import * as io from 'io-ts';
import { validString, nullableNumber } from '@shared/core/types/common';
import { cartLogoutModeEnum } from '@shared/core/types/consts';

const baseDefinition: io.MixedObject<OLO.Config.Cart.Base> = {
    header: validString,
    body: validString,
};

const clearOnLogoutModalDefinition: io.MixedObject<OLO.Config.Cart.UI> = {
    ...baseDefinition,
    yesButtonText: validString,
    noButtonText: validString,
};

const rootDefinition: io.MixedObject<OLO.Config.Cart> = {
    checkoutMaxCartItemsLines: nullableNumber,
    emptyCartBody: validString,
    emptyCartButton: validString,
    clearOnLogoutMode: cartLogoutModeEnum,
    clearOnLogoutModal: io.type(clearOnLogoutModalDefinition),
};

export const cartSchema = io.type(rootDefinition);
