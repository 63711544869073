import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

import { getOrderTypeIdFromDefaultOrCart } from '../getOrderTypeIdFromDefaultOrCart';

/**
 * Extract overwritten configuration for a specified page or returns default config
 * @param {keyof IUConfig} page
 * @param {OLO.Config} config
 */
export const getOrderTypeConfigurationForPage = <T extends keyof OLO.Config, K extends Unpacked<OLO.Config[T]>>(page: T) =>
    createSelector(getOrderTypeIdFromDefaultOrCart, (orderTypeId) => {
        const config = new Statics.ConfigStatic().current;

        if (!(page in config)) {
            console.warn('You need to provide valid page name. Provided:', page);

            return null;
        }

        const configObject = config[page];
        if (Utils.Arrays.isTypedArray(config[page])) {
            let mainConfig: Nullable<K> = null;
            let backupConfig: Nullable<K> = null;
            for (let i = 0, j = config[page]['length']; i < j; i++) {
                const obj = configObject[i];

                if (obj.orderTypeIds) {
                    if (!obj.orderTypeIds.length && !backupConfig) {
                        backupConfig = obj;
                    }

                    if (obj.orderTypeIds.includes(orderTypeId) && !mainConfig) {
                        return obj;
                    }
                }
            }

            return backupConfig;
        }

        return configObject;
    });
