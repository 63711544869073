import { createSelector } from '@ngrx/store';

import { getOrderTypesForLocation } from '../getOrderTypesForLocation';

export const getDataTypeFromOrderType = (locationNo: number, orderTypeId: number) =>
    createSelector(
        getOrderTypesForLocation(locationNo),
        orderType => orderType?.data?.reduce((dataType: number, orderTypeData: OLO.DTO.OrderType) => {
            if (typeof dataType === 'number') return dataType;

            return orderTypeData.Details.find(detail => detail.OrderTypeId === orderTypeId)?.DataType;
        }, null) || null
    );
