import { createSelector } from '@ngrx/store';

import { isCalculatingAvailablePickups } from '@shared/state/availablePickups/selectors';
import { getLocationsState } from '@shared/state/locations/selectors';

export const isLoadingDataForAnyLocation = createSelector(
    isCalculatingAvailablePickups,
    getLocationsState,
    (isCalculating, { isDownloading }) => isCalculating === true || isDownloading === true,
);
