import { createSelector } from '@ngrx/store';
import { appSettingsState } from '../root';

export const getAppSettingsLinks = createSelector(
    appSettingsState,
    appSettings => {
        const links: OLO.Config.ConfigLink[] = [];

        if (!appSettings?.apiSettings?.data?.AppSettings) return links;

        const a = appSettings?.apiSettings.data.AppSettings;

        if (a.ContactUsUrl) {
            links.push({
                label: 'Contact Us',
                name: 'contactUs',
                url: a.ContactUsUrl,
            });
        }

        if (a.UserAgreementUrl) {
            links.push({
                label: 'User Agreement',
                name: 'userAgreement',
                url: a.UserAgreementUrl,
            });
        }

        if (a.PrivacyPolicyUrl) {
            links.push({
                label: 'Privacy Policy',
                name: 'privacyPolicy',
                url: a.PrivacyPolicyUrl,
            });
        }

        return links;
    }
);
