import { createSelector } from '@ngrx/store';

import { getDefaultOrderTypeForLocation } from '../../orderTypes/getDefaultOrderTypeForLocation';
import { getProductRestrictions } from '@shared/state/productRestrictions/selectors';

export const restrictionForLocationOrderType = (locationNo: number) =>
    createSelector(getDefaultOrderTypeForLocation(locationNo), getProductRestrictions, (orderType, restrictions) => {
        if (!orderType || orderType?.IsProductsRestrictionEnabled !== true || !restrictions.data || restrictions.LocationNo !== locationNo || restrictions.isDownloading === true)
            return null;

        return restrictions.data;
    });
