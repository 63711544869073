import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

import { getOrderTypesForCartsLocation } from '../getOrderTypesForCartsLocation';
import { getOrderTypeIdFromDefaultOrCart } from '../getOrderTypeIdFromDefaultOrCart';

const groupDetectorFactory = (orderTypeId: number, config: OLO.Config): Utils.CollectionTypeGroupDetector => new Utils.CollectionTypeGroupDetector(orderTypeId, config);

export const getFilteredOrderTypesForCartsLocationByGroup = () =>
    createSelector(getOrderTypesForCartsLocation, getOrderTypeIdFromDefaultOrCart, (orderTypes, orderTypeIdDefaultOrCart) => {
        const config = new Statics.ConfigStatic().current;

        if (!orderTypes) {
            orderTypes = [];
        }

        const collectionTypeGroup: Utils.CollectionTypeGroupDetector = groupDetectorFactory(orderTypeIdDefaultOrCart, config);
        let orderTypesFilteredByGroup: OLO.DTO.OrderType[] = orderTypes.filter((orderType) => collectionTypeGroup.matchOrderTypeToCollectionType(orderType?.Id));

        if (orderTypesFilteredByGroup.length === 1) {
            orderTypesFilteredByGroup = orderTypesFilteredByGroup.filter((obj) => obj.Details.length > 0);
        }

        return orderTypesFilteredByGroup.map((obj) => ({
            Id: obj.Id,
            Name: obj.Description,
            ...obj,
        }));
    });
