import { createSelector } from '@ngrx/store';

import { paymentState } from '../root';

export const getPaymentStatus = createSelector(
    paymentState,
    payment => {
        const p = payment;
        if (p.hasFailed) {
            if (p.data && p.data.Status === OLO.Enums.PAYMENT_STATUS.FAILED) return 'declined';

            return 'failed';
        }

        if (p.isPaying) {
            return 'pending';
        }

        if (p.hasSucceeded) {
            return 'success';
        }

        return null;

    }
);
