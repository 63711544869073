import { createSelector } from '@ngrx/store';

import { getWizzard } from '@shared/state/wizzard/selectors';
import { getMenuFlowDetailsPagesByWizzard } from '../getMenuFlowDetailsPagesByWizzard';

export const wizzardMenuFlowPagesReports = createSelector(getWizzard, getMenuFlowDetailsPagesByWizzard, (wizzard, pages) => {
    if (!wizzard?.itemsMenuFlow || !pages) return null;

    const reports: OLO.State.Wizzard.WizzardPageReport[] = [];

    for (let i = 0, j = wizzard.itemsMenuFlow.Pages.length; i < j; i++) {
        const wizzardPage: OLO.State.Wizzard.WizzardMenuFlowPage = wizzard.itemsMenuFlow.Pages[i];
        const menuFlowPage: OLO.DTO.MenuFlowPage = pages.find((obj) => obj.PageIdentifier === wizzardPage.PageIdentifier);
        if (!menuFlowPage) break;

        const totalProductsQuantity = wizzardPage.Products.reduce((acc, product) => acc + product.Quantity, 0);

        reports.push({
            pageIdentifier: wizzardPage.PageIdentifier,
            isComplete: totalProductsQuantity >= menuFlowPage.PageMinQuantity && totalProductsQuantity <= menuFlowPage.PageMaxQuantity,
            errors: wizzard.errors?.length > 0,
        });
    }

    return reports;
});
