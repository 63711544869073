import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const hasMetMinimumOrderValueCriteria = createSelector(
    onlineOrderState,
    onlineOrder => {
        if (onlineOrder.data && onlineOrder.orderType) {
            return onlineOrder.data.TotalLeftToPay >= (onlineOrder.orderType.MinimumOrderValue || 0);
        }

        return null;
    }
);
