import * as io from 'io-ts';

export const logger = (left: io.Errors, params: Partial<{ logType: 'warn' | 'log' | 'error'; }> = {}) => {
    const opts: Parameters<typeof logger>[1] = {
        logType: 'error',
        ...params,
    };

    left.forEach((obj) => {
        const ctxSize = obj.context.length - 1;

        const err = [];
        let finalCtx;
        obj.context.forEach((ctx, i) => {
            const isLast = i === ctxSize;
            if (!ctx.key) {
                return;
            }

            const isNumber = !Number.isNaN(+ctx.key);
            let key = ctx.key.replace(/(\.?__THIS)/g, '');
            if (isNumber) {
                key = `[${key}]`;
            }

            if (isLast) {
                err.push(`${key} :::ERROR:::${obj.message ? ` ${obj.message} :::` : ''} expected type: '${ctx.type.name}', provided: ${ctx.actual}`);
                finalCtx = ctx;
            } else {
                err.push(`${key}`);
            }
        });
        // eslint-disable-next-line no-console
        console[opts.logType](err.join('.')
            .replace(/\.{2}/g, '')
            .replace(/\.\[/g, '['), [finalCtx]);
    });
};
