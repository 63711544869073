import { createSelector } from '@ngrx/store';

import { wizzardState } from '../root';

export const getWizzardMenuFlowProduct = (pageIdentifier: number, productId: number) =>
    createSelector(wizzardState, (wizzard) => {
        if (!wizzard.itemsMenuFlow) return null;
        const page = wizzard.itemsMenuFlow.Pages.find((p) => p.PageIdentifier === pageIdentifier);

        return page?.Products?.find((obj) => obj.ProductId === productId) || null;
    });
