import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

/**
 * Provides info about basic authorization without jwt token check,  i.e. when user is required to activate account - is signed in partially with limitted access.
 * This flag is used in a selector that provides info about full authorization: isMemberAuthorizedJWT
 */
export const isMemberAuthorized = createSelector(
    membersState,
    members => members.isAuthorized
);
