import { createSelector } from '@ngrx/store';

import { getCartLocationNo } from '@shared/state/cart/selectors';
import { getOrderTypesForAllLocations } from '@shared/state/orderTypes/selectors';

export const hasDownloadedOrderTypesForCurrentCartLocation = createSelector(getCartLocationNo, getOrderTypesForAllLocations, (locationNo, orderTypes) => {
    if (!locationNo || !orderTypes || orderTypes.length === 0) {
        return false;
    }

    const foundLocationOrderTypes = orderTypes.find((obj) => obj.locationNo === locationNo);
    if (!foundLocationOrderTypes) {
        return false;
    }

    return foundLocationOrderTypes.hasSucceeded === true;
});
