import { createSelector } from '@ngrx/store';

import { getPaymentStatus } from '../getPaymentStatus';
import { isPaying } from '../isPaying';

export const isPaymentDisabledForPayments = createSelector(
    getPaymentStatus,
    isPaying,
    (paymentStatus, isPayingFlag) => (paymentStatus === 'pending' || paymentStatus === 'success') ||
            isPayingFlag
);
