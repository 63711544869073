import { createSelector } from '@ngrx/store';
import { collectionTypeState } from '../root';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

export const isCollectionTypeCatering = createSelector(
    collectionTypeState,
    collectionType => {
        const config = new Statics.ConfigStatic().current;

        const collectionTypeCateringConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getCateringCollectionTypeConfig();

        return collectionType?.orderTypeId && collectionTypeCateringConfig?.orderTypeIds?.includes(collectionType?.orderTypeId) || false;
    }
);
