import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const getOnlineOrderTotalTax = createSelector(
    onlineOrderState,
    onlineOrder => {
        if (
            onlineOrder.recalculateRequest.data?.TotalTaxes != null
            && onlineOrder.recalculateRequest.data.TotalTaxes.length !== 0
        ) {
            return onlineOrder.data.TotalTaxes
                .map(tax => tax.Value)
                .reduce((sum, current) => sum + current);
        }

        return 0;
    }
);
