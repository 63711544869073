import { createSelector } from '@ngrx/store';
import { getCartSimpleItems } from '../getCartSimpleItems';
import { getCartMenuFlows } from '../getCartMenuFlows';

export const getCartAllItems = createSelector(
    getCartSimpleItems,
    getCartMenuFlows,
    (products, menuFlows) => {
        const arr: Array<OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem> = [];

        if (products?.length) {
            arr.push(...products);
        }

        if (menuFlows?.length) {
            arr.push(...menuFlows);
        }

        return arr.sort((a, b) => a._Id - b._Id);
    }
);
