import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';

import { membersState } from '../root';

export const getObfuscatedMemberEmail = createSelector(
    membersState,
    members => {
        if (!members?.data?.Email) return null;

        return Utils.Strings.obfuscateEmail(members.data.Email);
    }
);
