import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './credit-cards.actions';

const initialState: OLO.State.CreditCards = {
    activeCardId: null,
    activeCardToken: null,
    activeCardReturnUrlAfterRedirect: null,
    activeCardRedirectUrl: null,
    sessionToken: null,
    showAddCardForm: false,
    data: [],
    download: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
    },
    add: {
        isAdding: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    remove: {
        id: null,
        isRemoving: false,
        hasSucceeded: false,
        hasFailed: false,
        removedDate: null,
    },
    token: {
        isGettingToken: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    validation: {
        isValidating: false,
        hasSucceeded: false,
        hasFailed: false,
        token: null,
    },
    fatZebra: {
        r: null,
        v: null,
    },
    adyen: {
        locationConfig: {
            locationNo: null,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: false,
            downloadedDate: null,
            data: null,
        },
    },
    stripe: {
        locationConfig: {
            locationNo: null,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: false,
            downloadedDate: null,
            data: null,
        },
    }
};

export const creditCardReducerFn = createReducer<OLO.State.CreditCards>(
    initialState,
    on(
        actions.CreditCardsStripeConfigRequest,
        (state, action) => ({
            ...state,
            stripe: {
                ...state.stripe,
                locationConfig: {
                    ...state.stripe.locationConfig,
                    locationNo: action.locationNo,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    downloadedDate: null,
                    data: null,
                }
            }
        })
    ),
    on(
        actions.CreditCardsStripeConfigSuccessRequest,
        (state, action) => ({
            ...state,
            stripe: {
                ...state.stripe,
                locationConfig: {
                    ...state.stripe.locationConfig,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: Date.now(),
                    data: action.config,
                }
            }
        })
    ),
    on(
        actions.CreditCardsStripeConfigErrorRequest,
        (state, action) => ({
            ...state,
            stripe: {
                ...state.stripe,
                locationConfig: {
                    ...state.stripe.locationConfig,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                }
            }
        })
    ),


    on(
        actions.CreditCardsAdyenConfigRequest,
        (state, action) => ({
            ...state,
            adyen: {
                ...state.adyen,
                locationConfig: {
                    ...state.adyen.locationConfig,
                    locationNo: action.locationNo,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    downloadedDate: null,
                    data: null,
                }
            }
        })
    ),
    on(
        actions.CreditCardsAdyenConfigSuccessRequest,
        (state, action) => ({
            ...state,
            adyen: {
                ...state.adyen,
                locationConfig: {
                    ...state.adyen.locationConfig,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: Date.now(),
                    data: action.config,
                }
            }
        })
    ),
    on(
        actions.CreditCardsAdyenConfigErrorRequest,
        (state, action) => ({
            ...state,
            adyen: {
                ...state.adyen,
                locationConfig: {
                    ...state.adyen.locationConfig,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                }
            }
        })
    ),
    on(
        actions.CreditCardsAdyenAppendCVVNumber,
        (state, action) => ({
            ...state,
            data: state.data.map(card => {
                if(card.Id === action.cardId) {
                    return {
                        ...card,
                        AdyenPaymentData: {
                            ...card.AdyenPaymentData,
                            encryptedSecurityCode: action.encryptedSecurityCode
                        }
                    };
                }

                return card;
            })
        })
    ),

    on(
        actions.CreditCardsClearAllUnsavedCards,
        state => ({
            ...state,
            data: state.data.filter(obj => !(obj.SaveAwait === true && obj.Id === null) && !(obj.Id === null && obj.Token === null)),
        })
    ),
    on(
        actions.CreditCardsStateReset,
        (state, action) => ({
            ...state,
            ...JSON.parse(JSON.stringify(initialState))
        })
    ),
    on(
        actions.CreditCardTokenDataReset,
        (state, action) => ({
            ...JSON.parse(JSON.stringify(initialState)),
            showAddCardForm: true,
        })
    ),
    on(
        actions.CreditCardsResetError,
        (state, action) => ({
            ...state,
            download: {
                ...state.download,
                hasFailed: false,
            },
            token: {
                ...state.token,
                hasFailed: false,
            },
            remove: {
                ...state.remove,
                hasFailed: false,
            },
            add: {
                ...state.add,
                hasFailed: false,
            },
            validation: {
                ...state.validation,
                hasFailed: false,
                token: null,
            }
        })
    ),
    on(
        actions.CreditCardShowForm,
        (state, action) => ({
            ...state,
            showAddCardForm: action.isAdding,
        })
    ),
    on(
        actions.SelectActiveCreditCardId,
        (state, action) => ({
            ...state,
            activeCardId: action.cardId,
            activeCardToken: null,
        })
    ),
    on(
        actions.SelectActiveCreditCardToken,
        (state, action) => ({
            ...state,
            activeCardToken: action.token,
            activeCardId: null,
        })
    ),
    on(
        actions.AddCardToState,
        (state, action) => ({
            ...state,
            showAddCardForm: false,
            data:
                [
                    ...state.data,
                    action.card
                ]
        })
    ),
    on(
        actions.GetCreditCardToken,
        (state, action) => ({
            ...state,
            token: {
                ...state.token,
                isGettingToken: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.GetCreditCardTokenWithRedirect,
        (state, action) => ({
            ...state,
            activeCardReturnUrlAfterRedirect: null,
            activeCardRedirectUrl: null,
            activeCardToken: null,
            sessionToken: null,

            token: {
                ...state.token,
                isGettingToken: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.CreditCardsSuccessRequestToken,
        (state, action) => ({
            ...state,
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(

        actions.CreditCardsSuccessRequestTokenWithRedirect,
        (state, action) => ({
            ...state,
            activeCardReturnUrlAfterRedirect: action.returnUrlAfterRedirect || null,
            activeCardRedirectUrl: action.directPostUrl,
            sessionToken: action.token,
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.__DEMO__CreditCardsSuccessRequestToken,
        (state, action) => ({
            ...state,
            showAddCardForm: false,
            add: {
                ...state.add,
                isAdding: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.CreditCardsErrorRequestToken,
        (state, action) => ({
            ...state,
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.CreditCardsErrorRequestTokenWithRedirect,
        (state, action) => ({
            ...state,
            activeCardReturnUrlAfterRedirect: null,
            activeCardRedirectUrl: null,
            activeCardToken: null,
            sessionToken: null,
            token: {
                ...state.token,
                isGettingToken: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.CreditCardsAddRequest,
        actions.CreditCardsAddAfterRedirectRequest,
        (state, action) => ({
            ...state,
            add: {
                ...state.add,
                isAdding: true,
                hasFailed: false,
                hasSucceeded: false,
            },

        })
    ),
    on(
        actions.CreditCardsAddSuccessRequest,
        (state, action) => ({
            ...state,
            add: {
                ...state.add,
                isAdding: false,
                hasFailed: false,
                hasSucceeded: true,
            }

        })
    ),
    on(
        actions.CreditCardsAddAfterRedirectSuccessRequest,
        (state, action) => {
            const isNewDefault = action.newCard.IsDefault;

            return {
                ...state,
                add: {
                    ...state.add,
                    isAdding: false,
                    hasFailed: false,
                    hasSucceeded: true,
                },
                data: state.data.map(card => {
                    if (card.Token === action.card.Token) {
                        const c = {
                            ...card,
                            ...action.newCard,
                        };

                        delete c.SaveAwait;

                        return c;
                    } else {
                        if (isNewDefault) {
                            return {
                                ...card,
                                IsDefault: false,
                            };
                        }

                        return card;

                    }
                })
            };
        }
    ),
    on(
        actions.CreditCardsAddErrorRequest,
        actions.CreditCardsAddAfterRedirectErrorRequest,
        (state, action) => ({
            ...state,
            add: {
                ...state.add,
                isAdding: false,
                hasFailed: true,
                hasSucceeded: false,
            }
        })
    ),
    on(
        actions.CreditCardsRemoveRequest,
        (state, action) => ({
            ...state,
            remove: {
                ...state.remove,
                id: action.cardId,
                removedDate: null,
                isRemoving: true,
                hasSucceeded: false,
                hasFailed: false,
            }

        })
    ),
    on(
        actions.CreditCardsRemoveSuccessRequest,
        (state, action) => ({
            ...state,
            remove: {
                ...state.remove,
                id: null,
                removedDate: new Date().getTime(),
                isRemoving: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: state.data.filter(cart => cart.Id !== action.cardId),
            showAddCardForm: state.data.filter(cart => cart.Id !== action.cardId).length === 0 && state.activeCardId !== -1
        })
    ),
    on(
        actions.CreditCardsRemoveErrorRequest,
        (state, action) => ({
            ...state,
            remove: {
                ...state.remove,
                isRemoving: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.CreditCardsRequest,
        (state, action) => ({
            ...state,
            download: {
                ...state.download,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false
            }
        })
    ),
    on(
        actions.CreditCardsSuccessRequest,
        (state, action) => ({
            ...state,
            download: {
                ...state.download,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
            },
            data: action.payload.reduce((acc, card) => {
                const found = acc.find(obj => obj.Id === card.Id);
                if (!found) {
                    return [
                        ...acc,
                        card,
                    ];
                }

                return acc.map(obj => {
                    if (obj.Id === card.Id) {
                        return card;
                    }

                    return obj;
                });
            }, [
                ...state.data
            ]),
        })
    ),
    on(
        actions.CreditCardsErrorRequest,
        (state, action) => ({
            ...state,
            download: {
                ...state.download,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.CreditCardsValidateReset,
        (state, action) => ({
            ...state,
            validation: {
                ...state.validation,
                isValidating: false,
                hasSucceeded: false,
                hasFailed: false,
                token: null,
                r: null,
                v: null,
            },
            fatZebra: {
                r: null,
                v: null,
            }
        })
    ),
    on(
        actions.CreditCardsValidateRequest,
        (state, action) => ({
            ...state,
            activeCardReturnUrlAfterRedirect: null,
            activeCardRedirectUrl: null,
            validation: {
                ...state.validation,
                isValidating: true,
                hasSucceeded: false,
                hasFailed: false,
                token: action.responseParams?.token || null,
            },
            fatZebra: {
                r: +action.responseParams?.r || null,
                v: action.responseParams?.v || null,
            }
        })
    ),
    on(
        actions.CreditCardsValidateSuccessRequest,
        (state, action) => ({
            ...state,
            activeCardToken: action.responseParams?.token || action.card.CardId,
            activeCardReturnUrlAfterRedirect: null,
            activeCardRedirectUrl: null,
            sessionToken: null,
            showAddCardForm: false,
            validation: {
                ...state.validation,
                isValidating: false,
                hasSucceeded: true,
                hasFailed: false,
                token: action.responseParams?.token || null,
            },
            fatZebra: {
                r: +action.responseParams?.r || null,
                v: action.responseParams?.v || null,
            },
            data: state.data.map(obj => {
                if (obj.Id === null && obj.Token === null) {
                    return {
                        ...obj,
                        Token:  action.responseParams?.token || action.card?.CardId
                    };
                }

                return obj;
            })
        })
    ),
    on(
        actions.CreditCardsValidateErrorRequest,
        state => ({
            ...state,
            activeCardToken: null,
            activeCardReturnUrlAfterRedirect: null,
            activeCardRedirectUrl: null,
            sessionToken: null,
            validation: {
                ...state.validation,
                isValidating: false,
                hasSucceeded: false,
                hasFailed: true,
                token: null,
            },
            fatZebra: {
                r: null,
                v: null,
            }
        })
    ),
    on(
        actions.CreditCardsRemoveUnsavedCard,
        (state, action) => ({
            ...state,
            activeCardToken: null,
            showAddCardForm: state.activeCardId !== -1,
            data: state.data.filter(card => card.Id === null && card.Token === action.token),
        })
    ),
    on(
        actions.CreditCardsSetErrorValidationStatusToValidatingCards,
        state => ({
            ...state,
            data: state.data.map(card => {
                if (card.ValidationStatus === 'validating') {
                    return {
                        ...card,
                        ValidationStatus: 'error',
                    };
                }

                return card;
            })
        })
    ),
);

export function creditCardReducer(state: OLO.State.CreditCards | undefined, action: Action) {
    return creditCardReducerFn(state, action);
}
