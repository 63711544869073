import { createSelector } from '@ngrx/store';

import { getCartLocationNo } from '@shared/state/cart/selectors';
import { getAvailablePickupTimesForAllLocations } from '@shared/state/availablePickups/selectors';

export const isCartLocationsPickupsCalculating = createSelector(getCartLocationNo, getAvailablePickupTimesForAllLocations, (locationNo, availablePickups) => {
    if (!locationNo) return null;
    const foundAvailablePickups = availablePickups.find((item) => item.locationNo === locationNo);

    if (!foundAvailablePickups) return null;

    return foundAvailablePickups.isCalculating;
});
