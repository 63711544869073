import { Injectable, Inject } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import { QueryParamsService } from './query-params.shared.service';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RouteService {
    public LOCATION_DETAILS_REGEXP: RegExp = /\/locations\/\d+\/details$/gim;

    constructor(
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        @Inject(Tokens.CONFIG_TOKEN) protected _config: OLO.Config,
        protected _store: Store<OLO.State>,
        protected _router: Router,
        protected _queryParamsService: QueryParamsService,
    ) {}

    protected _getOrderConfirmationUrlObject(orderId: number, locationNo: number, orderTypeId: number): Utils.OrderConfirmationUrl {
        const encr: string = this._queryParamsService.encryptOrderData(locationNo, orderId);

        return new Utils.OrderConfirmationUrl(orderTypeId, encr);
    }

    public get locationRootUrl(): string {
        return this._config.venue && this._config.venue.id && this._config.venue.name ? this._config.venue.name : 'locations';
    }

    public isCheckoutPage$(): Observable<boolean> {
        return this._store.pipe(select(selectors.isCurrentRouteCheckoutPage));
    }

    public navigate(command: any, extras?: NavigationExtras): Promise<boolean> {
        return this._router.navigate(command, extras);
    }

    public navigateToLocationsSearchView(): Promise<boolean> {
        return this._router.navigate([this.locationRootUrl]);
    }

    public async navigateToLocation(locationNo: number): Promise<boolean> {
        if (!locationNo) return this._router.navigate(['/']);

        try {
            let targetUrl: string = `/locations/${locationNo}/details`;
            let canOrderFromLocation: boolean = await this._store.pipe(select(selectors.canOrderFromLocation(locationNo)), take(1)).toPromise();
            if (this._config.venue && this._config.venue.id && this._config.venue.name) {
                const locationFriendlyName: string = await this._store
                    .pipe(
                        select(selectors.getLocationDetails(locationNo)),
                        take(1),
                        map((location) => {
                            if (!location) {
                                console.warn(`Unable to find location ${locationNo}`);

                                return '/';
                            }

                            return location.LocationFriendlyName.replace(/[^a-zA-Z0-9]/gim, '');
                        }),
                    )
                    .toPromise();

                if (locationFriendlyName === null) {
                    canOrderFromLocation = false;
                } else {
                    targetUrl = locationFriendlyName === '/' ? '/' : `/${this.locationRootUrl}/${locationFriendlyName}`;
                }
            }

            return !canOrderFromLocation ? null : this._router.navigate([targetUrl]);
        } catch (ex) {
            console.error(`Can't navigate to provided location ${locationNo}`, ex);

            return null;
        }
    }

    public async navigateToCartsLocation(): Promise<boolean> {
        const locationNo: number = await this._store.pipe(select(selectors.getCartLocationNo), take(1)).toPromise();

        return this.navigateToLocation(locationNo);
    }

    public async navigateToCheckout(path: string = '/checkout', extras: NavigationExtras = {}): Promise<boolean> {
        return this._router.navigate([path ? path : '/checkout'], extras);
    }

    public removeURLQueryParams(): Promise<boolean> {
        return this._router.navigate([], {
            queryParams: {
                modal: undefined,
                itemId: undefined,
            },
            queryParamsHandling: 'merge',
        });
    }

    public async handleOrderMoreNavigation(): Promise<boolean> {
        return this.navigateToCartsLocation();
    }

    public saveConfirmationUrlAndNavigateToOrderConfirmation(orderId: number, locationNo: number, orderTypeId: number) {
        const UrlObj = this._getOrderConfirmationUrlObject(orderId, locationNo, orderTypeId);

        const url = UrlObj.getAbsoluteUrl();

        this._store.dispatch(
            actions.OnlineOrderSaveConfirmationUrlRequest({
                orderId,
                url,
            }),
        );

        return this.navigateToOrderConfirmationView(orderId, locationNo, orderTypeId);
    }

    public navigateToOrderConfirmationView(orderId: number, locationNo: number, orderTypeId: number): void {
        const UrlObj = this._getOrderConfirmationUrlObject(orderId, locationNo, orderTypeId);

        const url = UrlObj.getBaseUrl();
        const queryParams = UrlObj.getSerializedQueryParams();

        this._router.navigate([url], {
            queryParams,
        });
    }

    public async navigateToProfileView(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._store.pipe(select(selectors.isMemberAuthorizedJWT), take(1)).subscribe((isAuthorized) => {
                if (isAuthorized) {
                    return this._router.navigate(['/account']);
                }

                reject('unauthorized');
            });
        });
    }

    public async navigateToProfileRewards(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._store.pipe(select(selectors.isMemberAuthorizedJWT), take(1)).subscribe((isAuthorized) => {
                if (isAuthorized) {
                    return this._router.navigate(['/account/rewards']);
                }

                reject('unauthorized');
            });
        });
    }

    public async navigateToProfileLoyaltyHome(): Promise<boolean> {
        return this._store
            .pipe(select(selectors.isMemberAuthorizedJWT), take(1))
            .toPromise()
            .then((isAuthorized) => {
                if (isAuthorized) {
                    return this._router.navigate(['/account/loyalty']);
                }

                return this._router.navigate(['/loyalty']);
            });
    }

    public async navigateToHomeView(): Promise<boolean> {
        return this._store
            .pipe(select(selectors.isMemberAuthorizedJWT), take(1))
            .toPromise()
            .then((isAuthorized) => {
                switch (true) {
                    case this._config.appMode === OLO.Enums.APP_MODE.LOYALTY_ONLY && isAuthorized === true:
                        return this._router.navigate(['/account/loyalty']);
                    case this._config.appMode === OLO.Enums.APP_MODE.LOYALTY_ONLY && isAuthorized === false:
                        return this._router.navigate(['/loyalty']);
                    default:
                        return this._router.navigate(['/']);
                }
            });
    }

    public extractOrderConfirmationQueryParams$(): Observable<Nullable<OLO.Common.OrderConfirmationQueryParams>> {
        return this._store.pipe(
            select(selectors.getCurrentRouteQueryParams),
            map((params) => {
                if (!params?.order) return null;

                return this._queryParamsService.decryptOrderData(params);
            }),
        );
    }
}
