import { createSelector } from '@ngrx/store';
import { cartState } from '../root';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

export const isCartCollectionTypePickup = createSelector(
    cartState,
    cart => {
        const config = new Statics.ConfigStatic().current;

        const collectionTypePickupConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getPickupCollectionTypeConfig();

        return cart?.orderTypeId && collectionTypePickupConfig?.orderTypeIds?.includes(cart?.orderTypeId) || false;
    }
);
