import { createSelector } from '@ngrx/store';

import { getCurrentLocationNo } from '@shared/state/currentLocation/selectors';
import { getAllLocationImages } from '@shared/state/locationsImages/selectors';

export const getCurrentLocationImage = createSelector(getCurrentLocationNo, getAllLocationImages, (locationNo, images) => {
    const foundImage = images?.forWeb?.find((obj) => obj.Id === locationNo);

    return foundImage?.data?.ImageUrl || null;
});
