import { createSelector } from '@ngrx/store';
import { cartState } from '../root';

export const getCartTotalQuantity = createSelector(
    cartState,
    cart => {
        let total: number = 0;

        /* Regular loop is ~10x faster than 'reduce' method */
        for (let i = 0, j = cart.itemsSimple.length; i < j; i++) {
            total += cart.itemsSimple[i].Quantity;
        }

        for (let i = 0, j = cart.itemsMenuFlow.length; i < j; i++) {
            total += cart.itemsMenuFlow[i].Quantity;
        }

        return total;
    }
);
