import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { routerState } from '../root';

export const getCurrentRouteQueryParams = createSelector(routerState, (router) => {
    if (!router?.current) return {};

    return Utils.UrlParams.standardizeParamsCase(router?.current?.queryParams);
});
