import * as io from 'io-ts';

/**
 * Checks if an array contains only defined values
 * @param values array of allowed values
 */
export const arrayContainsValues = (...values: unknown[]) => {
    const existsInValues = (inputArr): inputArr is Array<keyof typeof values> => inputArr.reduce((inArr, next) => inArr && values.includes(next), true);

    return new io.Type('arrayOfExactValues', existsInValues, (input, context) => (existsInValues(values) ? io.success(input) : io.failure(input, context)), io.identity);
};
