import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getCart } from '@shared/state/cart/selectors';
import { getLocationsState } from '@shared/state/locations/selectors';

export const getOrderingTimeInfoByCartLocationAndOrderType = createSelector(getCart, getLocationsState, (cart, locationState) => {
    const location = locationState?.data?.find((obj) => obj.LocationNo === cart.locationNo);

    return new Utils.LocationOrderingTimeInfo(location, cart.orderTypeId).getOrderingTimeInfo();
});
