import { createSelector } from '@ngrx/store';

import { getPaymentState } from '@shared/state/payment/selectors';
import { getOnlineOrderState } from '@shared/state/onlineOrder/selectors';

export const isPaymentProcessValid = createSelector(getPaymentState, getOnlineOrderState, (payment, onlineOrder) => {
    if (payment.errors.length > 0) return false;
    if (!onlineOrder.recalculateRequest.data) return false;

    return Boolean(onlineOrder.recalculateRequest.data);
});
