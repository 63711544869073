export class Spread {
    public static menuFlowItemPageProductsByQuantity(products: OLO.Ordering.MenuFlowItemPageProduct[]): OLO.Ordering.MenuFlowItemPageProduct[] {
        const arr: OLO.Ordering.MenuFlowItemPageProduct[] = [];

        products.forEach(product => {
            let processed: number = 0;

            while (processed < product.Quantity) {
                ++processed;

                arr.push({
                    ...product,
                    Quantity: 1,
                });
            }

        });

        return arr;
    }
}
