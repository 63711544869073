import { createSelector } from '@ngrx/store';
import { getOnlineMenu } from '@shared/state/onlineMenu/selectors';
import { getCurrentLocationNo, getCurrentLocationValidationState } from '@shared/state/currentLocation/selectors';

export const canShowOnlineMenuForCurrentLocation = createSelector(
    getCurrentLocationNo,
    getCurrentLocationValidationState,
    getOnlineMenu,
    (locationNo, validation, onlineMenu) =>
        locationNo !== null &&
        onlineMenu?.locationNo === locationNo &&
        Boolean(onlineMenu?.data) &&
        validation?.hasSucceeded === true &&
        validation?.hasFailed !== true &&
        validation?.isValidating !== true,
);
