import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const isCancellingOnlineOrder = createSelector(
    onlineOrderState,
    onlineOrder => (
        onlineOrder.cancelRequest.isCanceling
    )
);
