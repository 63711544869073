export class ApplePayPaymentProviderMapper {
    public static mapApplePayTokenObject(tokenObj: ApplePayJS.PaymentEncryptedData): OLO.DTO.ApplePayPaymentData {
        if(typeof tokenObj === 'string') {
            try {
                tokenObj = JSON.parse(tokenObj);
            } catch(ex) {
                console.error('Unable to convert ApplePay token to object:', tokenObj, ex);

                return null;
            }
        }

        return tokenObj && tokenObj.data && {
            Data: tokenObj.data,
            Signature: tokenObj.signature,
            Version: tokenObj.version,
            Header: {
                ApplicationData: null,
                EphemeralPublicKey: tokenObj.header.ephemeralPublicKey,
                PublicKeyHash: tokenObj.header.publicKeyHash,
                TransactionId: tokenObj.header.transactionId
            }
        } || null;
    }
}
