import { createSelector } from '@ngrx/store';

import { getMenuFlowDetailsByWizzard } from '../getMenuFlowDetailsByWizzard';

export const getMenuFlowDetailsSepcificPageByWizzard = (pageIdentifier: number) =>
    createSelector(getMenuFlowDetailsByWizzard, (menuFlow) => {
        if (!menuFlow) return null;

        return menuFlow.Pages.find((page) => page.PageIdentifier === pageIdentifier) || null;
    });
