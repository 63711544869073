import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';

import { hasRecalculateFailed } from '../hasRecalculateFailed';

export const hasRecalculateFailedErrorMapped = createSelector(
    hasRecalculateFailed,
    hasFailed => !hasFailed ?
        null : Utils.Messages.mapErrors({
            apiCommunication: 'We are having trouble processing payment. Please try again'
        })[0],
);
