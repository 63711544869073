import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './payment.actions';

const initialState: OLO.State.Payment = {
    orderId: null,
    errors: [],
    redirect: {
        vendorPayment: false,
    },
    paymentStepStatus: null,

    paymentMethod: null,
    isPaying: false,
    hasSucceeded: false,
    hasFailed: false,
    data: null,
};

export const paymentsReducerFn = createReducer(
    initialState,
    on(
        actions.PaymentClearErrors,
        state => ({
            ...state,
            errors: [],
        }),
    ),
    on(
        actions.PaymentInitWithRedirect,
        (state, action) => ({
            ...state,
            orderId: null,
            errors: [],
            paymentStepStatus: 'init',
            redirect: {
                ...state.redirect,
                vendorPayment: true,
            },
            paymentMethod: null,
            isPaying: true,
            hasSucceeded: false,
            hasFailed: false,
            data: null,
        })
    ),
    on(
        actions.PaymentInitWithPaymentMethod,
        (state, action) => ({
            ...state,
            redirect: {
                ...state.redirect,
                vendorPayment: false,
            },
            orderId: null,
            errors: [],
            paymentStepStatus: 'init',

            paymentMethod: action.paymentMethod,
            isPaying: true,
            hasSucceeded: false,
            hasFailed: false,
            data: null,
        })
    ),
    on(
        actions.PaymentForceStep,
        (state, action) => ({
            ...state,
            paymentStepStatus: action.step
        }),
    ),
    on(
        actions.PaymentInit,
        (state, action) => ({
            ...state,
            orderId: null,
            redirect: {
                ...state.redirect,
                vendorPayment: false,
            },
            errors: [],
            paymentStepStatus: 'init',

            paymentMethod: null,
            isPaying: true,
            hasSucceeded: false,
            hasFailed: false,
            data: null,
        })
    ),
    on(
        actions.PaymentStepValidateGuestData,
        (state, action) => ({
            ...state,
            paymentStepStatus: 'validate_guest'
        })
    ),
    on(
        actions.PaymentStepValidateGuestCardToken,
        actions.PaymentStepValidateMemberCardToken,
        (state, action) => ({
            ...state,
            paymentStepStatus: 'validate_card_token',
        })
    ),
    on(
        actions.PaymentStepCreateOrder,
        (state, action) => ({
            ...state,
            paymentStepStatus: 'create_order',
        })
    ),
    on(
        actions.PaymentStepRedirect,
        (state, action) => ({
            ...state,
            paymentStepStatus: 'redirect',
        })
    ),
    on(
        actions.PaymentStepPay,
        (state, action) => ({
            ...state,
            paymentStepStatus: 'paying',
        })
    ),
    on(
        actions.PaymentStepPaymentStatusCheck,
        (state, action) => ({
            ...state,
            orderId: action.OrderId,
            paymentStepStatus: 'payment_status_check',
            data: {
                TransactionId: action.TransactionId,
            }
        })
    ),

    on(
        actions.PaymentStepComplete,
        (state, action) => ({
            ...state,
            orderId: action.OrderId,
            paymentStepStatus: 'complete',
            errors: [],
            redirect: {
                vendorPayment: false,
            },
            data: {
                ...action.payload,
            },
            paymentMethod: null,
            isPaying: false,
            hasSucceeded: true,
            hasFailed: false,
        })
    ),
    on(
        actions.PaymentStepFailed,
        (state, action) => ({
            ...state,

            paymentStepStatus: 'failed',
            errors: [
                ...state.errors,
                {
                    Id: action.Id,
                    Message: action.Message,
                }
            ],
            isPaying: false,
            hasSucceeded: false,
            hasFailed: true,
        })
    ),
    on(
        actions.PaymentStepSkipForZeroPricedOrder,
        (state, action) => ({
            ...state,
        })
    ),
    on(
        actions.PaymentReset,
        (state, action) => ({ ...initialState })
    ),
);

export function paymentsReducer(state: OLO.State.Payment | undefined, action: Action) {
    return paymentsReducerFn(state, action);
}
