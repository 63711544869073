import { createSelector } from '@ngrx/store';
import { locationsState } from '../root';

export const hasRequestedLocations = createSelector(
    locationsState,
    (locations) => {
        if (
            !locations
            || locations?.isDownloading
            || (locations?.isDownloading === false && locations?.hasSucceeded === false && locations?.hasFailed === false)
        ) return false;

        return true;
    }
);
