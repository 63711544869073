import { createSelector } from '@ngrx/store';
import { cartState } from '../root';

export const getCartMenuFlowQuantityByMenuFlowId = (menuFlowId: number) => createSelector(
    cartState,
    cart => cart.itemsMenuFlow.reduce((acc, menuFlow) => {
        if (menuFlow.MenuFlowId === menuFlowId) {
            return acc += menuFlow.Quantity;
        }

        return acc;
    }, 0)
);
