import { createSelector } from '@ngrx/store';
import { getLocationDetails } from '../getLocationDetails';
import * as Utils from '@shared/core/utils';

export const isLocationInDeliveryRadius = (locationNo: number, deliveryAddress: APICommon.GooglePlaceDetails) =>
    createSelector(
        getLocationDetails(locationNo),
        (locationDetails) => {
            if (!locationDetails?.DeliveryRadius || !deliveryAddress?.geometry || !locationDetails?.Latitude || !locationDetails?.Longitude) {
                return false;
            }

            const distance = Utils.Geolocation.getDistanceFromTo(
                {
                    latitude: deliveryAddress.geometry.lat,
                    longitude: deliveryAddress.geometry.lng,
                },
                {
                    latitude: locationDetails.Latitude,
                    longitude: locationDetails.Longitude,
                },
            );

            if (distance <= locationDetails.DeliveryRadius) {
                return true;
            }

            return false;
        }
    );
