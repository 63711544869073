export class Inputs {
    public static readonly negativeKeys = [
        'delete',
        'backspace',
    ];
    public static readonly neturalKeys = [
        'shift',
        'escape',
        'control',
        'arrowdown',
        'arrowup',
        'arrowleft',
        'arrowright',
        'alt',
        'end',
        'home',
        'pageup',
        'pagedown',
        'tab'
    ];

    public static isKeyNeutral(key: string): boolean {
        /* Backspace and dels excluded */
        const lckey: string = key.toLowerCase();

        return Inputs.neturalKeys.indexOf(lckey) > -1;
    }

    public static isKeySafe(e: KeyboardEvent): boolean {
        //
        //  Detects backspaces, del and some functional stuff
        //
        let isSave: boolean = false;

        if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumpadDecimal', 'Period'].indexOf(e.code) !== -1 ||
            /* Allow: Ctrl+A */
            (e.code === 'KeyA' && (e.ctrlKey || e.metaKey)) ||
            /* Allow: Ctrl+C */
            (e.code === 'KeyC' && (e.ctrlKey || e.metaKey)) ||
            /* Allow: Ctrl+V */
            (e.code === 'KeyV' && (e.ctrlKey || e.metaKey)) ||
            /* Allow: Ctrl+X */
            (e.code === 'KeyX' && (e.ctrlKey || e.metaKey)) ||
            /*  Allow: home, end, left, right */
            (e.code === 'End' || e.code === 'Home' || e.code === 'ArrowLeft' || e.code === 'ArrowRight')) {

            isSave = true;
        }

        return isSave;
    }

    public static isKeyNegative(key: string): boolean {
        /* Backspace and del only */
        const lckey: string = key.toLowerCase();

        return Inputs.negativeKeys.indexOf(lckey) > -1;
    }

    public static isTextSelected(input: HTMLInputElement | HTMLTextAreaElement): boolean {
        if (typeof input.selectionStart === 'number' && typeof input.selectionEnd === 'number') {
            return input.selectionStart !== input.selectionEnd;
        }

        return false;
    }

    public static canType(event: Event): boolean {
        return Inputs.isKeyNeutral((event as any).key) || Inputs.isKeyNegative((event as any).key);
    }

    public static inputAllowOnly(event: Event, regex: RegExp): boolean {
        /* Best to use on (keydown) event - will prevent from typing stuff to input. You can validate i.e. numbers only in text field */

        if (Inputs.canType(event)) {
            return true;
        }

        const keyMatch = regex.test((event as any).key);
        if (!keyMatch) {
            event.preventDefault();
        }
    }

    public static inputMaxLength(event: Event, maxLength: number, async: (() => any) | boolean = false): void {
        /* Prevent from typing too much */
        const currentVal: string = (event.target as HTMLInputElement).value;
        const currentValLength: number = currentVal.length;

        const isValid = () => {
            if (Inputs.canType(event) || Inputs.isTextSelected(event.target as HTMLInputElement)) {
                return true;
            }
            if (currentValLength + 1 > maxLength) {
                return false;
            }

            return true;
        };

        if (!isValid()) {
            event.preventDefault();
        }

        if (async) {
            setTimeout(() => {
                const newVal = (event.target as HTMLInputElement).value;
                const isInvalid = newVal.length > maxLength;
                if (isInvalid && typeof async === 'function') {
                    async();
                }
            }, 10);
        }

    }

}
