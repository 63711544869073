import { createSelector } from '@ngrx/store';

import { isCartEmpty } from '@shared/state/cart/selectors';
import { getCurrentLocationNo, getCurrentPickupTime } from '@shared/state/currentLocation/selectors';
import { isGuestModeEnabled, isMemberAuthorizedJWT } from '@shared/state/members/selectors';

export const canNavigateToCheckoutAuthorizedOnly = createSelector(
    isCartEmpty,
    getCurrentLocationNo,
    getCurrentPickupTime,
    isGuestModeEnabled,
    isMemberAuthorizedJWT,
    (isCartEmptyFlag, currentLocationNo, pickupTime, isGuestModeEnabledFlag, isMemberAuthorizedJWTFlag) => {
        if (!isGuestModeEnabledFlag && !isMemberAuthorizedJWTFlag) {
            return false;
        }

        if (isCartEmptyFlag === true) {
            return false;
        }

        if (currentLocationNo === null || currentLocationNo === 0) {
            return false;
        }

        return pickupTime !== null;
    },
);
