import { createSelector } from '@ngrx/store';
import { getOrderInfoCompleteForLocationsByDate } from '@shared/state/commonSelectors/orderTypes';

import * as Utils from '@shared/core/utils';

export const getEdgeOpeningHoursForLocations = (date: string | Date | null = null) =>
    createSelector(getOrderInfoCompleteForLocationsByDate(date), (openingHours) => {
        const tempObj = {
            OpeningTime: null as Nullable<string>,
            ClosingTime: null as Nullable<string>,
        };

        openingHours?.forEach((obj) => {
            if (!obj.OpeningTime || !obj.ClosingTime) return;
            const tempOpen = tempObj.OpeningTime === null ? null : Utils.Dates.createHoursIntFromDate(tempObj.OpeningTime);
            const tempClose = tempObj.OpeningTime === null ? null : Utils.Dates.createHoursIntFromDate(tempObj.ClosingTime);
            const openingTime = obj.OpeningTime ? Utils.Dates.createHoursIntFromDate(obj.OpeningTime) : null;
            const closingTime = obj.ClosingTime ? Utils.Dates.createHoursIntFromDate(obj.ClosingTime) : null;

            if (tempOpen === null || tempOpen > openingTime) {
                tempObj.OpeningTime = obj.OpeningTime;
            }

            if (tempClose === null || tempClose < closingTime) {
                tempObj.ClosingTime = obj.ClosingTime;
            }
        });

        return tempObj;
    });
