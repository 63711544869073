import { createSelector } from '@ngrx/store';
import { OnlineOrders } from '@shared/core/utils';
import { historyOrdersState } from '../root';

const orderSortFunc = (a, b) => {
    switch (true) {
        case a.OrderId > b.OrderId:
            return -1;
        case a.OrderId < b.OrderId:
            return 1;
        default:
            return 0;
    }
};

export const getHistoryActiveOrders = createSelector(historyOrdersState, (historyOrders) =>
    historyOrders.orders
        .filter((order) => !!order.data && OnlineOrders.isOrderActive(order.data.Status) === true)
        .sort(orderSortFunc),
);
