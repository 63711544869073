import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { venueImagesState } from '../root';

export const getVenueImage = (imageType: OLO.Enums.IMAGE_TYPE, venueId: number) =>
    createSelector(venueImagesState, (venuesImages) => {
        let targetImageType: string = Utils.Images.toImageTypeString(imageType);

        return venuesImages[targetImageType]?.find((obj) => obj.Id === venueId) || null;
    });
