import * as io from 'io-ts';
import * as Types from '@shared/core/types';

import { applicationConfigSchema, applicationConfigPartialSchema } from '@shared/core/types/schemas';
import { ApplicationConfig } from './application-config.model';
import { OrderTypeIdsValidators, RelationsValidators } from './validators';

type Params = {
    validateInput: boolean;
    validateOutput: boolean;
    validateDefault: boolean;
    /** Provide different validation for the default and the output  */
    schema: Nullable<io.Type<OLO.Config>>;
    /** Provide different validation model for the input */
    schemaPartial: Nullable<io.Type<Partial<OLO.Config>>>;
    defaultConfiguration: Nullable<OLO.Config>;
    relatedDataValidation: boolean;
};

export class ApplicationConfigBuilder {
    private _model!: OLO.Config;
    private _isInputValid: Nullable<boolean> = null;
    private _isDefaultValid: Nullable<boolean> = null;
    private _isOutputValid: Nullable<boolean> = null;
    private _relatedDataErrors: Array<OLO.Common.Validators> = [];

    private _opts: Params = {
        validateInput: false,
        validateOutput: true,
        validateDefault: false,
        defaultConfiguration: null,
        schema: applicationConfigSchema,
        schemaPartial: applicationConfigPartialSchema,
        relatedDataValidation: true,
    };

    constructor(private _config: Partial<OLO.Config> = {}, params: Partial<Params> = {}) {
        this._opts = {
            ...this._opts,
            ...params,
        };

        this._model = this._defaultConfig;

        this._sortTupleCollectionTypes(this._model, this._config);

        if (this._opts.validateInput) {
            this._isInputValid = Types.isValid(this._opts.schemaPartial, _config);

            if (!this._isInputValid) {
                throw new Error('Your config has errors.');
            }
        }

        if (this._opts.validateDefault) {
            this._isDefaultValid = Types.isValid(this._opts.schema, this._model);

            if (!this._defaultConfig) {
                throw new Error('Default configuration contains errors.');
            }
        }

        this._buildOutputModel();

        if (this._opts.validateOutput) {
            this._isOutputValid = Types.isValid(this._opts.schema, this._model);

            if (!this._isOutputValid) {
                throw new Error('Invalid configuration. Check the console for more details.');
            }
        }
    }

    /** Collection types array is a tuple, so in order to pass validation, types will be sorted by collectionTypeId */
    protected _sortTupleCollectionTypes(...configs: Partial<OLO.Config>[]): void {
        configs.forEach(config => {
            if(config.collectionTypes) {
                config.collectionTypes.sort((a, b) => b.collectionTypeId - a.collectionTypeId);
            }
        });
    }

    /** Merges custom config with the default one and drops all references */
    protected _buildOutputModel(): void {
        this._model = JSON.parse(
            JSON.stringify({
                ...this._model,
                ...this._config,
            }),
        );
    }

    /** Performs model validation and add any error to _relatedDataErrors for further inspection & handling */
    protected _validateRelatedData(model: OLO.Config): boolean {
        this._relatedDataErrors = [
            ...OrderTypeIdsValidators.validateTuple(model),

            ...OrderTypeIdsValidators.validatePages(
                model,
                'onlineOrders.scheduleOrderModals',
                'onlineOrders.updateOrderModals',
                'onlineOrders.cancelOrderModals',
                'onlineOrders.activeOrderModals',
            ),

            ...OrderTypeIdsValidators.validatePages(
                model,
                'landingPage',
                'checkoutPage',
                'orderConfirmationPage',
                'locationListPage',
                'locationDetailsPage',
                'topBar'
            ),

            ...RelationsValidators.validateScheduledOrdersFlag(
                model,
                OLO.Enums.COLLECTION_TYPE.CATERING
            ),

            ...RelationsValidators.validateLandingPageItemsLocationModes(model),

            ...RelationsValidators.validateLocationListPageItemsLocationModes(model),

            ...RelationsValidators.validateOrderConfirmationPageLiveViewSection(model)
        ];

        return this._relatedDataErrors.length === 0;
    }

    protected get _defaultConfig(): OLO.Config {
        return this._opts.defaultConfiguration || ApplicationConfig.defaultConfig;
    }

    public build(): ApplicationConfig | never {
        const model = this._model;

        if (this._opts.relatedDataValidation && !this._validateRelatedData(model)) {
            OrderTypeIdsValidators.logErrors(this._relatedDataErrors);

            throw new Error('Related data configuration is invalid. Check the console for more details.');
        }

        return new ApplicationConfig(model);
    }
}
