export class Objects {
    public static catchInvalidPaths<T>(obj: T, initialPath: string = ''): T {
        const asyncLogger = (() => {
            const ref = {
                current: null
            };

            let timeout;

            return (event) => {
                ref.current = event;
                if (!timeout) {
                    timeout = setTimeout(() => {
                        timeout = null;
                        console.error(
                            `Detected access to non-existing property in object -> path: ${ref.current}`.replace(/(ngOnDestroy|hasOwnProperty)\.?/gi, '')
                        );
                    }, 0);
                }
            };
        })();

        let path: string = initialPath;

        const props = {
            get: (target: any, name: string) => {
                if (!path) {
                    path = name;
                } else {
                    path += '.' + name;
                }

                if (target[name] === undefined) {
                    asyncLogger(path);
                    const nextProxy = new Proxy(
                        {
                            [name]: path
                        },
                        props
                    );

                    return nextProxy;
                }

                return target[name];
            },
            set: () => {
                console.warn('Overwriting not allowed in', path);

                return false;
            }
        } as ProxyHandler<any>;

        for (const key in obj) {
            if (key in obj && typeof obj[key] === 'object') {
                obj[key] = Objects.catchInvalidPaths(obj[key], key);
            }
        }

        const nextVal = new Proxy({ ...obj }, props);

        return nextVal;
    }

    public static extractSubValue<T>(obj: Record<string, any>, value: string): T {
        if (!obj) return undefined;

        const props = value.split('.');

        const v = props.reduce((substate, property) => {
            if (substate === null) {
                if (!obj.hasOwnProperty(property)) return undefined;

                return obj[property];
            }

            if (!substate.hasOwnProperty(property)) return undefined;

            return substate[property];
        }, null);


        return v;
    }

    public static sumMapValues<T>(map: Map<T, number>): number {
        let total: number = 0;

        for (let [key, value] of map) {
            total += value;
        }

        return total;
    }

    public static getPropertyOrDefault(obj: Record<string, any>, propName: string, defaultValue: any = ''): any {
        return obj.hasOwnProperty(propName) ? obj[propName] : defaultValue;
    }

    public static isObject(obj: any): boolean {
        // return (typeof value === 'object' || typeof value === 'function') && value !== null;
        return !Objects.isPrimitive(obj);
    }

    public static getType(obj: any): string {
        return Object.prototype.toString.call(obj).replace(/(\[object\s|\])/gim, '')
            .toLowerCase();
    }

    public static uberDeepFreez(object: Record<string, any>): void {
        Object.freeze(object);

        Object.keys(object).forEach(key => {
            const type: string = Objects.getType(object[key]);
            if (type !== 'array' && type !== 'object') {
                return;
            }

            return Objects.uberDeepFreez(object[key]);
        });
    }

    public static isPrimitive(obj: any): boolean {
        switch (Objects.getType(obj)) {
            case 'number':
            case 'null':
            case 'string':
            case 'undefined':
            case 'symbol':
            case 'boolean':
                return true;
            default:
                return false;
        }
    }

    public static getPropertyByString(obj: any, str: string): any {
        return str.split('.').reduce((p, c)=>p&&p[c]||null, obj);
    }

    public static overrideNestedProp<T extends Record<string, any>>(obj: T, property: string, newValue: any): T {
        if (!obj || Array.isArray(obj)) return obj;
        const strArr = property.split('.');
        const mirror = { ...obj };

        Object.keys(mirror).forEach((key: keyof T) =>
            key === strArr[strArr.length - 1]
                ? (mirror[key] = newValue)
                : (mirror[key] && typeof mirror[key] === 'object') && (mirror[key] = this.overrideNestedProp(mirror[key], property, newValue))
        );

        return mirror;
    }

    public static deleteNestedProp<T extends Record<string, any>>(obj: T, property: string): T {
        if (!obj || Array.isArray(obj)) return obj;
        const strArr = property.split('.');
        const mirror = { ...obj };

        Object.keys(mirror).forEach((key: keyof T) =>
            (key === strArr[strArr.length - 1]) && delete mirror[key] ||
            (mirror[key] && typeof mirror[key] === 'object') && (mirror[key] = this.deleteNestedProp(mirror[key], property))
        );

        return mirror;
    }
}
