import { enumType } from '@shared/core/types/common';

enum ONLINE_ORDER_STATUS {
    CREATED = OLO.Enums.ONLINE_ORDER_STATUS.CREATED,
    VALIDATED = OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED,
    SENT_TO_KITCHEN = OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_KITCHEN,
    SENT_TO_SITE = OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_SITE,
    RECIVED_AT_SITE = OLO.Enums.ONLINE_ORDER_STATUS.RECIVED_AT_SITE,
    FINALIZED = OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED,
    CANCELED = OLO.Enums.ONLINE_ORDER_STATUS.CANCELED,
    PENDING_FINALIZE = OLO.Enums.ONLINE_ORDER_STATUS.PENDING_FINALIZE,
}

export const onlineOrderStatusEnum = enumType('ONLINE_ORDER_STATUS', ONLINE_ORDER_STATUS);
