import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';
import { getPickupTimesForSelectedDate } from '../getPickupTimesForSelectedDate';
import { getSelectedOrderTypeId } from '../../orderTypes';

export const generatePickupTimesForAllLocationsByPeriod = (period: OLO.Ordering.Period, params: OLO.Ordering.GeneratePickupsParams) =>
    createSelector(getPickupTimesForSelectedDate(period.Date), getSelectedOrderTypeId, (edgeCases, orderTypeId) => {
        const DayOfWeek: number = period.DayOfWeek;

        const opts: OLO.Ordering.GeneratePickupsParams = {
            orderTimeoutBufferMins: 0,
            startBufferMins: 60,
            endBufferMins: 0,
            nextTick: 60,
            ...params,
        };

        return Utils.Pickups.generatePickupTimesFutureList(period, {
            orderTypeId,
            location: null,
            asapPickupMins: opts.nextTick,
            openingHours: [
                {
                    Date: period.Date,
                    DayOfWeek: DayOfWeek as APICommon.DayOfWeek,
                    OpeningTime: edgeCases.OpeningTime,
                    ClosingTime: edgeCases.ClosingTime,
                    // LocationId: 0, /* API bug */
                },
            ],
            orderTimeoutBufferMins: opts.orderTimeoutBufferMins,
            startBufferMins: opts.startBufferMins,
            endBufferMins: opts.endBufferMins,
            nextTick: opts.nextTick,
        });
    });
