import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';
import { flatMap, switchMap } from 'rxjs/operators';
import { ConvergePaymentProviderMapper } from '@shared/core/mappers/paymentProviders/converge.payment-provider.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class ConvergePaymentProviderService {
    private _injectedScript: HTMLScriptElement;

    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient) {}

    private async _attachVendorScriptHTMLTag(url: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this._injectedScript) return resolve(true);

            const scriptTag: HTMLScriptElement = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.src = url;

            document.body.appendChild(scriptTag);

            scriptTag.onload = () => {
                this._injectedScript = scriptTag;
                resolve(true);
            };

            scriptTag.onerror = () => {
                reject('unable load PP script');
            };
        });
    }

    private _request$(params: PPConverge.CommonParams): Observable<PPConverge.CheckoutJSSuccessResponseFields> {
        return Observable.create((observer) => {
            let status: string = null;

            if ('ConvergeEmbeddedPayment' in window === false) {
                observer.error(new Error('ConvergeEmbeddedPayment payment provider library not included in html'));
            }

            const callbacks = {
                onError: function (error: undefined) {
                    status = 'ERROR';
                    console.error('GOT AN ERROR', error);
                    observer.error(new Error('Payment error'));
                },
                onDeclined: function (response) {
                    status = 'DECLINED';
                    console.error('DECLINED', response);
                    observer.error(new Error(JSON.stringify(response)));
                },
                onApproval: function (response: PPConverge.CheckoutJSSuccessResponseFields) {
                    status = 'SUCCESS';
                    observer.next(response);
                    observer.complete();
                },
            };
            /* https://developer.elavon.com/#/api/eb6e9106-0172-4305-bc5a-b3ebe832f823.rcosoomi/versions/5180a9f2-741b-439c-bced-5c84a822f39b.rcosoomi/documents/?converge-integration-guide/book/integration_methods/checkoutjs.html */
            (window as any).ConvergeEmbeddedPayment.pay(params, callbacks);

            return () => {
                console.log('payment process has ended with status ' + status);
            };
        });
    }

    public convertAPIConvergeSettingsToAuthModelAsync$(model: OLO.DTO.ConvergeSettingsResponse): Observable<PPConverge.AuthRequestParams> {
        return new Observable((observer) => {
            this._attachVendorScriptHTMLTag(`${model.ApiUrl}Checkout.js`).then((isLoaded) => {
                if (isLoaded) {
                    observer.next({
                        ssl_merchant_id: model.MechantId,
                        ssl_pin: model.Pin,
                        ssl_user_id: model.UserId,
                        ssl_vendor_id: model.VendorId,
                        ssl_txn_auth_token: model.SessionToken.SessionToken,
                    });
                    observer.complete();
                }
            });
        });
    }

    public requestCardToken$(
        cardData: OLO.CreditCards.CreditCardDetails,
        locationNo: number = null,
        defaultSettings: OLO.DTO.ConvergeSettingsResponse = null,
    ): Observable<PPConverge.CheckoutJSSuccessResponseFields> {
        const model: PPConverge.CommonParams = {
            ssl_add_token: 'Y',
            ssl_transaction_type: 'CCGETTOKEN',
            ...ConvergePaymentProviderMapper.mapCreditCardToParams(cardData),
        };

        if (!locationNo) {
            if (!defaultSettings) return throwError('No default settings provided for converge payment provider');

            return this.convertAPIConvergeSettingsToAuthModelAsync$(ConvergePaymentProviderMapper.mapConvergeSettingsToAuthModel(defaultSettings)).pipe(
                switchMap((defaultAuthSettings) => this._request$({ ...model, ...defaultAuthSettings })),
            );
        }

        return this.getConvergeSettingsForLocation$(locationNo).pipe(flatMap((authSettings) => this._request$({ ...model, ...authSettings })));
    }

    public getConvergeSettingsForLocation$(locationNo: number): Observable<PPConverge.AuthRequestParams> {
        return this.httpClient.get<APIv3.ConvergeSettingsResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/converge/settings/${locationNo}`).pipe(
            switchMap((response: APIv3.ConvergeSettingsResponse) => {
                if (response.SessionToken.SessionToken === null) {
                    console.warn('Setup issue for Converge Payment Provider. Session token for location:', locationNo, ' is null. Full config:', response);
                }

                return this.convertAPIConvergeSettingsToAuthModelAsync$(ConvergePaymentProviderMapper.mapConvergeSettingsToAuthModel(response));
            }),
        );
    }
}
