import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';

import { getCurrentRoute } from '@shared/state/router/selectors';
import { getAppLocationMode } from '@shared/state/appSettings/selectors';

export const routeIsLocationDetailsPage = (regExp: RegExp = /\/locations\/\d+\/details\??/gim) =>
    createSelector(getCurrentRoute, getAppLocationMode, (route, locationMode) => {
        if (route === null || locationMode === null) return null;

        if (locationMode === OLO.Enums.APP_LOCATION_MODE.LOCATION) {
            const matchFound: RegExpMatchArray = route.url.match(regExp);

            return !!matchFound;
        }

        const config = new Statics.ConfigStatic().current;

        return route.params.LocationFriendlyName !== undefined && route.url ? route.url.includes(config.venue.name) : false;
    });
