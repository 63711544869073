import { createSelector } from '@ngrx/store';

import { getOrderErrorsMapped } from '../../onlineOrder/getOrderErrorsMapped';
import { getCartTotalQuantity } from '@shared/state/cart/selectors';
import { isPaymentDisabledForOnlineOrderValidation } from '../isPaymentDisabledForOnlineOrderValidation';
import { isPaymentDisabledForMemberWithCreditCard } from '../isPaymentDisabledForMemberWithCreditCard';
import { isPaymentDisabledForPayments } from '@shared/state/payment/selectors';
import { isPaymentDisabledForCards } from '@shared/state/creditCards/selectors';
import { isZeroPaymentsDisabled } from '../isZeroPaymentsDisabled';
import { hasExceededProductsLimit } from '../../restrictions/hasExceededProductsLimit';

export const isCardTypePaymentDisabled = (locationNo: number) =>
    createSelector(
        getOrderErrorsMapped(),
        getCartTotalQuantity,
        isPaymentDisabledForOnlineOrderValidation,
        isPaymentDisabledForMemberWithCreditCard,
        isPaymentDisabledForPayments,
        isPaymentDisabledForCards,
        isZeroPaymentsDisabled,
        hasExceededProductsLimit(locationNo),
        (errors, cartTotalQuantity, orderPaymentDisabled, memberPaymentDisabled, paymentDisabledForPayments, cardsPaymentDisabled, zeroPaymentDisabled, exceededLimit) =>
            !cartTotalQuantity ||
            paymentDisabledForPayments ||
            orderPaymentDisabled ||
            exceededLimit ||
            (memberPaymentDisabled && (zeroPaymentDisabled ?? memberPaymentDisabled)) ||
            (cardsPaymentDisabled && (zeroPaymentDisabled ?? cardsPaymentDisabled)) ||
            Boolean(errors),
    );
