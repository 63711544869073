import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { ModalsService } from './modals.shared.service';
import { LocationsService } from './locations.shared.service';

import { Observable, of } from 'rxjs';
import { map, filter, withLatestFrom, switchMap, auditTime, tap, take, combineLatest } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PickupsService {
    constructor(
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public store: Store<OLO.State>,
        public locationsService: LocationsService,
        public modalsService: ModalsService,
        public router: Router,
    ) {}

    public validateSelectedPickupTimeObjForOnlineMenu(
        date: Date = new Date(),
        pickupTimeObj: OLO.Ordering.PickupTime,
        onlineMenuTimes: { StartTime?: string; EndTime?: string; },
        orderingTimeInfo: OLO.DTO.LocationOrderingTimeInfoModel[] = null,
        locationUpdatedPickupTimeObj: OLO.DTO.MinimumPickupTimeModel = null,
    ): boolean {
        if (!pickupTimeObj || onlineMenuTimes === null || onlineMenuTimes.StartTime === null || onlineMenuTimes.EndTime === null) return false;

        /** I don't know what the hell the line below means but it was implemented by Rafal Gamon in TOLO-1016 */
        pickupTimeObj.Date = new Date(pickupTimeObj.Date);

        let isToday = Utils.Dates.isToday(pickupTimeObj.Date);
        let isPickupTimeInOnlineMenuTimeRange: boolean = Utils.Dates.isHourInHoursRange(pickupTimeObj.Hour, onlineMenuTimes.StartTime, onlineMenuTimes.EndTime);
        let openHours: OLO.DTO.OpeningHoursModel = orderingTimeInfo.find((obj) => obj.Date.split('T')[0] === pickupTimeObj.DateLocalISO?.split('T')[0]);

        /** Handle future orders only */
        const isPickupTimeInLocationOpenRange: boolean = !openHours ? true : Utils.Dates.isHourInHoursRange(pickupTimeObj.Hour, openHours.OpeningTime, openHours.ClosingTime);
        if (this.config.onlineOrders.scheduledOrders && !pickupTimeObj.IsToday) {
            return !(!isPickupTimeInOnlineMenuTimeRange || !isPickupTimeInLocationOpenRange);
        }

        const now: Date = new Date();
        const nowISOString = Utils.Dates.getLocalISOFormatDate(now);
        const isNowInOnlineMenuRange: boolean = Utils.Dates.isHourInHoursRange(nowISOString, onlineMenuTimes.StartTime, onlineMenuTimes.EndTime);
        const isOpenNow: boolean = openHours ? Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(now), openHours.OpeningTime, openHours.ClosingTime, 'from') : false;
        if (locationUpdatedPickupTimeObj) {
            const locationClosingHour: number = Utils.Dates.createHoursIntFromDate(openHours?.ClosingTime);
            const newPickupTime: Date = new Date(now.getTime() + locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000);
            const newPickupTimeHour: number = Utils.Dates.createHoursIntFromDate(newPickupTime);

            if (pickupTimeObj.IsAsap && newPickupTimeHour >= locationClosingHour) {
                return false;
            }

            if (!pickupTimeObj.IsAsap && isOpenNow) {
                const targetPickupTimeDiff = pickupTimeObj.Id - locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000;
                const prepTimeExceedsPickupTime = targetPickupTimeDiff < date.getTime();
                if (prepTimeExceedsPickupTime) {
                    return false;
                }
            }
        }

        const willBeOpenToday: boolean = openHours
            ? Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(now), Utils.Dates.getLocalISOFormatDate(now), openHours.ClosingTime, 'from')
            : false;
        const allowClosedLocationOrders = this.config.onlineOrders.allowClosedLocationOrders === true;

        if (!isToday || (!isPickupTimeInOnlineMenuTimeRange && !isPickupTimeInLocationOpenRange)) return false;

        if (allowClosedLocationOrders) {
            if (isOpenNow && pickupTimeObj.IsAsap === true && !isNowInOnlineMenuRange) return false;
            if (!isOpenNow && willBeOpenToday && pickupTimeObj.IsAsap === true && !isPickupTimeInOnlineMenuTimeRange) return false;
        }

        if (pickupTimeObj.IsAsap === true && !isNowInOnlineMenuRange) return false;

        let isUpdatedPickupTimeInLocationOpenHoursRange: boolean = true;
        if (locationUpdatedPickupTimeObj && openHours) {
            /**
             * We don't want to take orders for user that will show up after location is closed.
             * Check if pickupTime + target pickup time won't exceed location's opening time.
             */
            const newPickupTime: number = now.getTime() + locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000;
            isUpdatedPickupTimeInLocationOpenHoursRange = Utils.Dates.isHourInHoursRange(newPickupTime, openHours.OpeningTime, openHours.ClosingTime);
        }

        /**
         * Validate case when placeOrderTimeout has been exceeded but all params are ok
         * Used for PAYMENT CASE, final step, where order can still be placed if location is open,
         * newer pickup time is location open time range and online menu hasn't changed (TODO)
         */
        const hasExceededPlaceOrderTimeoutForPickupTime: boolean = pickupTimeObj.IsAsap ? false : date > pickupTimeObj.PlaceOrderTimeout;
        if (hasExceededPlaceOrderTimeoutForPickupTime && openHours && locationUpdatedPickupTimeObj) {
            return isPickupTimeInLocationOpenRange && isUpdatedPickupTimeInLocationOpenHoursRange && !isNowInOnlineMenuRange;
        }
        /* Regular check without updated data - used when navigating between pages */

        return !hasExceededPlaceOrderTimeoutForPickupTime && isPickupTimeInLocationOpenRange;
    }

    public checkAvailablePickups(locationNo: number): Observable<OLO.Ordering.PickupTime[]> {
        return this.store.pipe(
            select(selectors.getOrderTypeId),
            filter(orderTypeId => orderTypeId != null),
            tap((orderTypeId) => {
                const collectionType = new Utils.CollectionTypeGroupDetector(orderTypeId, this.config);
                this.store.dispatch(actions.AvailablePickupsCalculateRequest({ locationNo, collectionTypeId: collectionType.getCollectionType() }));
            }),
            switchMap(() =>
                this.store.pipe(
                    select(selectors.getAvailablePickupTimesForLocation(locationNo)),
                    withLatestFrom(this.store.pipe(select(selectors.isCalculatingAvailablePickups))),
                    filter(([availablePickups, isCalculating]) => Boolean(availablePickups?.hasSucceeded) && !isCalculating),
                    take(1),
                    map(([availablePickups]) => availablePickups.data),
                ),
            ),
        );
    }

    public validateCartWithPopup(): Observable<boolean | null> {
        return this.store.select(selectors.getCart).pipe(
            take(1),
            switchMap((cart) => of(cart).pipe(combineLatest(this.checkAvailablePickups(cart.locationNo)))),
            tap(([cart, availablePickups]) => {
                if (cart.pickupTime.IsAsap) {
                    const pickupTime = availablePickups.length ? availablePickups[0] : cart.pickupTime;
                    const pickupTimeObj = Utils.Pickups.overrdrivePickupTimeObjToAsap(pickupTime);
                    this.store.dispatch(actions.CartSetPickupTime(pickupTimeObj));
                }
                this.store.dispatch(actions.OnlineOrderRecalculateRequest());
            }),
            switchMap(([cart]) =>
                this.store.pipe(
                    select(selectors.getOnlineOrderState),
                    auditTime(10),
                    filter((onlineOrderState) => onlineOrderState.recalculateRequest.isRecalculating === false),
                    take(1),
                    switchMap((onlineOrderState) => {
                        if (onlineOrderState.recalculateRequest.hasFailed || onlineOrderState.recalculateRequest.data === null) {
                            return of(false);
                        }

                        return this.store.pipe(
                            select(selectors.getOrderingTimeInfoByCartLocationAndOrderType),
                            filter((orderingTimeInfo) => orderingTimeInfo !== null && orderingTimeInfo !== undefined),
                            take(1),
                            withLatestFrom(
                                this.store.select(selectors.getOrderingTimeInfoForCartPickupLocationNo(cart.locationNo)),
                                this.store.select(selectors.isLocationOpenNowByOrderingTimeInfoObj(cart.locationNo, cart.orderTypeId)),
                                this.store.select(selectors.getMinimumPickupTimeForLocationAndDate(cart.locationNo, cart.pickupTime.IsAsap ? null : cart.pickupTime.Date)),
                                this.store.select(selectors.isCollectionTypeDineIn),
                            ),
                            map(([orderTimeInfo, timeInfoObj, isOpenNow, preperationTimeObj, isDineIn]) => {
                                const closedLocationOrder =
                                    this.config.onlineOrders.allowClosedLocationOrders === true || this.config.onlineOrders.scheduledOrders ? true : isOpenNow;
                                if (orderTimeInfo.length === 0 || !timeInfoObj || !preperationTimeObj || !closedLocationOrder) return false;

                                if (isDineIn) {
                                    return true;
                                }

                                return this.validateSelectedPickupTimeObjForOnlineMenu(new Date(), cart.pickupTime, cart.onlineMenu, orderTimeInfo, preperationTimeObj);
                            }),
                            tap(this.handleCartValidationSummary.bind(this)),
                            take(1),
                        );
                    }),
                ),
            ),
        );
    }

    public handleCartValidationSummary(isCartValid: boolean): void {
        if (isCartValid === false) {
            return this.exitLocationWithPickupPrompt();
        }

        if (isCartValid === null) {
            this.modalsService.show({
                type: 'alert',
                params: {
                    preTitle: 'WARNING',
                    title: 'Error',
                    body: `There was an error
                            when validating your order.
                            Please try again.`,
                },
            });
        }
    }

    public exitLocationWithPickupPrompt(promptDelay: number = 200): void {
        this.store.dispatch(actions.SetCartPopup({ options: { isVisible: false, animation: null } }));
        this.store.dispatch(actions.LocationsFiltersReset());
        this.store.dispatch(actions.AvailablePickupsReset());
        this.store.dispatch(actions.CartReset());
        this.store.dispatch(actions.WizzardUnmountAll());
        this.store.dispatch(actions.CurrentLocationReset());

        this.modalsService.closeAll(['alert'], OLO.Components.Modals.MODAL_ANIMATION.NONE);

        this.router.navigate(['/']).then(() => {
            this.locationsService.requestLocations();
            setTimeout(() => {
                this.modalsService.show({
                    type: 'alert',
                    params: {
                        preTitle: this.t.cartEmptyModal.warning,
                        title: this.t.cartEmptyModal.cartIsEmpty,
                        body: this.t.cartEmptyModal.description,
                    },
                });
            }, promptDelay);
        });
    }
}
