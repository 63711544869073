import { createSelector } from '@ngrx/store';

import { wizzardState } from '../root';

export const getWizzardMenuFlowTotalSelectedProducts = createSelector(wizzardState, (wizzard) => {
    let total: number = 0;
    if (wizzard.itemsMenuFlow) {
        wizzard.itemsMenuFlow.Pages.forEach((page) => {
            page.Products.forEach((product) => {
                total += product.Quantity;
            });
        });
    }

    return total;
});
