import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

import { onlineOrderState } from '../root';
import { hasMetMinimumOrderValueCriteria } from '../hasMetMinimumOrderValueCriteria';

export const minimumSpendCriteriaError = createSelector(
    onlineOrderState,
    hasMetMinimumOrderValueCriteria,
    (onlineOrder, hasMetValueCriteria) => {
        if (hasMetValueCriteria === false) {
            const text = new Statics.TextsStatic().current;

            return Utils.Messages.mapErrors(
                // test tz - consider in future how to handle currency
                {
                    minimumOrderValue: Utils.ReplacePlaceholderVariables.replaceVariableWithValue(
                        text.errors.minimumOrderValue, 'value', `${onlineOrder.orderType.MinimumOrderValue.toFixed(2)}`)
                },
            );
        }

        return null;
    }
);
