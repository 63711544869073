import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';
import * as Utils from '@shared/core/utils';

import { getLocationPeriods } from '../getLocationPeriods';
import { getLocationDetails } from '@shared/state/locations/selectors';
import { getSelectedOrderTypeId } from '../../orderTypes';

export const getLocationFuturePickupList = (locationNo: number, format: string = 'ddd, D MMM', prefixes: boolean = true) => {
    const config = new Statics.ConfigStatic().current;

    return createSelector(getLocationPeriods({ locationNo, format, prefixes }), getLocationDetails(locationNo), getSelectedOrderTypeId, (periods, location, orderTypeId) => {
        if (!periods || !location) return null;

        const collectionTypeConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getDefaultCollectionType(orderTypeId);
        if (!collectionTypeConfig) return null;
        const orderingTimeInfo = new Utils.LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();

        const params: OLO.Ordering.GeneratePickupsParams = {
            orderTypeId,
            location,
            openingHours: orderingTimeInfo,
        };

        if ('orderTimeoutBufferMins' in collectionTypeConfig) {
            params.orderTimeoutBufferMins = collectionTypeConfig.orderTimeoutBufferMins;
            params.startBufferMins = collectionTypeConfig.startBufferMins;
            params.endBufferMins = collectionTypeConfig.endBufferMins;
            params.nextTick = collectionTypeConfig.nextTick;
        }
        if ('displayAsTimespans' in collectionTypeConfig) {
            params.displayAsTimespans = collectionTypeConfig.displayAsTimespans;
        }

        const result = periods.reduce((acc, period) => [...acc, ...Utils.Pickups.generatePickupTimesFutureList(period, params)], [] as OLO.Ordering.PickupTime[]);

        return result;
    });
};
