import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './member-card-barcodes-images.actions';

const initialState: OLO.State.MemberCardBarcodeImage[] = [];

export const memberCardBarcodesImagesReducerFn = createReducer<OLO.State.MemberCardBarcodeImage[]>(
    initialState,
    on(actions.MemberCardBarcodesImagesRequest, (state, action) => {
        let found: boolean = false;
        const recalculatedState: OLO.State.MemberCardBarcodeImage[] = state.reduce((acc, item) => {
            if (item.memberId === action.memberId && item.barcodeType === action.barcodeType) {
                found = true;

                return [
                    ...acc,
                    {
                        ...item,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    },
                ];
            }

            return [...acc, item];
        }, []);

        return found
            ? recalculatedState
            : [
                ...state,
                {
                    memberId: action.memberId,
                    barcodeType: action.barcodeType,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    downloadedDate: null,
                    data: null,
                },
            ];
    }),
    on(actions.MemberCardBarcodesImagesSuccessRequest, (state, action) =>
        state.map((obj) => {
            if (obj.memberId === action.memberId && obj.barcodeType === action.barcodeType) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    downloadedDate: new Date().getTime(),
                    data: action.payload || null,
                };
            }

            return obj;
        }),
    ),
    on(actions.MemberCardBarcodesImagesErrorRequest, (state, action) =>
        state.map((obj) => {
            if (obj.memberId === action.memberId && obj.barcodeType === action.barcodeType) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return obj;
        }),
    ),
);

export function memberCardBarcodesImagesReducer(state: OLO.State.MemberCardBarcodeImage[] | undefined, action: Action) {
    return memberCardBarcodesImagesReducerFn(state, action);
}
