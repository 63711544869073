import { createSelector } from '@ngrx/store';

import { membersState } from '../root';
import { getGuestData } from '../getGuestData';


export const isPartialMemberSet = createSelector(
    membersState,
    getGuestData,
    (members, guestData) => members.isGuestModeEnabled === true && guestData != null
);
