import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { wizzardState } from '../root';

export const getWizzardMenuFlowTotalValue = createSelector(wizzardState, (wizzard) => {
    const extraPrice = wizzard.itemsMenuFlow?.Pages ? Utils.Pricing.calculateExtraPriceForIngredients(wizzard.itemsMenuFlow) : 0;

    return wizzard.itemsMenuFlow ? wizzard.itemsMenuFlow.Quantity * (wizzard.itemsMenuFlow.UnitTotalValue + extraPrice) : null;
});
