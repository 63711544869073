import { createSelector } from '@ngrx/store';

import { paymentState } from '../root';

export const isPaying = createSelector(
    paymentState,
    payment => {
        const s = payment.paymentStepStatus;

        return s !== null && s !== 'failed' && s !== 'complete';
    }
);
