import { createAction, props } from '@ngrx/store';

export const SetApplicationInitFlag = createAction(
    '[App settings] Set init flag'
);

export const SetApplicationMode = createAction(
    '[App settings] Set location mode',
    props<{ mode: OLO.Enums.APP_LOCATION_MODE; }>(),
);

export const SetDeviceType = createAction(
    '[App settings] Set device type',
    props<{ deviceType: OLO.Enums.DEVICE_TYPE; }>(),
);

export const AppSettingsRequest = createAction(
    '[App settins] Request settings',
);

export const AppSettingsSuccessRequest = createAction(
    '[App settins] Success request settings',
    props<{ payload: OLO.DTO.LoyaltyAppModel; }>(),
);

export const AppSettingsErrorRequest = createAction(
    '[App settins] Error request settings',
    props<{ ex?: any; }>(),
);

export const AppSettingsSetOnlineStatus = createAction(
    '[App settings] Set online status',
    props<{ online: boolean; }>(),
);
