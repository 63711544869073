import { createSelector } from '@ngrx/store';
import { getHistoryOrderByOrderId } from '../getHistoryOrderByOrderId';

export const isHistoryOrderIsFinalized = (orderId: number) =>
    createSelector(
        getHistoryOrderByOrderId(orderId),
        (order) => {
            if (!order?.data) return false;

            return order.data.Status === OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED;
        }
    );
