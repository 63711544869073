import { createSelector } from '@ngrx/store';

import { getWizzardMenuFlowPageSelectedProducts } from '../getWizzardMenuFlowPageSelectedProducts';

export const canShowNextPriceForWizzardTile = (pageIdentifier: number, product: OLO.DTO.MenuFlowProduct) =>
    createSelector(getWizzardMenuFlowPageSelectedProducts(pageIdentifier), (selectedProducts) => {
        const isSelected = selectedProducts?.find((obj) => obj.ProductId === product.ProductId);
        if (!isSelected) {
            return false;
        }

        return Boolean(isSelected.UnitPrice);
    });
