import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import * as fromLocations from '@shared/state/locations/selectors';
import { getSelectedOrderTypeId } from '../../orderTypes/getSelectedOrderType';

export const getPickupTimesForSelectedDate = (date: Date | string = null) =>
    createSelector(fromLocations.getLocations, getSelectedOrderTypeId, (locations, orderTypeId) => {
        if (!locations) return null;
        let d: string = (!date && Utils.Dates.getLocalISOFormatDate(new Date(), false)) || '';
        if (date instanceof Date) {
            d = Utils.Dates.getLocalISOFormatDate(date);
        }

        if (typeof date === 'string') {
            d = date;
        }

        d = d.split('T')[0];

        const tempObj = {
            OpeningTime: null,
            ClosingTime: null,
        };

        locations.forEach((location) => {
            const openingHours = Utils.Pickups.getFilteredOrderingTimeInfo(location, orderTypeId).find((orderingObj) => orderingObj.Date.split('T')[0] === d);
            if (openingHours) {
                if (!openingHours.OpeningTime || !openingHours.ClosingTime) return;
                const tempOpen = tempObj.OpeningTime === null ? null : Utils.Dates.createHoursIntFromDate(tempObj.OpeningTime);
                const tempClose = tempObj.OpeningTime === null ? null : Utils.Dates.createHoursIntFromDate(tempObj.ClosingTime);
                const openingTime = openingHours.OpeningTime ? Utils.Dates.createHoursIntFromDate(openingHours.OpeningTime) : null;
                const closingTime = openingHours.ClosingTime ? Utils.Dates.createHoursIntFromDate(openingHours.ClosingTime) : null;

                if (tempOpen === null || tempOpen > openingTime) {
                    tempObj.OpeningTime = openingHours.OpeningTime;
                }

                if (tempClose === null || tempClose < closingTime) {
                    tempObj.ClosingTime = openingHours.ClosingTime;
                }
            }
        });

        return tempObj;
    });
