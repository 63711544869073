import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as Utils from '@shared/core/utils';

import { CollectionTypeParams } from '@shared/state';

@Injectable({
    providedIn: 'root',
})
export class OrderTypeService {
    constructor(
        private _store: Store<OLO.State>,
    ) {}

    private _getStorageParsed(orderTypeId: number): CollectionTypeParams {
        let storageCollection = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.COLLECTION_TYPE);

        if (orderTypeId && !isNaN(orderTypeId)) {
            Utils.Storage.set(OLO.Enums.USER_STORAGE.COLLECTION_TYPE, {
                orderTypeId: orderTypeId,
                address: null,
                tableNo: null,
            });
            storageCollection = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.COLLECTION_TYPE);
        }

        let storageParsed: CollectionTypeParams = null;
        if (storageCollection) {
            try {
                storageParsed = JSON.parse(storageCollection);
            } catch (error) {
                console.error('Unable to parse collection type values', storageCollection);
            }
        }

        return storageParsed;
    }

    public setCollectionTypeFromLocalStorage(): void {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        const orderTypeIdFromUrl = +urlParams.get('orderTypeId');
        const storageParsed = this._getStorageParsed(orderTypeIdFromUrl);
        if (storageParsed) {
            return this._store.dispatch(actions.SetCollectionType(storageParsed));
        }
    }

}
