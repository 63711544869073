import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { LocationsService } from './locations.shared.service';
import { RouteService } from './route.shared.service';
import { OnlineMenuService } from './online-menu.shared.service';
import { ModalsService } from './modals.shared.service';

import { Observable, of } from 'rxjs';
import { take, filter, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LocationDetailsService {
    constructor(
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public store: Store<OLO.State>,
        public actions$: Actions,
        public locationsService: LocationsService,
        public routeService: RouteService,
        public onlineMenuService: OnlineMenuService,
        public modalsService: ModalsService,
    ) {}

    public accessDeniedModal(): boolean {
        const id: number = new Date().getTime();

        this.store.select(selectors.getAllModals).pipe(
            filter((modals) => modals.length === 0),
            take(1)
        )
            .subscribe(() => {
                this.modalsService.show({
                    id,
                    type: 'alert',
                    params: {
                        showButton: true,
                        buttonLabel: this.t.outOfHoursModal.findAnotherStore,
                        title: this.t.outOfHoursModal.outOfHoursTitle,
                        body: this.t.outOfHoursModal.description,
                    },
                });
            });


        this.actions$.pipe(ofType(actions.ModalClose), take(1)).subscribe(() => {
            this.routeService.navigateToLocationsSearchView();
        });

        return false;
    }

    public canEnterCurrentLocation$(): Observable<{
        locationNo: number;
        locationDetails: OLO.DTO.LocationBusinessModel;
    }> {
        this.store.dispatch(actions.CurrentLocationValidationRequest());

        const accessDenied = () => {
            this.store.dispatch(actions.CurrentLocationValidationErrorRequest());

            return of(null);
        };

        return this.store.pipe(
            select(selectors.currentLocationNoByRoute),
            filter((locationNo) => locationNo !== undefined),
            switchMap((locationNo) => {
                if (!locationNo) return accessDenied();

                return this.store.pipe(
                    select(selectors.getAvailablePickupTimesForLocation(locationNo)),
                    withLatestFrom(this.store.pipe(select(selectors.getOrderTypeId))),
                    switchMap(([pickupTime, orderTypeId]) => {
                        if (!pickupTime) {
                            const collectionType = new Utils.CollectionTypeGroupDetector(orderTypeId, this.config);
                            this.store.dispatch(actions.AvailablePickupsCalculateRequest({ locationNo, collectionTypeId: collectionType.getCollectionType() }));
                        }

                        return this.store.pipe(
                            select(selectors.isLocationLoading(locationNo)),
                            filter((isLoading) => isLoading === false),
                            withLatestFrom(this.store.pipe(select(selectors.canOrderFromLocation(locationNo))), this.store.pipe(select(selectors.getLocationDetails(locationNo)))),
                            switchMap(([isLoading, canEnterLocation, locationDetails]) => {
                                if (!canEnterLocation) return accessDenied();
                                this.store.dispatch(actions.CurrentLocationSet({ locationNo }));
                                this.store.dispatch(actions.CurrentLocationValidationSuccessRequest());

                                return of({
                                    locationNo,
                                    locationDetails,
                                });
                            }),
                        );
                    }),
                );
            }),
            take(1),
        );
    }
}
