import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const isOnlineOrderUpdatedFailed = createSelector(
    onlineOrderState,
    onlineOrder => (
        onlineOrder.updateRequest.hasFailed
    )
);
