import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './online-menu-products-images.actions';

const initialState: OLO.State.OnlineMenuPageProductsImages[] = [];

export const onlineMenuProductsImagesReducerFn = createReducer(
    initialState,
    on(
        actions.OnlineMenuProductsImagesRequest,
        (state, action) => {
            const foundObj = state.find(obj => obj.pageId === action.pageId);

            if (!foundObj) {
                return [
                    ...state,
                    {
                        pageId: action.pageId,
                        isDownloading: true,
                        hasFailed: false,
                        hasSucceeded: false,
                        data: null,
                    }
                ];
            }

            return state.map(obj => {
                if (obj.pageId === action.pageId) {
                    return {
                        ...obj,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    };
                }
                return obj;
            });
        }
    ),
    on(
        actions.OnlineMenuProductsImagesSuccessRequest,
        (state, action) => state.map(obj => {
            if (obj.pageId === action.pageId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    data: action.payload
                };
            }
            return obj;
        })
    ),
    on(
        actions.OnlineMenuProductsImagesErrorRequest,
        (state, action) => state.map(obj => {
            if (obj.pageId === action.pageId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }
            return obj;
        })
    ),
);

export function onlineMenuProductsImagesReducer(state: OLO.State.OnlineMenuPageProductsImages[] | undefined, action: Action) {
    return onlineMenuProductsImagesReducerFn(state, action);
}
