import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { GoogleAnalyticsEffects } from './google-analytics.effects';

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            GoogleAnalyticsEffects
        ]),
    ],
})
export class GoogleAnalyticsStateModule { }
