import { createSelector } from '@ngrx/store';

import { getAvailablePickupTimesForLocation } from '@shared/state/availablePickups/selectors';
import { getLocationFuturePickupList } from '../getLocationFuturePickupList';

export const getAvailablePickupTimesWithFutureForLocation = (locationNo: number, futureOrders: boolean = false) =>
    createSelector(getAvailablePickupTimesForLocation(locationNo), getLocationFuturePickupList(locationNo), (availablePickups, futurePickupList) => {
        let arr: OLO.Ordering.PickupTime[] = [];
        if (!locationNo || !availablePickups) return arr;

        if (availablePickups.data) {
            arr = [...availablePickups.data];
        }
        if (futureOrders && futurePickupList) {
            futurePickupList.forEach((pickup) => {
                if (arr.some((obj) => obj.Id === pickup.Id)) return;

                arr.push(pickup);
            });
        }

        return arr;
    });
