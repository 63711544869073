import { MetaReducer, Action } from '@ngrx/store';

import { __devMetaReducer } from './dev/dev.meta-reducers';
import { resetMetaReducer } from './reset/reset.meta-reducers';
import { stateMetaReducer } from './state/state.meta-reducer';


export const stateMetaReducers: MetaReducer<OLO.State, Action>[] = [
    __devMetaReducer,
    resetMetaReducer,
    stateMetaReducer,
];
