import { createSelector } from '@ngrx/store';

import { restrictionsLimit } from '../restrictionsLimit';

export const hasExceededProductsLimit = (locationNo: number) =>
    createSelector(restrictionsLimit(locationNo), (reports) => {
        if (!reports) return false;

        return reports.some((report) => report.isExceeded === true);
    });
