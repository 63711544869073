import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const isUpdatingOnlineOrder = createSelector(
    onlineOrderState,
    onlineOrder => (
        onlineOrder.updateRequest.isUpdating || onlineOrder.downloadRequest.isDownloading
    )
);
