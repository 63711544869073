import * as io from 'io-ts';
import { boolean, nullableString } from '@shared/core/types/common';

const rootDefinition: io.MixedObject<OLO.Config.SignUpRewardsAccountLinking> = {
    enabled: boolean,
    introText: nullableString,
    headerText: nullableString,
    descriptionText: nullableString,
    cardNumberLabelText: nullableString,
};

export const signUpRewardsAccountLinkingSchema = io.type(rootDefinition);
