import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

export const freeValidProducts = createSelector(
    membersState,
    members => {
        if (!members.data || !members.freeProducts.data) return null;

        const now: Date = new Date();

        return members.freeProducts.data.filter(obj => obj.MemberId === members.data.MemberId && new Date(obj.ExpiryDate) > now && obj.IsRedeemed === false);
    }
);
