import * as Statics from '@shared/core/statics';

import { CollectionTypeHelper } from './collection-type-helper.utils';
import { Dates } from './dates.utils';
import { LocationCollectionTypesChecker } from './location-collection-types-checker.utils';
import { LocationOpenStatus } from './location-open-status.utils';
import { LocationOrderingTimeInfo } from './location-ordering-time-info.utils';

type CanOrderWithDineInParams = {
    date?: Date;
    orderTypeId?: number;
};

export class LocationDineIn {
    private _config: OLO.Config = new Statics.ConfigStatic().current;
    private _collectionTypesChecker!: LocationCollectionTypesChecker;

    /**
     * Creates methods to help with dine in types
     * @param _location
     * @param _orderTypeId ONLY provide if you are 100% sure that provided orderTypeId is DINE IN.
     */
    constructor(private _location: OLO.DTO.OnlineOrderingLocationBusinessModel, private _orderTypeId?: number) {
        this._collectionTypesChecker = new LocationCollectionTypesChecker(_location, this._config);
    }

    /**
     * Based on current order type and application config, checks if location is allowed for NOW to make orders for dine in
     * @param {CanOrderWithDineInParams=} params
     * @returns {boolean} boolean
     */
    public canOrderWithDineIn(params?: CanOrderWithDineInParams) {
        const date = params?.date || new Date();
        const isoDate = Dates.getLocalISOFormatDate(date);
        const collectionTypeFromConfig = new CollectionTypeHelper(this._config.collectionTypes);
        const orderTypeId = params?.orderTypeId || this._orderTypeId || null;

        const targetOrderTypeIds = collectionTypeFromConfig.getDineInCollectionTypeConfig().orderTypeIds;

        if (/* (orderTypeId && !targetOrderTypeIds?.includes(orderTypeId)) ||  */ !this._collectionTypesChecker.hasDineIn() || !Dates.isToday(date)) {
            return false;
        }

        const orderingTimeInfo = orderTypeId
            ? new LocationOrderingTimeInfo(this._location, orderTypeId).getOrderingTimeInfo()
            : targetOrderTypeIds?.map((id) => new LocationOrderingTimeInfo(this._location, id).getOrderingTimeInfo()).filter((obj) => obj)[0];

        if (!orderingTimeInfo) {
            return false;
        }

        const foundOrderingTimeInfo = orderingTimeInfo.find((obj) => Dates.getChunk(obj.Date) === Dates.getChunk(isoDate));
        if (!foundOrderingTimeInfo) {
            return false;
        }

        const isOpen = new LocationOpenStatus(foundOrderingTimeInfo).isOpenForProvidedDate(isoDate);
        if (!isOpen) {
            return false;
        }

        const isInTimeRange = Dates.isHourInHoursRange(isoDate, foundOrderingTimeInfo.OpeningTime, foundOrderingTimeInfo.ClosingTime);

        return isInTimeRange;
    }
}
