import { createSelector } from '@ngrx/store';

import { isOnlineMapped } from '@shared/state/appSettings/selectors';
import { getPaymentErrorsMapped } from '@shared/state/payment/selectors';
import { hasRecalculateFailedErrorMapped } from '@shared/state/onlineOrder/selectors';

export const getOrderErrorsMapped = () =>
    createSelector(
        isOnlineMapped(),
        getPaymentErrorsMapped,
        hasRecalculateFailedErrorMapped,
        (offlineErrors, paymentErrors, onlineOrders) => offlineErrors || paymentErrors || onlineOrders,
    );
