import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './online-order.actions';

const initialState: OLO.State.OnlineOrder = {
    saveConfirmationUrl: {
        isSaving: false,
        hasSucceeded: false,
        hasFailed: false,
        saveDate: null,
        data: null,
    },
    createRequest: {
        isCreating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    updateRequest: {
        isUpdating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    cancelRequest: {
        isCanceling: false,
        hasSucceeded: false,
        hasFailed: false
    },
    downloadRequest: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    recalculateRequest: {
        isRecalculating: false,
        hasSucceeded: false,
        hasFailed: false,
        data: null,
    },
    voucherRequest: {
        isVoucherAdding: false,
        hasSucceeded: false,
        hasFailed: false,
        code: null,
        error: null,
    },
    sendEmailReceipt: {
        isSending: false,
        hasSucceeded: false,
        hasFailed: false,
        orderId: null,
    },
    orderType: null,

    data: null,
};

export const onlineOrdersReducerFn = createReducer(
    initialState,
    on(
        actions.OnlineOrderStateReset,
        (_state, _action) => JSON.parse(JSON.stringify(initialState))
    ),
    on(
        actions.OnlineOrderSaveConfirmationUrlRequest,
        (state, _action) => ({
            ...state,
            saveConfirmationUrl: {
                ...state.saveConfirmationUrl,
                isSaving: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderSaveConfirmationUrlSuccessRequest,
        (state, action) => ({
            ...state,
            saveConfirmationUrl: {
                ...state.saveConfirmationUrl,
                isSaving: false,
                hasSucceeded: true,
                hasFailed: false,
                saveDate: new Date().getTime(),
                data: action.payload,
            }
        })
    ),
    on(
        actions.OnlineOrderSaveConfirmationUrlErrorRequest,
        (state, _action) => ({
            ...state,
            saveConfirmationUrl: {
                ...state.saveConfirmationUrl,
                isSaving: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.OnlineOrderSendEmailReceiptRequest,
        (state, action) => ({
            ...state,
            sendEmailReceipt: {
                ...state.sendEmailReceipt,
                isSending: true,
                hasSucceeded: false,
                hasFailed: false,
                orderId: action.orderId,
            }
        })
    ),
    on(
        actions.OnlineOrderSendEmailReceiptSuccessReset,
        state => ({
            ...state,
            sendEmailReceipt: {
                ...state.sendEmailReceipt,
                isSending: false,
                hasSucceeded: false,
                hasFailed: false
            }
        })
    ),
    on(
        actions.OnlineOrderSendEmailReceiptSuccessRequest,
        (state, action) => ({
            ...state,
            sendEmailReceipt: {
                ...state.sendEmailReceipt,
                isSending: false,
                hasSucceeded: true,
                hasFailed: false,
                orderId: action.orderId,
            }
        })
    ),
    on(
        actions.OnlineOrderSendEmailReceiptErrorRequest,
        (state, action) => ({
            ...state,
            sendEmailReceipt: {
                ...state.sendEmailReceipt,
                isSending: false,
                hasSucceeded: false,
                hasFailed: true,
                orderId: action.orderId,
            }
        })
    ),
    on(
        actions.OnlineOrderSendEmailReceiptReset,
        (state, _action) => ({
            ...state,
            sendEmailReceipt: {
                isSending: false,
                hasSucceeded: false,
                hasFailed: false,
                orderId: null,
            }
        })
    ),
    on(
        actions.OnlineOrderTypeSelect,
        (state, action) => ({
            ...state,
            orderType: action.orderType
        })
    ),
    on(
        actions.OnlineOrderTypeUpdateValues,
        (state, action) => ({
            ...state,
            orderType: {
                ...state.orderType,
                Details: state.orderType.Details.map(obj => {
                    const found = action.details.find(val => val.Id === obj.Id);
                    if (found) {
                        return {
                            ...obj,
                            _Value: found._Value
                        };
                    }

                    return obj;
                }),
                Disclaimers: state.orderType.Disclaimers?.map(obj => {
                    const found = action.disclaimers.find(val => val.Id === obj.Id);
                    if (found) {
                        return {
                            ...obj,
                            _Value: found._Value
                        };
                    }

                    return obj;
                }) || null
            }
        })
    ),
    on(
        actions.OnlineOrderResetOrderTypeValues,
        (state, _action) => ({
            ...state,
            orderType: {
                ...state.orderType,
                Details: state.orderType.Details.map(obj => {
                    const newObj = { ...obj };
                    delete newObj._Value;

                    return newObj;
                })
            }
        })
    ),
    on(
        actions.OnlineOrderClearPostOrderRequestFlags,
        (state, _action) => ({
            ...state,
            createRequest:
            {
                isCreating: false,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.OnlineOrderCreateRequest,
        (state, _action) => ({
            ...state,
            createRequest: {
                isCreating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderCreateSuccessRequest,
        (state, action) => ({
            ...state,
            createRequest: {
                isCreating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: {
                ...state.data, // important. Some properties are filled only during recalculate call we don't want to override them
                ...{ ...action.payload },
            }

        })
    ),
    on(
        actions.OnlineOrderCreateErrorRequest,
        (state, _action) => ({
            ...state,
            createRequest: {
                isCreating: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.OnlineOrderUpdateRequest,
        (state, _action) => ({
            ...state,
            updateRequest: {
                ...state.createRequest,
                isUpdating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderUpdateSuccessRequest,
        (state, action) => ({
            ...state,
            updateRequest: {
                ...state.createRequest,
                isUpdating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: {
                ...state.data,
                ...action.payload,
            }
        })
    ),
    on(
        actions.OnlineOrderUpdateErrorRequest,
        (state, _action) => ({
            ...state,
            updateRequest: {
                ...state.createRequest,
                isUpdating: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.OnlineOrderUpdateRequestReset,
        (state, _action) => ({
            ...state,
            updateRequest: {
                ...state.createRequest,
                isUpdating: false,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.OnlineOrderCancelRequest,
        (state, _action) => ({
            ...state,
            cancelRequest: {
                ...state.cancelRequest,
                isCanceling: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderCancelSuccessRequest,
        (state, _action) => ({
            ...state,
            cancelRequest: {
                ...state.cancelRequest,
                isCanceling: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            // data: {
            //     ...state.data,
            //     ...action.payload,
            // }
        })
    ),
    on(
        actions.OnlineOrderCancelErrorRequest,
        (state, _action) => ({
            ...state,
            cancelRequest: {
                ...state.cancelRequest,
                isCanceling: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.OnlineOrderCancelRequestReset,
        (state, _action) => ({
            ...state,
            cancelRequest: {
                ...state.cancelRequest,
                isCanceling: false,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.OnlineOrderRequest,
        (state, _action) => ({
            ...state,
            downloadRequest: {
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderSuccessRequest,
        (state, action) => ({
            ...state,
            downloadRequest: {
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: action.payload,
        })
    ),
    on(
        actions.OnlineOrderErrorRequest,
        (state, _action) => ({
            ...state,
            downloadRequest: {
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.OnlineOrderRecalculateRequest,
        (state, _action) => ({
            ...state,
            recalculateRequest: {
                ...state.recalculateRequest,
                isRecalculating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.OnlineOrderRecalculateSuccessRequest,
        (state, action) => ({
            ...state,
            recalculateRequest: {
                isRecalculating: false,
                hasSucceeded: true,
                hasFailed: false,
                data: action.payload
            },
            data: {
                ...state.data,
                ...action.payload,
            }
        })
    ),
    on(
        actions.OnlineOrderRecalculateErrorRequest,
        (state, _action) => ({
            ...state,
            recalculateRequest: {
                ...state.recalculateRequest,
                isRecalculating: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.OnlineOrderAddVoucherRequest,
        (state, action) => ({
            ...state,
            voucherRequest: {
                ...state.voucherRequest,
                isVoucherAdding: true,
                hasSucceeded: false,
                hasFailed: false,
                code: action.code,
                error: null,
            }
        })
    ),
    on(
        actions.OnlineOrderAddVoucherSuccessRequest,
        (state, action) => ({
            ...state,
            voucherRequest: {
                isVoucherAdding: false,
                hasSucceeded: true,
                hasFailed: false,
                code: action.code,
                error: null,
            },
            data: {
                ...state.data,
                ...action.payload,
                ActivatedVouchers: action.payload.ActivatedVouchers,
            },
            recalculateRequest: {
                ...state.recalculateRequest,
                data: {
                    ...state.recalculateRequest.data,
                    ...action.payload,
                    ActivatedVouchers: action.payload.ActivatedVouchers
                }
            }
        })
    ),
    on(
        actions.OnlineOrderAddVoucherErrorRequest,
        (state, action) => ({
            ...state,
            voucherRequest: {
                ...state.voucherRequest,
                isVoucherAdding: false,
                hasSucceeded: false,
                hasFailed: true,
                code: null,
                error: {
                    error: {
                        Message: (() => {
                            const messageFromApi = action.ex.error?.Message;
                            if (messageFromApi && (messageFromApi.includes('exceeded') || messageFromApi.includes('was available'))) {
                                return action.staticText.addVoucherModal.voucherHasExpired;
                            } else if (messageFromApi && (messageFromApi.includes('redeemed') || messageFromApi.includes('redemption'))) {
                                return action.staticText.addVoucherModal.voucherRedeemed;
                            } else if (messageFromApi && messageFromApi.includes('location')) {
                                return action.staticText.addVoucherModal.voucherNotAvailableAtThisStore;
                            } else {
                                return action.staticText.addVoucherModal.codeIsInvalid;
                            }
                        })()
                    },
                    status: action.ex.status
                },
            }
        })
    ),
    on(
        actions.OnlineOrderVoucherErrorReset,
        (state) => ({
            ...state,
            voucherRequest: {
                ...state.voucherRequest,
                isVoucherAdding: false,
                hasSucceeded: false,
                hasFailed: false,
                code: null,
                error: null,
            }
        })
    ),
    on(
        actions.OnlineOrderRemoveVoucherRequest,
        (state, _action) => ({
            ...state,
            voucherRequest: {
                ...state.voucherRequest,
                isVoucherAdding: !!state.data.ActivatedVouchers,
                hasSucceeded: false,
                hasFailed: false,
                code: null,
                error: null,
            }
        })
    ),
    on(
        actions.OnlineOrderRemoveVoucherSuccessRequest,
        (state, action) => ({
            ...state,
            voucherRequest: {
                isVoucherAdding: false,
                hasSucceeded: true,
                hasFailed: false,
                code: null,
                error: null,
            },
            data: {
                ...state.data,
                ...action.payload,
                ActivatedVouchers: action.payload.ActivatedVouchers
            },
            recalculateRequest: {
                ...state.recalculateRequest,
                data: {
                    ...state.recalculateRequest.data,
                    ...action.payload,
                    ActivatedVouchers: action.payload.ActivatedVouchers
                }
            }
        })
    ),
    on(
        actions.OnlineOrderRemoveVoucherErrorRequest,
        (state, action) => ({
            ...state,
            voucherRequest: {
                ...state.voucherRequest,
                isVoucherAdding: false,
                hasSucceeded: false,
                hasFailed: true,
                error: action.ex,
            }
        })
    ),
    on(
        actions.OnlineDeliveryAddressSet,
        (state, action) => ({
            ...state,
            data: {
                ...state.data,
                DeliveryDetails: action.address
            }
        })
    ),
);


export function onlineOrdersReducer(state: OLO.State.OnlineOrder | undefined, action: Action) {
    return onlineOrdersReducerFn(state, action);
}
