import { createSelector } from '@ngrx/store';

import { wizzardState } from '../root';

export const getWizzardMenuFlowAllPageProducts = (pageIdentifier: number) =>
    createSelector(wizzardState, (wizzard) => {
        if (!wizzard.itemsMenuFlow || !wizzard.itemsMenuFlow.Pages) return null;
        const page = wizzard?.itemsMenuFlow?.Pages?.find((p) => p.PageIdentifier === pageIdentifier);

        return page?.Products?.length > 0 ? page.Products : null;
    });
