import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const getActiveVoucher = createSelector(
    onlineOrderState,
    onlineOrder =>
        onlineOrder.data?.ActivatedVouchers?.length ?
            onlineOrder.data?.ActivatedVouchers[0] :
            null
);
