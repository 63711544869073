import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const isOnlineOrderCancelledSuccessfully = createSelector(
    onlineOrderState,
    onlineOrder => (
        onlineOrder.cancelRequest.hasSucceeded
    )
);
