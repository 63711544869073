import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './order-types.actions';

const initialState: OLO.State.OrderTypes[] = [];

export const orderTypesReducerFn = createReducer(
    initialState,
    on(
        actions.OrderTypesRequest,
        (state, action) => {
            const foundObj = state.find(obj => obj.locationNo === action.locationNo);

            if (!foundObj) {
                return [
                    ...state,
                    {
                        locationNo: action.locationNo,
                        isDownloading: true,
                        hasFailed: false,
                        hasSucceeded: false,
                        data: null,
                    }
                ];
            }

            return state.map(obj => {
                if (obj.locationNo === action.locationNo) {
                    return {
                        ...obj,
                        isDownloading: true,
                        hasFailed: false,
                        hasSucceeded: false,
                    };
                }
                return obj;
            });
        }
    ),
    on(
        actions.OrderTypesSuccessRequest,
        (state, action) => state.map(obj => {
            if (obj.locationNo === action.locationNo) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasFailed: false,
                    hasSucceeded: true,
                    data: action.payload,
                    downloadedDate: new Date().getTime(),
                };
            }
            return obj;
        }),
    ),
    on(
        actions.OrderTypesErrorRequest,
        (state, action) => state.map(obj => {
            if (obj.locationNo === action.locationNo) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasFailed: true,
                    hasSucceeded: false,
                };
            }
            return obj;
        })
    )
);

export function orderTypesReducer(state: OLO.State.OrderTypes[] | undefined, action: Action) {
    return orderTypesReducerFn(state, action);
}
