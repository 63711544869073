export class PickupModels {
    private get _baseModel() {
        return class PickupScheduleObject {
            public Id: Nullable<number> = null;
            public Index: Nullable<number> = null;
            public Name: Nullable<string> = null;
            public ShortName: Nullable<string> = null;
            public Date: Nullable<Date> = null;
            public MinutesFromNow: Nullable<number> = null;
            public IsAsap: boolean = false;
            public Hour: Nullable<string> = null;
            public DateLocalISO: Nullable<string> = null;
            public PlaceOrderTimeout: Nullable<Date> = null;
            public IsToday: Nullable<boolean> = null;
            public DayNo: Nullable<number> = null;
            public TimeSpan: Nullable<OLO.Ordering.PickupTimeSpan> = null;

            constructor(model: Partial<OLO.Ordering.PickupTime> = {}) {
                Object.keys(model).forEach((key) => {
                    this[key] = model[key];
                });
            }
        };
    }

    public generate(pickupObjectModel: Partial<OLO.Ordering.PickupTime> = {}): OLO.Ordering.PickupTime {
        return new this._baseModel(pickupObjectModel);
    }

    public generateDefaultSchedule(text: T.StaticTexts, overwrite: Partial<OLO.Ordering.PickupTime> = {}): OLO.Ordering.PickupTime {
        return new this._baseModel({
            Id: -1,
            Name: text.hero.scheduleForLater,
            ShortName: text.hero.scheduleForLater,
            ...overwrite,
        });
    }

    public generateDefaultAsap(text: T.StaticTexts, overwrite: Partial<OLO.Ordering.PickupTime> = {}): OLO.Ordering.PickupTime {
        return new this._baseModel({
            Id: 1,
            Name: text.hero.todayASAP,
            ShortName: text.hero.asap,
            DayNo: 0,
            ...overwrite,
        });
    }
}
