import { Component, ViewChild, ViewContainerRef, Inject, AfterViewInit, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Event, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { fadeInOut } from '@shared/core/animations';
import { LazyLoaderService, ModalsService, InitService } from '@shared/core/services';
import { ENV_TOKEN } from '@shared/core/tokens';
import { ReturningMemberService } from '@shared/core/services/returning-member.shared.service';
import * as Services from '@shared/core/services';
import { GoogleTagManagerService } from '@shared/core/services/google/google-tag-manager.shared.service';
import * as Tokens from '@shared/core/tokens';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [trigger('routeAnimations', [transition('* <=> *', [useAnimation(fadeInOut)])])],
})
export class AppComponent implements AfterViewInit, OnInit {
    @ViewChild('dev', { read: ViewContainerRef, static: false }) public dev: ViewContainerRef;
    private _quickSignInHasBeenDisplayed: boolean = false;

    constructor(
        @Inject(ENV_TOKEN) private _env: Environment,
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        private _initService: InitService,
        private _router: Router,
        private _modalsService: ModalsService,
        private _lazyLoaderService: LazyLoaderService,
        private _returningMemberService: ReturningMemberService,
        private readonly _sessionService: Services.SessionService,
        private _gtmService: GoogleTagManagerService,
        private _titleService: Title
    ) {
        this._router.events
            .pipe(
                filter((event: Event): event is RouterEvent => event instanceof NavigationStart)
            ).subscribe(() => {
                if (!this._quickSignInHasBeenDisplayed) {
                    this._modalsService.closeAll();
                    this._returningMemberService.openLoginModalForReturningMember();
                    this._quickSignInHasBeenDisplayed = true;
                }

                this._sessionService.getSession()
                    .pipe(take(1))
                    .subscribe(res => {
                        if (res) {
                            this._quickSignInHasBeenDisplayed = true;
                        }
                    });
            });
    }

    ngOnInit() {
        this._setTitle();
        // TODO check if it is neccesary
        if (this._config.google.tagManager) {
            this._gtmTagInit();
        }
    }

    ngAfterViewInit(): void {
        this._initServ();
    }

    private _gtmTagInit(): void {
        this._gtmService.pushTag({ ecommerce: null });
    }

    private _initServ(): void {
        this._initService.init().then(() => {
            if (!this._env.production) {
                this._lazyLoaderService.loadModuleWithComponent({
                    viewRefContainer: this.dev,
                    name: 'dev',
                });
            }
        });
    }

    private _setTitle(): void {
        this._titleService.setTitle(this.t.pageTitle.genericTitle);
    }
}
