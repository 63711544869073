import { createSelector } from '@ngrx/store';

import { wizzardState } from '../root';

export const wizzardPageHasSelectedAnyProduct = (pageDetails: OLO.DTO.MenuFlowPage) =>
    createSelector(wizzardState, (wizzard) => {
        const page = wizzard?.itemsMenuFlow?.Pages?.find((p) => p.PageIdentifier === pageDetails.PageIdentifier);

        return page && page.Products ? page.Products.length > 0 : null;
    });
