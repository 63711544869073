export class VendorPayments {
    public static applePayCanMakePayments = window.ApplePaySession
    && ApplePaySession != null
    && ApplePaySession.canMakePayments() || false;

    /**
     * Check if application is configured to use Apple Pay
     * @param {OLO.Config} config
     * @returns {boolean}
     */
    public static isAppConfiguredForApplePay(config: OLO.Config): boolean {
        return Boolean(config.payments?.applePay?.enabled && config.payments?.applePay?.merchantId);
    }

    /**
     * Check if client can pay with Apple Pay payment method
     * @param {OLO.Config} config
     * @returns {boolean}
     */
    public static clientCanUseApplePayMethod(config: OLO.Config): boolean {
        return VendorPayments.applePayCanMakePayments && VendorPayments.isAppConfiguredForApplePay(config);
    }

    /**
     * Check if application is configured to use Apple Pay
     * @param {OLO.Config} config
     * @returns {boolean}
     */
    public static isAppConfiguredForGooglePay(config: OLO.Config): boolean {
        return config.payments?.googlePay?.enabled === true && Boolean(config.payments?.googlePay?.gateway) && Boolean(config.payments?.googlePay?.gatewayMerchantId);
    }

    /**
     * Check if application is configured for pay in store
     * @param {OLO.Config} config
     * @returns {boolean}
     */
    public static isAppConfiguredForPayInStore(config: OLO.Config): boolean {
        return config.payments?.payInStore === true;
    }

    /**
     * Check if at least one vendor payment provider is configured and available for making payments
     * @param {OLO.Config} config
     * @returns {boolean}
     */
    public static isAnyVendorPaymentMethodAvailable(config: OLO.Config): boolean {
        return VendorPayments.clientCanUseApplePayMethod(config) && config?.payments?.applePay?.enabled === true
            || VendorPayments.isAppConfiguredForGooglePay(config)
            || VendorPayments.isAppConfiguredForPayInStore(config);
    }
}
