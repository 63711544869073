export class AdyenPaymentProviderMapper {
    public static mapAdyenPaymentGetSettingsForLocation(settingsForLocation: APIv3.PaymentsGetAdyenSettings.Responses.$200): OLO.DTO.AdyenSettingsResponse {
        return settingsForLocation ? {
            CountryCode: settingsForLocation.CountryCode,
            Currency: settingsForLocation.Currency,
            ClientKey: settingsForLocation.ClientKey,
            Environment: settingsForLocation.Environment,
        } : null;
    }
}
