import { createSelector } from '@ngrx/store';

import { getCart } from '@shared/state/cart/selectors';
import { getCollectionType } from '@shared/state/collectionType/selectors';

/** Provide orderTypeId based on **CART** or **COLLECTION TYPE** (backup) state */
export const getSelectedOrderTypeId = createSelector(
    getCart,
    getCollectionType,
    (cart, collectionType) => cart.orderTypeId
    || collectionType.orderTypeId
);
