import { ActionReducer, Action } from '@ngrx/store';

export function resetMetaReducer(reducer: ActionReducer<OLO.State>): ActionReducer<OLO.State> {
    return function (state: OLO.State, action: Action): OLO.State {
        switch (action.type) {
            case 'RESET':
                return reducer(undefined, action);

            default: {
                return reducer(state, action);
            }
        }
    };
}
