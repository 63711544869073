import { createSelector } from '@ngrx/store';

import { getMemberState, isMemberLoading } from '@shared/state/members/selectors';
import { getCardsState } from '@shared/state/creditCards/selectors';

export const isPaymentDisabledForMemberWithCreditCard = createSelector(
    getMemberState,
    isMemberLoading,
    getCardsState,
    (membersState, memberLoading, cardsState) =>
        memberLoading ||
        (membersState.isGuestModeEnabled && !membersState.guestData) ||
        (!cardsState.activeCardToken && !cardsState.activeCardId) ||
        (!membersState.isGuestModeEnabled && !membersState.data),
);
