import { createSelector } from '@ngrx/store';
import { getOnlineMenu } from '@shared/state/onlineMenu/selectors';
import { getOnlineMenuPageImage } from '@shared/state/onlineMenuPagesImages/selectors';

export const getOnlineMenuPageDetails = (pageId: number, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb) =>
    createSelector(getOnlineMenu, getOnlineMenuPageImage(pageId, imageType), (onlineMenu, image) => {
        if (!onlineMenu || !onlineMenu?.data) return null;

        const foundPage = onlineMenu?.data.Pages.find((obj) => obj.Id === pageId);
        if (!foundPage) return null;

        return {
            title: foundPage.Name,
            description: foundPage.Description,
            image: image,
        } as Nullable<OLO.Components.OnlineMenuPageDetailsWithStateImage>;
    });
