import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

import { getLocationDetails } from '@shared/state/locations/selectors';
import { getMinimumPickupTimeForLocation } from '../../pickups';
import { getLocationFilters } from '@shared/state/locationsFilters/selectors';
import { getAppLocationMode } from '@shared/state/appSettings/selectors';
import { getSelectedOrderTypeId } from '../../orderTypes';

export const canOrderFromLocation = (locationNo: number) =>
    createSelector(
        getLocationDetails(locationNo),
        getMinimumPickupTimeForLocation(locationNo),
        getLocationFilters,
        getAppLocationMode,
        getSelectedOrderTypeId,
        (location, minPickupTime, filters, locationMode, orderTypeId) => {
            const config = new Statics.ConfigStatic().current;
            const hasLocationRelationToSelectedOrderTypeId = location.OrderTypes.some(orderType =>
                new Utils.CollectionTypeGroupDetector(orderTypeId, config).matchOrderTypeToCollectionType(orderType.Id)
            );
            if (!hasLocationRelationToSelectedOrderTypeId) return false;

            const isAvailableForOrderType = new Utils.LocationOpenStatusMessage(Utils.LocationOpenStatus, location, orderTypeId, new Date())
                .setMode(OLO.Enums.LOCATION_AVAILABILITY.BY_ORDERING_TIME_INFO)
                .getOpenStatusObj();

            /**
             * Deleted 'availablePickups' checks due to optimizations done 2 months ago for bakers and available pickups times calculations
             * Selector: getLocationFuturePickupList(config, locationNo)
             * First part of the condition was: !availablePickups || availablePickups.length === 0 ||
             */
            if (!location || location.OnlineOrderingStatus !== 0) return false;

            const locationOrderTypeChecker = new Utils.LocationCollectionTypesChecker(location, config);

            if (!locationOrderTypeChecker.hasAnyTypes()) return false;

            const orderTypeChecker = new Utils.CollectionTypeGroupDetector(orderTypeId, config);
            const isLocationDineInOnly = locationOrderTypeChecker.hasOnlyDineInType();

            const canOrderFromClosedLocation =
                config.onlineOrders.scheduledOrders === true && location.FutureOrderingMaxDaysAhead !== null && location.FutureOrderingMinDaysAhead !== null;

            const isClosed =
                !isAvailableForOrderType ||
                (isAvailableForOrderType.isOpen === false && (isLocationDineInOnly || !canOrderFromClosedLocation)) ||
                (orderTypeChecker.isDineIn() && isAvailableForOrderType.isOpen === false);

            if (isClosed) return false;

            const { FutureOrderingMinDaysAhead, FutureOrderingMaxDaysAhead } = Utils.LocationFutureOrdering.getLocationFutureOrderingDetails({
                location,
                orderTypeId
            });
            const locationIsConfiguredForFutureOrdering = FutureOrderingMaxDaysAhead !== null && FutureOrderingMinDaysAhead !== null && FutureOrderingMaxDaysAhead !== 0;

            const collectionTypeConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getDefaultCollectionType(orderTypeId);

            if (!collectionTypeConfig) {
                return false;
            }

            const currentOrderType = new Utils.CollectionTypeGroupDetector(orderTypeId, config);
            const isDineOrderType =
            (isLocationDineInOnly && collectionTypeConfig.collectionTypeId === OLO.Enums.COLLECTION_TYPE.DINE_IN) ||
            (locationOrderTypeChecker.hasDineIn() && currentOrderType.isDineIn());

            const pickupParams = {
                orderTimeoutBufferMins: 'orderTimeoutBufferMins' in collectionTypeConfig ? collectionTypeConfig?.orderTimeoutBufferMins : isDineOrderType ? 0 : null,
                startBufferMins: 'startBufferMins' in collectionTypeConfig ? collectionTypeConfig?.startBufferMins : isDineOrderType ? 0 : null,
            };

            const orderingTimeInfo = new Utils.LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();
            if (!locationIsConfiguredForFutureOrdering) {
                return Utils.OnlineOrders.canOrder(
                    location,
                    false,
                    minPickupTime.MinimumPickupTime,
                    orderingTimeInfo,
                    pickupParams.orderTimeoutBufferMins,
                    pickupParams.startBufferMins,
                );
            }
            if (locationMode === OLO.Enums.APP_LOCATION_MODE.LOCATION) return true;

            if (config.onlineOrders.scheduledOrders === true) {
                const isToday = Utils.Dates.isToday(filters.pickupTime?.DateLocalISO || new Date());

                if (!isToday) {
                    return Utils.Pickups.isDateInFutureOrdersTimeRange(filters.pickupTime?.DateLocalISO || new Date(), FutureOrderingMinDaysAhead, FutureOrderingMaxDaysAhead);
                }
            }

            return Utils.OnlineOrders.canOrder(
                location,
                config.onlineOrders.scheduledOrders === true,
                minPickupTime.MinimumPickupTime,
                orderingTimeInfo,
                pickupParams.orderTimeoutBufferMins,
                pickupParams.startBufferMins,
            );
        },
    );
