import { Inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom } from 'rxjs/operators';


@Injectable()
export class GoogleEffects {
    @Effect() public onPlaceSearch$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.GooglePlaceSearchRequest
            ),
            withLatestFrom(this._store.pipe(
                select(selectors.getOrderTypeId),
                map(orderTypeId => Utils.DynamicPages.getCurrentPageSetup(this._config.landingPage, orderTypeId)?.locationMode.searchMode)
            )),
            switchMap(([{ search, location }, searchMode]) =>
                this._googlePlacesService.placesSearch(search, location, searchMode)
                    .pipe(
                        map(payload => actions.GooglePlaceSearchSuccessRequest({ search, payload })),
                        catchError(ex => {
                            console.error('Unable to search places', ex);

                            return of(actions.GooglePlaceSearchErrorRequest({ search, ex }));
                        })
                    )
            )
        );

    @Effect() public onPlaceDetails$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.GooglePlaceDetailsRequest
            ),
            switchMap(({ placeId }) =>
                this._googlePlacesService.placeDetails({ placeId })
                    .pipe(
                        map(payload => actions.GooglePlaceDetailsSuccessRequest({ placeId, payload })),
                        catchError(ex => {
                            console.error('Unable to search places', ex);

                            return of(actions.GooglePlaceDetailsErrorRequest({ placeId, ex }));
                        })
                    )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _store: Store<OLO.State>,
        private _actions$: Actions,
        private _googlePlacesService: Services.GooglePlacesService,
    ) { }
}
