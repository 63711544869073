import { createSelector } from '@ngrx/store';

import { getMemberState } from '@shared/state/members/selectors';
import { getOrderSummary } from '@shared/state/onlineOrder/selectors';

export const memberHasAvailableBalanceToPayForCartOrder = createSelector(getMemberState, getOrderSummary, (member, summary) => {
    if (!summary || !member.data || member.accountBalance.isDownloading === true || !member.accountBalance.data) return null;

    return summary.Total <= member.accountBalance.data?.AvailableBalance;
});
