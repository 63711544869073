import { createSelector } from '@ngrx/store';

import { getReorder } from '../getReorder';

export const reorderSelectedItemsTotalQuantity = (orderId: number, locationNo: number, pickupTime: OLO.Ordering.PickupTime) =>
    createSelector(getReorder(orderId, locationNo, pickupTime), (foundReorder) => {
        if (!foundReorder || !foundReorder.data || !foundReorder.data.cart) return 0;

        let menuFlowsTotal: number = 0;
        for (let i = 0, j = foundReorder.data.cart.itemsMenuFlow.length; i < j; i++) {
            let menuFlow = foundReorder.data.cart.itemsMenuFlow[i];
            if (menuFlow._IsSelected && !menuFlow._IsDisabled) {
                menuFlowsTotal += menuFlow.Quantity || 0;
            }
        }

        let simpleItemsTotal: number = 0;
        for (let i = 0, j = foundReorder.data.cart.itemsSimple.length; i < j; i++) {
            let simpleItem = foundReorder.data.cart.itemsSimple[i];
            if (simpleItem._IsSelected && !simpleItem._IsDisabled) {
                simpleItemsTotal += simpleItem.Quantity;
            }
        }

        return menuFlowsTotal + simpleItemsTotal;
    });
