export class OnlineMenuMapper {
    public static mapGetMenuPages(menuPages: APIv3.OnlineMenuResponseModel): OLO.DTO.OnlineMenuResponseModel {
        if (!menuPages) return null;

        const mapedMenuPages: OLO.DTO.OnlineMenuResponseModel = {
            StartTime: menuPages.StartTime,
            EndTime: menuPages.EndTime,
            DayOfWeek: menuPages.DayOfWeek,
            Id: menuPages.Id,
            Name: menuPages.Name,
            Pages: menuPages.Pages ?
                menuPages.Pages.map((page: APIv3.OnlineMenuPageResponseModel) => ({
                    Id: page.Id,
                    Name: page.Name,
                    Description: page.Description,
                    Products: page.Products ?
                        page.Products.map((product: APIv3.OnlineMenuProductResponseModel) => ({
                            Id: product.Id,
                            Plu: product.Plu,
                            MenuFlowId: product.MenuFlowId,
                            Price: product.Price,
                            MinimumPrice: product.MinimumPrice,
                            DisplayMinimumPrice: product.DisplayMinimumPrice,
                            DietaryTags: product.DietaryTags ?
                                product.DietaryTags.map((tag: APIv3.TagModel) => ({
                                    Id: tag.Id,
                                    Name: tag.Name,
                                })) : null,
                            DisplayName: product.DisplayName,
                            MinQty: product.MinQty,
                            MaxQty: product.MaxQty,
                            DisplayIndex: product.DisplayIndex,
                            ProductId: product.ProductId,
                            State: product.State,
                            PosDisplay: product.PosDisplay,
                            PosDescription: product.PosDescription,
                            Kilojoules: product.Kilojoules,
                            StockAmount: product.StockAmount,
                            Tags: product.Tags ?
                                product.Tags.map((tag: APIv3.TagModel) => ({
                                    Id: tag.Id,
                                    Name: tag.Name,
                                })) : null,
                            ProductCategoryId: product.ProductCategoryId,
                            ProductFamilyIds: product.ProductFamilyIds ? product.ProductFamilyIds : []
                        })) : null,
                    DisplayIndex: page.DisplayIndex,
                    BackgroundColour: page.BackgroundColour,
                })) : null
        };

        mapedMenuPages.Pages.forEach(Page => {
            Page.Products.sort((a, b) => {
                switch (true) {
                    case a.DisplayIndex > b.DisplayIndex:
                        return 1;
                    case a.DisplayIndex < b.DisplayIndex:
                        return -1;
                    default:
                        return 0;
                }
            });
        });

        return mapedMenuPages;
    }
}
