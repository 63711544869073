import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Tokens from '@shared/core/tokens';

import { Observable, of, never } from 'rxjs';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';


@Injectable()
export class LocationFiltersEffects {
    @Effect() public resetFiltersOnCollectionTypeChangeOrOnCartActiveOrderContinue$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.SetCollectionType,
                actions.CartActiveOrderContinue
            ),
            map(() => actions.LocationsFiltersReset())
        );

    @Effect() public onRestoreCurrentLocationDetailsRestoreFilters$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationPickupTimeRestore
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getAppLocationMode)
                    )
            ),
            switchMap(([action, locationMode]) => {
                const arr: Array<Action> = [

                ];

                if (locationMode === OLO.Enums.APP_LOCATION_MODE.VENUE) {
                    arr.push(actions.LocationsFiltersSetPickupTime(action.pickupTime || null));
                }

                return arr.length ? arr : never();
            })
        );

    @Effect() public updateModeOnPickupTimeSet$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LocationsFiltersSetPickupTime,
                actions.LocationsFiltersSyncPickupTime,
            ),
            switchMap(({ pickupTime }) => {
                if (pickupTime && !pickupTime.IsAsap) {
                    return of(
                        actions.LocationsFiltersSetPickupMode({ Id: 2, Name: this.config.onlineOrders.scheduledOrders === true ? 'Schedule' : 'Later today' })
                    );
                } else {
                    return of(
                        actions.LocationsFiltersSetPickupMode({ Id: 1, Name: 'ASAP' })
                    );
                }
            })
        );

    @Effect() public onCartSelectedNewActiveOrder$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationPickupTimeSet,
                actions.CartSetPickupTime,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCart),
                    ),
                this._store
                    .pipe(
                        select(selectors.getAppLocationMode)
                    )
            ),
            switchMap(([action, cart, locationMode]) => {
                if (locationMode === OLO.Enums.APP_LOCATION_MODE.LOCATION) return never();

                return [
                    actions.LocationsFiltersSetPickupTime(action.pickupTime),
                    actions.CurrentLocationFiltersPickupTimeSync(action.pickupTime),
                ];
            })
        );

    @Effect() public onLocationsFiltersReset: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.LocationsFiltersReset),
            withLatestFrom(this._store.pipe(select(selectors.getGeolocationCoords))),
            switchMap(([, geocords]) => {
                if (!geocords || !geocords?.latitude || !geocords.longitude) return [];
                const geocoder = {
                    name: null,
                    lat: geocords.latitude,
                    lng: geocords.longitude,
                };

                return [actions.InitGeocoder(geocoder)];
            }),
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        private _store: Store<OLO.State>,
        private _actions$: Actions,
    ) { }
}
