import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';



import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, take } from 'rxjs/operators';


@Injectable()
export class LoyaltyMessagesEffects {
    @Effect() public requestMessagesOnSettingsDownloaded$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.AppSettingsSuccessRequest),
            switchMap(() => {
                this._store.pipe(select(selectors.getLoyaltyAppSettings), take(1)).subscribe((settings) => {
                    this._googleAPIService.appendMapsScript(settings?.data?.AppSettings?.GoogleMapsApiKey);
                });

                return of(actions.LoyaltyMessagesRequest());
            })
        );

    @Effect() public requestMessages$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.LoyaltyMessagesRequest),
            switchMap(() => this._loyaltyAppService.apiGetMessages()
                .pipe(
                    map(payload => actions.LoyaltyMessagesSuccessRequest({ payload })),
                    catchError(ex => {
                        console.error('Request Messages Error', ex);

                        return of(actions.LoyaltyMessagesErrorRequest({ ex }));
                    })
                )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _loyaltyAppService: Services.LoyaltyAppService,
        private _googleAPIService: Services.GoogleApiService,
    ) { }
}
