import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import { Observable, from, forkJoin, timer, of } from 'rxjs';
import { take, switchMap, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GooglePlacesService {
    private _googlePlacesAutocomplete!: google.maps.places.AutocompleteService;
    private _googlePlaces!: google.maps.places.PlacesService;
    private _map!: google.maps.Map;
    private _sessionToken!: google.maps.places.AutocompleteSessionToken;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) protected _config: OLO.Config,
        protected _httpClient: HttpClient,
    ) { }

    private _initGoogleServices(): void {
        if (!this._googlePlacesAutocomplete && google) {
            this._map = new google.maps.Map(document.createElement('div'));
            this._googlePlacesAutocomplete = new google.maps.places.AutocompleteService();
            this._googlePlaces = new google.maps.places.PlacesService(this._map);
            this._sessionToken = new google.maps.places.AutocompleteSessionToken();
        }
    }

    public placeDetails(params: google.maps.places.PlaceDetailsRequest): Observable<APICommon.GooglePlaceResult> {
        const requestData = {
            ...params,
            sessionToken: this._sessionToken,
            fields: [
                'address_component',
                'adr_address',
                'business_status',
                'formatted_address',
                'geometry',
                'name',
                'place_id',
                'plus_code',
                'type',
                'utc_offset_minutes',
                'vicinity'
            ]
        };
        this._initGoogleServices();

        return from(
            new Promise<APICommon.GooglePlaceResult>((resolve, reject) => {
                this._googlePlaces.getDetails(requestData, (place, status) => {
                    if (status !== google.maps.places.PlacesServiceStatus.OK) return reject(status);

                    resolve({
                        ...place,
                        _parent_id: requestData.placeId,
                    });
                });
            })).pipe(
            take(1)
        );
    }

    /**
     * @param {string} input search text query
     * @param {Nullable<APICommon.GoogleLocation>} location the point around which to retrieve place information
     * @param {Nullable<OLO.Enums.SEARCH_MODE>} searchMode location address - search location in area, delivery address - search address for delivery
     * @returns {Observable<APICommon.GooglePlaceDetails[]>} collection of nearby location results of point around
     */
    public placesSearch(
        input: string,
        location: Nullable<APICommon.GoogleLocation> = null,
        searchMode: OLO.Enums.SEARCH_MODE = OLO.Enums.SEARCH_MODE.LOCATION_ADDRESS
    ): Observable<Array<google.maps.places.AutocompletePrediction>> {
        this._initGoogleServices();

        /**
         * Worth reading https://developers.google.com/places/web-service/autocomplete#place_types
         */
        let types = [];

        if (searchMode === OLO.Enums.SEARCH_MODE.DELIVERY_ADDRESS) {
            types = ['address'];
        } else {
            types = ['(regions)'];
        }

        return from(
            new Promise<Array<google.maps.places.AutocompletePrediction>>((resolve, reject) => {
                this._googlePlacesAutocomplete.getPlacePredictions({
                    input,
                    types,
                    sessionToken: this._sessionToken,
                    componentRestrictions: this._componentRestrictions,
                    ...(location && { location, radius: this._config.google.maps.searchDistance })
                }, (payload, status) => {
                    if (status !== google.maps.places.PlacesServiceStatus.OK) {
                        return reject(status);
                    }
                    resolve(payload);
                });
            })).pipe(
            take(1)
        );
    }

    /**
     * @param {string} input search text query
     * @param {Nullable<APICommon.GoogleLocation>} location the point around which to retrieve place information
     * @param {Nullable<OLO.Enums.SEARCH_MODE>} searchMode location address - search location in area, delivery address - search address for delivery
     * @returns {Observable<APICommon.GooglePlaceDetails[]>} collection of nearby location results of point around
     */
    public placesSearchWithDetails(
        input: string,
        location: Nullable<APICommon.GoogleLocation> = null,
        searchMode: OLO.Enums.SEARCH_MODE = OLO.Enums.SEARCH_MODE.LOCATION_ADDRESS
    ): Observable<APICommon.GooglePlaceDetails[]> {
        /* https://developers.google.com/maps/documentation/javascript/places */
        return this.placesSearch(input, location, searchMode)
            .pipe(
                switchMap(payload => forkJoin(payload.map((obj, index) => timer((index + 30))
                    .pipe(
                        switchMap(() => this.placeDetails({
                            placeId: obj.place_id
                        }))
                    )

                ))
                    .pipe(
                        map(detailsArr => payload.map(obj => {
                            const placeDetails = detailsArr?.find(d => d._parent_id === obj.place_id);

                            return ({
                                Id: obj.place_id,
                                Name: obj.description,
                                ...obj,
                                details: placeDetails,
                                geometry: placeDetails?.geometry?.location?.toJSON() || null
                            } as APICommon.GooglePlaceDetails);
                        }))
                    ))
            );
    }

    private get _componentRestrictions(): APICommon.GoogleComponentRestrictions {
        return this._config.google?.maps?.componentRestrictions || (this._config.localization?.country ? { country: this._config.localization?.country } : null);
    }
}
