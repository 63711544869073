import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';
import * as Utils from '@shared/core/utils';

import { isOnline } from '../isOnline';

export const isOnlineMapped = () =>
    createSelector(isOnline, (online) => {
        const text = new Statics.TextsStatic().current;

        return online ? null : Utils.Messages.mapErrors({ offline: text.errors.youAreOffline })[0];
    });
