import * as io from 'io-ts';
import { validString } from '@shared/core/types/common';

const memberVerificationBaseTypeDefinition: io.MixedObject<OLO.Config.MemberVerification.BaseType> = {
    header: validString,
    body: validString,
};

const memberVerificationLandingDefinition: io.MixedObject<OLO.Config.MemberVerification.Landing> = {
    ...memberVerificationBaseTypeDefinition,
    button: validString,
    sub: validString,
};

const rootDefinition: io.MixedObject<OLO.Config.MemberVerification> = {
    landing: io.type(memberVerificationLandingDefinition),
    createProfileForm: io.type(memberVerificationBaseTypeDefinition),
    success: io.type(memberVerificationBaseTypeDefinition),
    failure: io.type(memberVerificationBaseTypeDefinition),
    failureVerify: io.type(memberVerificationBaseTypeDefinition),
};

export const memberVerificationSchema = io.type(rootDefinition);
