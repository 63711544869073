import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getLocationsState } from '@shared/state/locations/selectors';
import { getSelectedOrderTypeId } from '../../orderTypes/getSelectedOrderType';

export const getMinimumPickupTimeForLocationAndDate = (locationNo: number, date: string | Date | null = null) =>
    createSelector(getLocationsState, getSelectedOrderTypeId, (locations, orderTypeId) => {
        const location = locations.data?.find((obj) => obj.LocationNo === locationNo);
        if (!location) return null;

        const foundObj = Utils.Pickups.getFilteredOrderingTimeInfo(location, orderTypeId);
        if (!foundObj) return null;

        let d: Date = date instanceof Date ? date : Utils.Dates.createDate(date);
        const findDay = foundObj.find(Utils.Pickups.datesMatchByDayCallback(Utils.Dates.getLocalISOFormatDate(d)));

        if (findDay === undefined) {
            return null;
        }

        const foundPickup = findDay.PickupTimes.find((obj) =>
            Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(Utils.Dates.roundDateToMinutes(d, 'ceil')), obj.From, obj.To),
        );

        if (foundPickup) return foundPickup;

        /* Default case when pickup time is not configured but location is open - take opening times and 0 minimumPickupTime */
        return {
            From: findDay.OpeningTime,
            To: findDay.ClosingTime,
            MinimumPickupTime: 0,
        };
    });
