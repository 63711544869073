import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { membersState } from '../root';

export const getObfuscatedMemberPhoneNo = createSelector(
    membersState,
    members => {
        let mobilePhone: string = null;
        switch (true) {
            case members.authorizationStep === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE && members.update?.data?.MobilePhone != null:
                mobilePhone = members.update.data.MobilePhone;
                break;

            case members.addData?.MobilePhone != null:
                mobilePhone = members.addData.MobilePhone;
                break;

            case members.data?.MobilePhone != null:
                mobilePhone = members.data.MobilePhone;
                break;

            default:
                return null;
        }


        return Utils.Strings.obfuscateMobilePhone(mobilePhone);
    }
);
