import { createSelector } from '@ngrx/store';

import { getPaymentState } from '../getPaymentState';

export const isPaymentPossible = createSelector(
    getPaymentState,
    (paymentState) => paymentState.hasSucceeded === false &&
    paymentState.data === null &&
    paymentState.paymentStepStatus !== 'complete' &&
    paymentState.paymentStepStatus !== 'payment_status_check'
);
