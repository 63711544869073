import { createAction, props } from '@ngrx/store';

/* General */
export const CartPickupTimeUpdate = createAction(
    '[Cart] Update pickup time',
    props<{ modalId: number; locationNo: number; pickupTime: OLO.Ordering.PickupTime; }>(),
);

export const CartSetup = createAction(
    '[Cart] Setup and validate active orders crap AOLO-289',
    props<{ modalId: number; locationNo: number; item: OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem; }>(),
);

export const CartSetupWithMultipleItems = createAction(
    '[Cart] Setup and validate active orders from reorder modal',
    (
        modalId: number,
        locationNo: number,
        menuFlows: OLO.State.Cart.CartMenuFlowExtended[] = [],
        simpleItems: OLO.State.Cart.CartSimpleItemExtended[] = []
    ) => ({ modalId, locationNo, menuFlows, simpleItems })
);

export const CartTransferItemRequest = createAction(
    '[Cart] Transfer item request',
    props<{ modalId: number; locationNo: number; item: OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem; }>(),
);

export const CartActiveOrderContinue = createAction(
    '[Cart] Active order - continue',
    props<{ modalId: number; }>(),
);

export const CartActiveOrderStartNew = createAction(
    '[Cart] Active order - start new order',
    props<{ modalId: number; locationNo: number; }>(),
);

export const CartSetupOrderTypeId = createAction(
    '[Cart] Setup and validate next collection type Id - this is a helper and does not change state - to delete in the near future',
    props<{ orderTypeId: number; previousOrderTypeId?: Nullable<number>; }>(),
);

export const CartSetOrderTypeId = createAction(
    '[Cart] Active order - set cart collection type id',
    props<{ orderTypeId: number; }>(),
);

export const CartLoad = createAction(
    '[Cart] Load cart contents from browser storage',
    props<{ cart: OLO.State.Cart; }>(),
);

// export const CartSetPendingClear = createAction('[Cart] Set pending clear'); /* DO WE NEED THAT? */

export const CartSetLocationNo = createAction(
    '[Cart] Set locationNo',
    (locationNo: number = null) => ({ locationNo }),
);

export const CartSetOnlineMenu = createAction(
    '[Cart] Set online menu time',
    props<{ onlineMenu: OLO.DTO.OnlineMenuResponseModel; }>(),
);

export const CartSetPickupTime = createAction(
    '[Cart] Set pickup time',
    (pickupTime: OLO.Ordering.PickupTime = null) => ({ pickupTime })
);

export const CartReset = createAction('[Cart] Restore entire cart to initial state');

export const CartRemoveAllItems = createAction('[Cart] Remove all products and menuflows');

export const CartEditItem = createAction(
    '[Cart] Edit item',
    props<{ item: OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem; }>(),
);

/* Products */
export const CartSimpleItemAdd = createAction(
    '[Cart] Add simple item',
    props<{ locationNo: number; item: OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended; }>(),
);

export const CartSimpleItemUpdate = createAction(
    '[Cart] Update simple item props/values',
    props<{ item: OLO.State.Cart.CartSimpleItem; }>(),
);

export const CartSimpleItemDecrement = createAction(
    '[Cart] Decrement simple item',
    props<{ changeValue: number; item: OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended; }>()
);

export const CartSimpleItemIncrement = createAction(
    '[Cart] Increment simple item',
    props<{ changeValue: number; item?: OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended; }>(),
);

export const CartSimpleItemRemove = createAction(
    '[Cart] Remove simple item',
    (item: OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended) => ({ item })
);


/* Menu flows */
export const CartMenuFlowAdd = createAction(
    '[Cart] Add menu flow item',
    props<{ locationNo: number; item: OLO.State.Cart.CartMenuFlowExtended; }>(),
);

export const CartMenuFlowAddWithMerge = createAction(
    '[Cart] Add menu flow item but check if there is any identical in cart' /* AOLO-282 */,
    props<{ locationNo: number; item: OLO.State.Cart.CartMenuFlowExtended; }>(),
);

export const CartMenuFlowUpdate = createAction(
    '[Cart] Update menu flow props/values',
    (item: OLO.State.Cart.CartMenuFlowExtended) => ({ item })
);

export const CartMenuFlowDecrement = createAction(
    '[Cart] Decrement menu flow item',
    props<{ changeValue: number; item: OLO.State.Cart.CartMenuFlowExtended; }>(),
);

export const CartMenuFlowIncrement = createAction(
    '[Cart] Increment menu flow item',
    props<{ changeValue: number; context: OLO.Enums.CART_ACTION_CONTEXT;
        item?: OLO.State.Cart.CartMenuFlowExtended; }>(),
);

export const CartMenuFlowRemove = createAction(
    '[Cart] Remove menu flow item',
    (item: OLO.State.Cart.CartMenuFlowExtended) => ({ item })
);


export const CartAddVoucher = createAction('[Cart] Add voucher');

export const CartAddActivatedVoucher = createAction(
    '[Cart] Add activated vouchers',
    props<{ payload: OLO.DTO.OnlineOrderDetailedBusinessModel; }>(),
);

export const CartRemoveActivatedVoucher = createAction(
    '[Cart] Remove activated vouchers'
);

export const CartSetDeliveryAddress = createAction(
    '[Cart] Set delivery address',
    props<{ address: Nullable<APICommon.GooglePlaceDetails>; }>()
);
