import * as io from 'io-ts';
import { combinedTypes } from './combined.type';
import { arraySize } from './array-size.type';

export const minimumArraySize = <T extends io.ArrayC<any>>(combinedType: T, minimumSize: number = 1) => combinedTypes({
    types: [
        combinedType,
        arraySize(minimumSize)
    ]
});
