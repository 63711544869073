import { createSelector } from '@ngrx/store';

import { getLocationFiltersSearchInput } from '../getLocationFiltersSearchInput';
import { getLocationFiltersGeocoder } from '../getLocationFiltersGeocoder';

export const isSearchingByNearestPlace = createSelector(
    getLocationFiltersSearchInput,
    getLocationFiltersGeocoder,
    (term, geocoder) => !!(term && geocoder)
);
