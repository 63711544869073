import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';

import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AppSettingsEffects {

    @Effect() public requestApiSettings$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.AppSettingsRequest
            ),
            switchMap(_action => this._loyaltyAppService.apiGetLoyaltyAppInfo()
                .pipe(
                    map((payload: OLO.DTO.LoyaltyAppModel) => {
                        payload.CountryAssignments = payload.CountryAssignments.sort(
                            (
                                a: OLO.DTO.LoyaltyAppCountryAssignmentModel,
                                b: OLO.DTO.LoyaltyAppCountryAssignmentModel) => (a.Name > b.Name) ? 1 : (a.Name < b.Name) ? -1 : 0);

                        return actions.AppSettingsSuccessRequest({ payload });
                    }),
                    catchError(ex => {
                        console.error('Loyalty app settings request error:', ex);

                        return of(actions.AppSettingsErrorRequest({ ex }));
                    })
                )),
        );

    constructor(
        private _actions$: Actions,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) { }
}
