import * as io from 'io-ts';
import { orderTypeIdsArray } from '@shared/core/types/consts';
import { boolean, validString, nullableNumber, arrayOfNumbers, arrayOfStrings, numberInRange, minimumArraySize } from '@shared/core/types/common';

const itemModalDefinition: io.MixedObject<OLO.Config.OnlineOrders.DynamicModal.ItemModal> = {
    preTitle: validString,
    title: arrayOfStrings,
    description: arrayOfStrings,
};

const scheduleOrderModalsDefinition: io.MixedObject<OLO.Config.OnlineOrders.ScheduledOrderModal> = {
    orderTypeIds: orderTypeIdsArray,
    showCollectionTypeDropdown: boolean,
    modal: io.type(itemModalDefinition),
};

const actionDefinition: io.MixedObject<OLO.Config.OnlineOrders.DynamicModal.Actions> = {
    header: arrayOfStrings,
    body: arrayOfStrings,
};

const itemActionModalDefinition: io.MixedObject<OLO.Config.OnlineOrders.DynamicModal.ItemActionModal> = {
    preTitle: validString,
    buttonYesText: validString,
    buttonNoText: validString,
    title: arrayOfStrings,
    description: arrayOfStrings,
    actionSuccess: io.type(actionDefinition),
    actionFailure: io.type(actionDefinition),
};

const itemUpdateModalDefinition: io.MixedObject<OLO.Config.OnlineOrders.DynamicModal.ItemUpdateModal> = {
    preTitle: validString,
    buttonText: validString,
    title: arrayOfStrings,
    description: arrayOfStrings,
};

const updateOrderModalsDefinition: io.MixedObject<OLO.Config.OnlineOrders.UpdateOrderModal> = {
    orderTypeIds: orderTypeIdsArray,
    statusFilter: arrayOfNumbers,
    pickupDateFilterInHours: numberInRange(0),
    modal: io.type(itemUpdateModalDefinition),
};

const cancelOrderModalsDefinition: io.MixedObject<OLO.Config.OnlineOrders.CancelOrderModal> = {
    orderTypeIds: orderTypeIdsArray,
    statusFilter: arrayOfNumbers,
    pickupDateFilterInHours: numberInRange(0),
    modal: io.type(itemActionModalDefinition),
};

const itemActiveOrderDetailsModalDefinition: io.MixedObject<OLO.Config.OnlineOrders.DynamicModal.ItemActiveOrderDetailsModal> = {
    preTitle: validString,
    title: arrayOfStrings,
    detailsSection: io.type(actionDefinition),
};

const activeOrderModalsDefinition: io.MixedObject<OLO.Config.OnlineOrders.ActiveOrderModal> = {
    orderTypeIds: orderTypeIdsArray,
    modal: io.type(itemActiveOrderDetailsModalDefinition),
};

const rootDefinition: io.MixedObject<OLO.Config.OnlineOrders> = {
    saleName: validString,
    scheduledOrders: boolean,
    sendAutoConfirmationEmail: boolean,
    sendAutoReceiptEmail: boolean,
    allowModifiers: boolean,
    allowClosedLocationOrders: boolean,
    discounts: nullableNumber,
    scheduleOrderModals: minimumArraySize(io.array(io.type(scheduleOrderModalsDefinition))),
    updateOrderModals: io.array(io.type(updateOrderModalsDefinition)),
    cancelOrderModals: io.array(io.type(cancelOrderModalsDefinition)),
    activeOrderModals: minimumArraySize(io.array(io.type(activeOrderModalsDefinition))),
};

export const onlineOrdersSchema = io.type(rootDefinition);
