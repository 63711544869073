import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getLocationDetails } from '@shared/state/locations/selectors';
import { getSelectedOrderTypeId } from '../../orderTypes/getSelectedOrderType';

export const getOrderingTimeInfoByLocationNo = (locationNo: number, filterAvailable: boolean = true) =>
    createSelector(getLocationDetails(locationNo), getSelectedOrderTypeId, (location, orderTypeId) => {
        const orderingTimeInfo = new Utils.LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();

        if (!filterAvailable) {
            return (
                orderingTimeInfo?.map((obj) => ({
                    ...obj,
                    IsOpen: new Utils.LocationOpenStatus(obj).isOpen(),
                })) || null
            );
        }

        return location ? Utils.Pickups.getFilteredOrderingTimeInfo(location, orderTypeId) : null;
    });
