import { createSelector } from '@ngrx/store';
import { creditCardsState } from '../root';

export const getActiveCardDetails = createSelector(
    creditCardsState,
    creditCards => {
        const activeId: number = creditCards.activeCardId;
        const activeToken: string = creditCards.activeCardToken;
        const cards: OLO.Members.MemberCreditCardDetails[] = creditCards.data;

        if (!activeId && !activeToken || !cards || cards.length === 0) return null;

        if (activeId) {
            return cards.find(obj => obj.Id === activeId) || null;
        }

        return cards.find(obj => obj['Token'] === activeToken) || null; /* Check TOKEN property - something is not right here - or is it just demo? */

    }
);
