import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getSelectedOrderType } from '../getSelectedOrderType';

export const hasOrderTypePristineFields = createSelector(
    getSelectedOrderType,
    orderType => {
        const pageConfig = Utils.DynamicPages.customPageSetup('checkoutPage', orderType?.Id);

        return Utils.OnlineOrders.hasOrderTypePristineDisclaimerFields(orderType, pageConfig?.orderTypesDisclaimers);
    }
);
