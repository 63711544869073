import * as Statics from '@shared/core/statics';
import { CollectionTypeHelper } from './collection-type-helper.utils';
import { Dates } from './dates.utils';

export class LocationOrderingTimeInfo {
    constructor(private _location?: Nullable<OLO.DTO.OnlineOrderingLocationBusinessModel>, private _orderTypeId?: Nullable<number>) {}

    private get _baseOrderingTimeInfo() {
        return this._location?.OrderingTimeInfo || null;
    }

    private get _orderTypes() {
        return (this._orderTypeId && this._location?.OrderTypes) || null;
    }

    private _getOverwrittenOrderingTimeInfoByOrderTypeId(orderTypeId: number) {
        const orderTypes = this._orderTypes;
        if (!orderTypes) {
            return this._baseOrderingTimeInfo;
        }

        // Collection type could have multiple orderTypeIds, location could have configured OrderingTimeInfo only for one of orderTypeId, it requires to check if
        // orderTypeId belongs to collectionType and return orderingTimeInfo. E.g Dine in type have 3 and 4 orderTypeId. Location has configured OrderingTimeInfo for 4 orderTypeId.
        // Selected orderTypeId is 3, in that case we return OrderingTimeInfo for orderTypeId 4, because 3 belongs to dine in collection type.
        const collectionTypeByOrderTypeId = new CollectionTypeHelper(new Statics.ConfigStatic().current.collectionTypes).getCollectionTypeByOrderTypeId(orderTypeId);
        const foundById = orderTypes.find((obj) => collectionTypeByOrderTypeId?.orderTypeIds.includes(obj.Id))?.OrderingTimeInfo;
        if (!foundById) {
            return this._baseOrderingTimeInfo;
        }

        return this._baseOrderingTimeInfo.reduce((acc, next) => {
            const relatedBaseItem = foundById.find((obj) => {
                const orderTypeDate = Dates.getChunk(obj.Date, 'date');
                const baseDate = Dates.getChunk(next.Date, 'date');

                return orderTypeDate === baseDate;
            });

            acc.push({
                ...next,
                ...relatedBaseItem,
            });

            return acc;
        }, [] as APIv3_SNAPSHOT.LocationOrderingTimeInfoModel[]);
    }

    public getOrderingTimeInfo(orderTypeId: number = this._orderTypeId) {
        if (!this._location) {
            return null;
        }

        return this._getOverwrittenOrderingTimeInfoByOrderTypeId(orderTypeId);
    }
}
