import { createSelector } from '@ngrx/store';

import { reorderState } from '../root';

export const getReorder = (orderId: number, locationNo: number, pickupTime: OLO.Ordering.PickupTime) =>
    createSelector(reorderState, (reorder) => {
        if (!orderId || !locationNo || !pickupTime) return null;
        const foundReorder = reorder.find((obj) => obj.LocationNo === locationNo && obj.OrderId === orderId && obj.PickupTime !== null && obj.PickupTime.Id === pickupTime.Id);

        return foundReorder || null;
    });
