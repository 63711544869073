import { AbstractControl } from '@angular/forms';

export class ValidatorsUtils {
    public static findControl(fieldName: string, control: AbstractControl): AbstractControl {
        if (!control.parent) return null;

        return control.parent.controls[fieldName] || ValidatorsUtils.findControl(fieldName, control.parent);
    }

    public static findRoot(group: AbstractControl): AbstractControl {
        return group.root;
    }
}
