import { createAction, props } from '@ngrx/store';

export const OrderTypesImagesRequest = createAction(
    '[Order Types images] Request',
    props<{ params: APICommon.ImagesGetParams; orderTypesIds: number[]; }>(),
);

export const OrderTypesImagesSuccessRequest = createAction(
    '[Order Types images] Success request',
    props<{ params: APICommon.ImagesGetParams; payload: OLO.DTO.ImageUrlModel[]; orderTypesIds: number[]; }>(),
);

export const OrderTypesImagesErrorRequest = createAction(
    '[Order Types images] Error request',
    props<{ params: APICommon.ImagesGetParams; ex?: any; orderTypesIds: number[]; }>(),
);
