import { createSelector } from '@ngrx/store';

import { getCart } from '@shared/state/cart/selectors';
import { getLocations } from '@shared/state/locations/selectors';

export const getCartLocationFriendlyName = createSelector(getCart, getLocations, (cart, locations) => {
    if (!cart || !cart.locationNo || !locations) return null;
    const foundLocation = locations.find((location) => location.LocationNo === cart.locationNo);

    return foundLocation ? foundLocation.LocationFriendlyName : null;
});
