type LangCode = 'en' | 'es';

export class I18n {
    public static readonly DEFAULT_LANG: LangCode = 'en';
    public static readonly AVAILABLE_LANGS: LangCode[] = ['en', 'es'];

    /**
     * Provides language code based on config or from browser if config was not provided
     * @param {OLO.Types.COUNTRIES_CODE=} configLocale locale extracted from application config
     * @returns {LangCode} LangCode
     */
    public static getLanguage(configLocale?: OLO.Types.COUNTRIES_CODE): LangCode {
        const configLang = configLocale.slice(0, 2) as LangCode;
        const userLang = navigator.language.slice(0, 2) as LangCode;

        if (configLang) {
            return configLang;
        } else if (I18n.AVAILABLE_LANGS.includes(userLang as LangCode)) {
            return userLang;
        }

        return I18n.DEFAULT_LANG;
    };
}
