import { FormGroup, FormArray, FormControl } from '@angular/forms';

export class Forms {
    public static extractValues(form: FormGroup): { [key: string]: any; } {
        return Object.keys(form.controls).reduce(
            (acc, controlName) =>
                /* TODO - support nested stuff */
                ({
                    ...acc,
                    [controlName]: form.get(controlName).value,
                }),
            {},
        );
    }

    public static labelToName(str: string): string {
        if (!str || typeof str !== 'string') return null;

        return str.toLowerCase().replace(/[^a-z]/g, '');
    }

    /**
     * Searches for a specific form control field
     * @param {FormGroup | FormArray} formControl - form or part of the form
     * @param {string} search - control name
     */
    public static findControl = (formControl: FormGroup | FormArray, search: string): Nullable<FormControl> => {
        if (!formControl) return null;

        return formControl.controls[search] || Forms.findControl(formControl.parent, search);
    };
}
