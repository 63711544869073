import { createSelector } from '@ngrx/store';

import { getLocationFilters } from '@shared/state/locationsFilters/selectors';
import { getCartPickupTimeName } from '@shared/state/cart/selectors';

export const getPickupTimeLabelForCart = createSelector(getLocationFilters, getCartPickupTimeName, (filters, cartLabel) => {
    if (!cartLabel && !filters) return null;

    if (cartLabel) return cartLabel;

    if (!filters.pickupTime) return filters.pickupMode.Name;

    return filters.pickupTime.Name;
});
