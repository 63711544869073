import * as io from 'io-ts';
import { nullableString } from '@shared/core/types/common';
import { orderTypeIdsArray } from '@shared/core/types/consts';

const rootDefinition: io.MixedObject<OLO.Config.TopBar> = {
    orderTypeIds: orderTypeIdsArray,
    currentLocationOrderTypeText: io.union([nullableString, io.literal(-1)]),
};

export const topBarSchema = io.array(io.type(rootDefinition));
