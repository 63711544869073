import { createSelector } from '@ngrx/store';
import { getAppLocationMode } from '@shared/state/appSettings/selectors/getAppLocationMode/get-app-location-mode.selector';
import { getLandingPageSearchMode } from '@shared/state/commonSelectors/dynamicPage/getLandingPageSearchMode/get-landing-page-search-mode.selector';

export const getLocationsListFilterParams = (limit: number) => createSelector(
    getAppLocationMode, getLandingPageSearchMode,
    (mode, searchMode) => {
        if (mode === OLO.Enums.APP_LOCATION_MODE.VENUE) {
            return ({ sortTag: 'Popular', params: { byOpenStatus: false, byPickupTime: true, bySearch: false, byDeliveryRadius: false }, limit });
        }

        if (searchMode === OLO.Enums.SEARCH_MODE.DELIVERY_ADDRESS) {
            return ({ sortTag: 'Distance', params: { byOpenStatus: false, byPickupTime: false, bySearch: false, byDeliveryRadius: true }, limit });
        }

        return ({ sortTag: 'Distance', params: { byOpenStatus: false, byPickupTime: false, bySearch: true, byDeliveryRadius: false }, limit });
    }
);
