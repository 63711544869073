import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';
import * as Models from '@shared/core/models';

import { getEdgeOrderingTimeInfoForLocations } from '../getEdgeOrderingTimeInfroForLocations';
import { getSelectedOrderTypeId } from '../../orderTypes/getSelectedOrderType';

export const generatePickupTimesForAllLocations = (config: OLO.Config, text: T.StaticTexts, limit: number = null, isSchedule: boolean = false) =>
    createSelector(getEdgeOrderingTimeInfoForLocations(), getSelectedOrderTypeId, (edgeCases, orderTypeId) => {
        const DayOfWeek: number = new Date().getDay();
        const collectionTypeConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getCollectionTypeByOrderTypeId(orderTypeId);
        const params: OLO.Ordering.GeneratePickupsParams = {
            orderTypeId: orderTypeId,
            endBufferMins: 0,
            startBufferMins: 60,
            orderTimeoutBufferMins: 0,
            nextTick: 60,
            displayAsTimespans: collectionTypeConfig && 'displayAsTimespans' in collectionTypeConfig ? collectionTypeConfig.displayAsTimespans : false,
        };
        if (edgeCases.MinimumPickupTime === null && isSchedule === false) {
            return null;
        }

        const asapModel: OLO.Ordering.PickupTime = new Models.PickupModels().generateDefaultAsap(text);
        const scheduleModel: OLO.Ordering.PickupTime = new Models.PickupModels().generateDefaultSchedule(text);

        if (collectionTypeConfig && 'endBufferMins' in collectionTypeConfig) {
            params.endBufferMins = collectionTypeConfig.endBufferMins;
            params.startBufferMins = collectionTypeConfig.startBufferMins;
            params.orderTimeoutBufferMins = collectionTypeConfig.orderTimeoutBufferMins;
            params.nextTick = collectionTypeConfig.nextTick;
        }

        const list = Utils.Pickups.generatePickupTimesList({
            orderTypeId,
            location: null,
            asapPickupMins: edgeCases.MinimumPickupTime,
            openingHours: [
                {
                    DayOfWeek: DayOfWeek as APICommon.DayOfWeek,
                    OpeningTime: edgeCases.OpeningTime,
                    ClosingTime: edgeCases.ClosingTime,
                },
            ],
            limit,
            ...params
        }).filter((pickup) => pickup.IsAsap === false);

        list.unshift(asapModel);

        if (limit && list.length > limit) {
            list.length = limit;
        }

        if (isSchedule) {
            list.push(scheduleModel);
        }

        return list;
    });
