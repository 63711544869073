
import * as Utils from '@shared/core/utils';

export class ConvergePaymentProviderMapper {
    public static mapConvergeSettingsToAuthModel(convergeSettings: APIv3.ConvergeSettingsResponse): OLO.DTO.ConvergeSettingsResponse {
        return convergeSettings
            ? {
                ApiUrl: convergeSettings.ApiUrl,
                MechantId: convergeSettings.MechantId,
                UserId: convergeSettings.UserId,
                Pin: convergeSettings.Pin,
                VendorId: convergeSettings.VendorId,
                SessionToken: convergeSettings.SessionToken
                    ? {
                        SessionToken: convergeSettings.SessionToken.SessionToken,
                    }
                    : null,
            }
            : null;
    }

    public static mapCreditCardToParams(cardData: OLO.CreditCards.CreditCardDetails): PPConverge.CommonParams {
        return {
            ssl_card_number: cardData.cardNumber,
            ssl_exp_date: Utils.CreditCards.dateToCustomFormat(cardData.expiryDate),
            ssl_cvv2cvc2: +cardData.cvv,
        };
    }
}
