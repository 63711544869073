import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './product-images.actions';

const initialState: OLO.State.ProductImage[] = [];

export const productImagesReducerFn = createReducer(
    initialState,
    on(
        actions.ProductImagesRequest,
        (state, action) => {
            const newToConcat: OLO.State.ProductImage[] = [];

            action.productIds.forEach(productId => {
                if (state.find(image => image.ProductId === productId)) {
                    return;
                }

                newToConcat.push({
                    ProductId: productId,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    data: null,
                });
            });

            return [
                ...state.map((image: OLO.State.ProductImage) => {
                    if (action.productIds.find(productId => productId === image.ProductId)) {
                        return {
                            ...image,
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        };
                    }

                    return image;
                }),
                ...newToConcat,
            ];
        }
    ),
    on(
        actions.ProductImagesSuccessRequest,
        (state, action) => state.map((image: OLO.State.ProductImage) => {
            const foundItem: OLO.DTO.ImageUrlModel = action.payload.find(newImage => newImage.ParentId === image.ProductId);

            if (foundItem) {

                return {
                    ...image,
                    data: { ...foundItem },
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                };
            }

            return {
                ...image,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };

        })
    ),

    on(
        actions.ProductImagesErrorRequest,
        (state, action) => state.map((image: OLO.State.ProductImage) => {
            if (action.productIds.find(productId => productId === image.ProductId)) {
                return {
                    ...image,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return image;
        })
    ),
);

export function productImagesReducer(state: OLO.State.ProductImage[] | undefined, action: Action) {
    return productImagesReducerFn(state, action);
}
