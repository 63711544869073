import { createSelector } from '@ngrx/store';

import { getAvailablePickupTimesForLocation } from '@shared/state/availablePickups/selectors';
import { getOrderInfoCompleteForLocationByDate } from '../getOrderInfoCompleteForLocationByDate';

/**
 * Checks if location sub details are still loading or calculating, including order info and available pickups
 * @param {number} locationNo
 * @returns {boolean} boolean
 */
export const isLocationLoading = (locationNo: number) =>
    createSelector(
        getAvailablePickupTimesForLocation(locationNo),
        getOrderInfoCompleteForLocationByDate(locationNo),
        (availablePickups, orderInfo) => !availablePickups || availablePickups?.isCalculating || orderInfo.isDownloading,
    );
