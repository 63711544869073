import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';

import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationsMapper } from '@shared/core/mappers/locations.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient, public store: Store<OLO.State>) {}

    public getLocationsWithOnlineOrdering(customParams: APICommon.TerminalsOnlineOrderingLocationsGetParams = {}): Observable<OLO.DTO.OnlineOrderingLocationBusinessModel[]> {
        const defaultParams: APICommon.TerminalsOnlineOrderingLocationsGetParams = {
            duration: APICommon.ORDERING_TIME_INFO_DURATION.DAY,
        };

        const params: HttpParams = new HttpParams({
            fromObject: { ...defaultParams, ...customParams } as any,
        });

        return this.httpClient.get<APIv3.TerminalsGetOnlineOrderingLocations.Responses.$200>(`${this.config.api.base}/terminals/onlineOrderingLocations`, { params }).pipe(
            map((response: APIv3.TerminalsGetOnlineOrderingLocations.Responses.$200) => LocationsMapper.mapGetLocationsWithOnlineOrdering(response)),
            map((locations) => {
                const filteredLocations = locations.filter((location) => {
                    const collectionTypesInfo = new Utils.LocationCollectionTypesChecker(location, this.config);
                    if (!collectionTypesInfo.hasAnyTypes()) {
                        console.warn(`WARNING! The config.js collection types list haven't configured any order type id corresponding to order type id in
                            location no ${location.LocationNo}.`);

                        return false;
                    }

                    return true;
                });

                return filteredLocations;
            }),
        );
    }

    public requestLocations(): void {
        this.store.dispatch(
            actions.LocationsRequest({
                venueNo: this.config.venue ? this.config.venue.id : null,
                dateToCheck: Utils.Dates.getLocalISOFormatDate(new Date(), false) /* Without Z - will not return TODAY if true */,
                duration: APICommon.ORDERING_TIME_INFO_DURATION.MONTH,
            }),
        );
    }
}
