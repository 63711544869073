import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getLocationsState } from '@shared/state/locations/selectors';
import { getSelectedOrderTypeId } from '../../orderTypes/getSelectedOrderType';

type Params = {
    /** Only provide for overwritinig selected one. Otherwise leave null */
    orderTypeId: Nullable<number>;
    format: string;
    prefixes: boolean;
};

export const getAllPeriodsForAllLocations = (params: Partial<Params> = {}) => {
    const opts: Params = {
        orderTypeId: null,
        format: 'dd, D MMM',
        prefixes: true,
        ...params,
    };

    const { format, prefixes, orderTypeId: overwriteOrderTypeId } = opts;

    return createSelector(getLocationsState, getSelectedOrderTypeId, (locations, orderTypeId) => {
        if (!locations?.data) return null;

        const nextOrderTypeId = overwriteOrderTypeId || orderTypeId;

        return locations.data
            ?.reduce((acc, location) => {
                const locationIsConfiguredForFutureOrders = location.FutureOrderingMaxDaysAhead !== null && location.FutureOrderingMinDaysAhead !== null;
                let min: number = location.FutureOrderingMinDaysAhead;
                let max: number = location.FutureOrderingMaxDaysAhead;

                Utils.Pickups.getFilteredOrderingTimeInfo(location, nextOrderTypeId).forEach((timeInfo) => {
                    const foundInAcc = acc.some((existingObj) => existingObj.Date.split('T')[0] === timeInfo.Date.split('T')[0]);
                    if (!foundInAcc) {
                        const daysDiff = Utils.Dates.datesDiffInDays(new Date(), Utils.Dates.createDate(timeInfo.Date));

                        const isTodayWithoutFutureOrderingConfigured = !locationIsConfiguredForFutureOrders && daysDiff === 0;
                        const isAnyDateWithFutureOrderingConfigured = locationIsConfiguredForFutureOrders && daysDiff >= min && daysDiff <= max;

                        if (isTodayWithoutFutureOrderingConfigured || isAnyDateWithFutureOrderingConfigured) {
                            acc.push(Utils.Pickups.createPeriodObject(timeInfo, format, prefixes));
                        }
                    }
                });

                return acc;
            }, [] as OLO.Ordering.Period[])
            .sort((a, b) => a.Id - b.Id);
    });
};
