import { Validators } from './validators';

export class RelationsValidators extends Validators {
    public static readonly ERROR_TYPES = {
        scheduledOrders: 'Flag onlineOrders.scheduledOrders should be enabled for provided collectionType',
        landingPageLocationMode: 'Invalid location mode config. Venue or location config should be defined for landingPage element depending on app mode',
        locationListPageLocationMode: 'Invalid location mode config. Venue or location config should be defined for locationListPage element depending on app mode',
        orderConfirmationPageLiveViewSection: 'liveViewSection property should contain at least one element when isLiveViewEnabled flag is true'
    };

    public static validateScheduledOrdersFlag(config: OLO.Config, ...mandatoryCollectionTypeIds: OLO.Enums.COLLECTION_TYPE[]): typeof RelationsValidators._errors {
        Validators._registerErrorTypes(RelationsValidators.ERROR_TYPES);

        RelationsValidators._errors = [];

        const isScheduledOrderingEnabled = config.onlineOrders.scheduledOrders === true;
        if(isScheduledOrderingEnabled) {
            return [];
        }

        const enabledCollectionTypes = config.collectionTypes.filter(obj => obj.enabled === true && mandatoryCollectionTypeIds.includes(obj.collectionTypeId));
        if(enabledCollectionTypes.length) {
            enabledCollectionTypes.forEach(obj => {
                RelationsValidators._errors.push({
                    scheduledOrders: {
                        path: 'collectionTypes',
                        foundIn: `${obj.collectionTypeId}`,
                        value: isScheduledOrderingEnabled,
                        context: null
                    }
                });
            });
        }

        return RelationsValidators._errors;
    }

    public static validateLandingPageItemsLocationModes(config: OLO.Config): typeof RelationsValidators._errors {
        Validators._registerErrorTypes(RelationsValidators.ERROR_TYPES);

        RelationsValidators._errors = [];

        const isVenueMode = config.venue?.id != null && config.venue?.name != null;
        config.landingPage.forEach((obj, index) => {
            if(isVenueMode && obj.venueMode == null) {
                RelationsValidators._errors.push({
                    landingPageLocationMode: {
                        path: 'landingPage',
                        foundIn: `[${index}].venueMode`,
                        value: obj.venueMode,
                        context: null
                    }
                });
            }

            if(!isVenueMode && obj.locationMode == null) {
                RelationsValidators._errors.push({
                    landingPageLocationMode: {
                        path: 'landingPage',
                        foundIn: `[${index}].locationMode`,
                        value: obj.locationMode,
                        context: null
                    }
                });
            }
        });

        return RelationsValidators._errors;
    }

    public static validateLocationListPageItemsLocationModes(config: OLO.Config): typeof RelationsValidators._errors {
        Validators._registerErrorTypes(RelationsValidators.ERROR_TYPES);

        RelationsValidators._errors = [];

        const isVenueMode = config.venue?.id != null && config.venue?.name != null;
        config.locationListPage.forEach((obj, index) => {
            if(isVenueMode && obj.venueMode == null) {
                RelationsValidators._errors.push({
                    locationListPageLocationMode: {
                        path: 'locationListPage',
                        foundIn: `[${index}].venueMode`,
                        value: obj.venueMode,
                        context: null
                    }
                });
            }

            if(!isVenueMode && obj.locationMode == null) {
                RelationsValidators._errors.push({
                    locationListPageLocationMode: {
                        path: 'locationListPage',
                        foundIn: `[${index}].locationMode`,
                        value: obj.locationMode,
                        context: null
                    }
                });
            }
        });

        return RelationsValidators._errors;
    }

    public static validateOrderConfirmationPageLiveViewSection(config: OLO.Config): typeof RelationsValidators._errors {
        Validators._registerErrorTypes(RelationsValidators.ERROR_TYPES);

        RelationsValidators._errors = [];

        config.orderConfirmationPage.forEach((obj, index) => {
            if(obj.orderStatusSection.isLiveViewEnabled === true && !obj.orderStatusSection.liveViewSection.length) {
                RelationsValidators._errors.push({
                    orderConfirmationPageLiveViewSection: {
                        path: 'orderConfirmationPage',
                        foundIn: `[${index}].orderStatusSection.liveViewSection`,
                        value: obj.orderStatusSection.liveViewSection,
                        context: null
                    }
                });
            }
        });

        return RelationsValidators._errors;
    }
}
