import { enumType } from '@shared/core/types/common';

enum COLLECTION_TYPE {
    PICKUP = OLO.Enums.COLLECTION_TYPE.PICKUP,
    DINE_IN = OLO.Enums.COLLECTION_TYPE.DINE_IN,
    DELIVERY = OLO.Enums.COLLECTION_TYPE.DELIVERY,
    CATERING = OLO.Enums.COLLECTION_TYPE.CATERING,
}

export const collectionTypeEnum = enumType('COLLECTION_TYPE', COLLECTION_TYPE);
