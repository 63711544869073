import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getLocationDetails } from '@shared/state/locations/selectors';
import { getAvailablePickupTimesForLocation } from '@shared/state/availablePickups/selectors';
import { getSelectedOrderTypeId } from '../../orderTypes/getSelectedOrderType';

type Params = {
    locationNo: Nullable<number>;
    /** Only provide for overwritinig selected one. Otherwise leave null */
    orderTypeId: Nullable<number>;
    format: string;
    prefixes: boolean;
};

export const getLocationPeriods = (params: Partial<Params> = {}) => {
    const opts: Params = {
        orderTypeId: null,
        locationNo: null,
        format: 'ddd, D MMM',
        prefixes: true,
        ...params,
    };

    const { locationNo, format, prefixes, orderTypeId: overwriteOrderTypeId } = opts;

    return createSelector(getLocationDetails(locationNo), getAvailablePickupTimesForLocation(locationNo), getSelectedOrderTypeId, (location, availablePickups, orderTypeId) => {
        if (!location || !location.OrderingTimeInfo) return null;

        const nextOrderTypeId = overwriteOrderTypeId || orderTypeId;

        return Utils.Pickups.getFilteredOrderingTimeInfo(location, nextOrderTypeId).reduce((acc, obj) => {
            const period = Utils.Pickups.createPeriodObject(obj, format, prefixes);
            const isToday = Utils.Dates.isToday(obj.Date);
            if (isToday) {
                const todaysList = availablePickups?.data?.filter((a) => a.IsToday === true);
                if (!todaysList || !todaysList?.length) return acc;
            }

            acc.push(period);

            return acc;
        }, []);
    });
};
