import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, withLatestFrom } from 'rxjs/operators';


@Injectable()
export class GoogleAnalyticsEffects {
    @Effect({ dispatch: false }) public onAddToCartSimpleItem$: Observable<void> = this._actions$
        .pipe(
            ofType(
                actions.CartSimpleItemAdd,
                actions.CartSimpleItemIncrement,
            ),
            switchMap(action => {
                this._googleEcommerce.recordAddToCartSimpleItem(action.item);

                return [];
            })
        );

    @Effect({ dispatch: false }) public onAddToCartMenuFlow$: Observable<void> = this._actions$
        .pipe(
            ofType(
                actions.CartMenuFlowAdd,
                actions.CartMenuFlowIncrement
            ),
            switchMap(action => {
                this._googleEcommerce.recordAddToCartMenuFlowItem(action.item);

                return [];
            })
        );

    @Effect({ dispatch: false }) public onRemoveFromCartSimpleItem$: Observable<void> = this._actions$
        .pipe(
            ofType(
                actions.CartSimpleItemRemove,
                actions.CartSimpleItemDecrement
            ),
            switchMap(action => {
                this._googleEcommerce.recordRemoveFromCart({
                    item_id: action.item.Plu.toString(),
                    item_name: action.item.PosDisplay,
                    price: action.item.UnitPrice,
                    currency: this._config.localization.currency,
                    index: null
                });

                return [];
            })
        );

    @Effect({ dispatch: false }) public onRemoveFromCartMenuFlow$: Observable<void> = this._actions$
        .pipe(
            ofType(
                actions.CartMenuFlowRemove,
                actions.CartMenuFlowDecrement
            ),
            switchMap(action => {
                this._googleEcommerce.recordRemoveFromCart({
                    item_id: action.item.MenuFlowId.toString(),
                    item_name: action.item.MenuFlowName,
                    price: action.item.UnitTotalValue,
                    currency: this._config.localization.currency,
                    item_list_name: null,
                    item_list_id: null
                });

                return [];
            })
        );

    @Effect({ dispatch: false }) public onSelectCreditCards$: Observable<void> = this._actions$
        .pipe(
            ofType(
                actions.SelectActiveCreditCardId
            ),
            withLatestFrom(this._store.pipe(select(selectors.getCartTotalValue))),
            switchMap(([action, totalCartValue]) => {
                switch (action?.cardId) {
                    case OLO.Enums.PAYMENT_VENDOR_SERVICE.ACCOUNT_CHARGE:
                        this._googleEcommerce.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.ACOUNT_CHARGE);
                        break;
                    case OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY:
                        this._googleEcommerce.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.GOOGLE_PAY);
                        break;
                    case OLO.Enums.PAYMENT_VENDOR_SERVICE.APPLE_PAY:
                        this._googleEcommerce.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.APPLE_PAY);
                        break;
                    default:
                        this._googleEcommerce.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.CREDIT_CARD);
                        break;
                }

                return [];
            })
        );

    @Effect({ dispatch: false }) public onStepPaymentComplete$: Observable<void> = this._actions$
        .pipe(
            ofType(
                actions.PaymentStepComplete
            ),
            withLatestFrom(
                // these data is required to pass to google ecommerce purchase event before payment init
                this._store.pipe(select(selectors.getOnlineOrderRecalcData)),
                this._store.pipe(select(selectors.getCartSimpleItems)),
                this._store.pipe(select(selectors.getCartMenuFlows)),
                this._store.pipe(select(selectors.getOnlineMenuPages)),
                this._store.pipe(select(selectors.isAccountChargeSelected))
            ),
            switchMap(([step, recalcOrder, simpleItems, menuFlowItems, onlineMenuPages, isAccountChargeSelected]) => {
                if (isAccountChargeSelected) {
                    this._googleEcommerce.recordSpendVirtualCurrency(recalcOrder.data.TotalGrossValue);
                }
                this._googleEcommerce.recordPurchase({
                    orderId: step.OrderId,
                    order: recalcOrder.data,
                    simpleItems,
                    menuFlowItems,
                    onlineMenuPages
                });

                return [];
            })
        );

    @Effect({ dispatch: false }) public onCartPopupVisible$: Observable<void> = this._actions$
        .pipe(
            ofType(
                actions.SetCartPopup
            ),
            switchMap(() => this._store.pipe(select(selectors.isCartPopupVisible))),
            distinctUntilChanged(),
            filter(isCartVisible => isCartVisible),
            switchMap(() => {
                this._googleEcommerce.recordViewCart();

                return [];
            })
        );

    @Effect({ dispatch: false }) public onCartSetupForMenuFlow$: Observable<void> = this._actions$.pipe(
        ofType(
            actions.CartSetup,
        ),
        filter(action => action.type === actions.CartSetup.type && !!action.item?.MenuFlowId),
        switchMap(action => {
            this._googleEcommerce.recordSelectItemMenuFlow(action.item as OLO.State.Cart.CartMenuFlow );

            return [];
        })
    );

    @Effect({ dispatch: false }) public onSearchOnlineMenuOrLocations$: Observable<void> = this._actions$.pipe(
        ofType(
            actions.OnlineMenuFiltersSetSearch,
            actions.LocationsFiltersSetSearch,
        ),
        filter(action => !!action.search?.length),
        switchMap(action => {
            this._googleEcommerce.recordSearch(action.search);

            return [];
        })
    );

    //  TODO in future
    // @Effect() public onCollectionTypeSetGoogleEcommerce$: Observable<Action> = this._actions$
    //     .pipe(
    //         ofType(
    //             actions.SetCollectionType,
    //             actions.PatchOrderTypeIdCollectionTypeValue,
    //             actions.CartSetCollectionTypeId
    //         ),
    //         tap(() => this._googleEcommerceService.recordAddShippingInfo(null)),
    //         switchMap(() => [])
    //     );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _googleEcommerce: Services.GoogleEcommerceService
    ) { }
}
