import * as io from 'io-ts';
import { arrayOfStrings, boolean, string, validString, nullable, nullableString } from '@shared/core/types/common';
import { overlayType, orderTypeIdsArray } from '@shared/core/types/consts';
import { searchModeEnum } from '@shared/core/types/consts/search-mode.type';

const heroBackgroundDefinition: io.MixedObject<OLO.Config.HeroBackground> = {
    topGradient: boolean,
    overlay: overlayType,
    image: nullableString,
};

const daysHelloDefinition: io.MixedObject<OLO.Config.DaysHello> = {
    morningText: validString,
    afternoonText: validString,
    eveningText: string,
};

const topLocationsAreaDefinition: io.MixedObject<OLO.Config.LandingPage.TopLocationsArea> = {
    title: nullableString,
    viewAllLocationsButton: nullableString,
};

const locationModeDefinition: io.MixedObject<OLO.Config.LandingPage.LocationMode> = {
    preHeader: io.type(daysHelloDefinition),
    header: nullableString,
    searchPlaceholderText: nullableString,
    searchMode: searchModeEnum,
    topLocationsArea: io.type(topLocationsAreaDefinition),
};

const venueModeHeaderDefinition: io.MixedObject<OLO.Config.LandingPage.VenueModeHeader> = {
    line1: nullableString,
    line2: nullableString,
};

const venueModeDefinition: io.MixedObject<OLO.Config.LandingPage.VenueMode> = {
    preHeader: io.type(daysHelloDefinition),
    header: io.type(venueModeHeaderDefinition),
    topLocationsArea: io.type(topLocationsAreaDefinition),
};

const dynamicBannerDefinition: io.MixedObject<OLO.Config.LandingPage.DynamicBanner> = {
    header: validString,
    actionButtonText: validString,
    actionButtonUrl: validString,
    image: nullableString,
    body: arrayOfStrings,
};

const rootDefinition: io.MixedObject<OLO.Config.LandingPage> = {
    orderTypeIds: orderTypeIdsArray,
    heroBackground: io.type(heroBackgroundDefinition),
    locationMode: io.union([nullable(io.type(locationModeDefinition)), io.literal(-1)]),
    venueMode: nullable(io.type(venueModeDefinition)),
    dynamicBanners: io.union([io.array(io.type(dynamicBannerDefinition)), io.literal(-1)])
};

export const landingPageSchema = io.array(io.type(rootDefinition));
