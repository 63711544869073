import * as actions from './loader.actions';

const initialState: OLO.State.Loader = {
    isVisible: false,
};

export function loaderReducer(state: OLO.State.Loader = initialState, action: actions.LoaderShow | actions.LoaderHide): OLO.State.Loader {
    switch (action.type) {
        case actions.LOADER_SHOW:
            return {
                ...state,
                isVisible: true,
            };

        case actions.LOADER_HIDE:
            return {
                ...state,
                isVisible: false,
            };

        default:
            return state;
    }
}
