import { Injectable, Inject, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as actions from '@shared/state/actions';

import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LoyaltyAppMapper } from '@shared/core/mappers/loyalty-app.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class LoyaltyAppService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
        public store: Store<OLO.State>,
    ) { }

    public apiGetLoyaltyAppInfo(): Observable<OLO.DTO.LoyaltyAppModel> {
        return this.httpClient
            .get<APIv3.LoyaltyAppModel>(`${this.config.api.base}/loyaltyapp`)
            .pipe(
                map((response: APIv3.LoyaltyAppModel) => LoyaltyAppMapper.mapGetLoyaltyAppInfo(response)),
                map((response: OLO.DTO.LoyaltyAppModel) => {
                    if (isDevMode()) {
                        console.warn('Overriding appSettings for dev purposes');

                        return Utils.DevmodeUtils.overrideAppSettingsPayload(response);
                    }

                    return response;
                })
            );
    }

    public apiGetLoyaltyIntroductionPages(): Observable<OLO.DTO.PaginatedListLoyaltyAppIntroductionPageModel> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetLoyaltyAppIntroductionPages.Responses.$200>(`${this.config.api.base}/loyaltyapp/introductionPages?status=1`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetLoyaltyAppIntroductionPages.Responses.$200) => LoyaltyAppMapper.mapApiGetLoyaltyIntroductionPages(response))
            );
    }

    public apiGetLoyaltyPrograms(): Observable<OLO.DTO.LoyaltyProgramAppAssignmentModel[]> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetLoyaltyPrograms.Responses.$200>(`${this.config.api.base}/loyaltyapp/loyaltyPrograms`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetLoyaltyPrograms.Responses.$200) => LoyaltyAppMapper.mapApiGetLoyaltyPrograms(response))
            );
    }

    public apiGetLoyaltyProductPrograms(): Observable<OLO.DTO.GetLoyaltyProductProgramBusinessModel[]> {
        return this.httpClient
            .get<APIv3.LoyaltyProgramAppAssignmentModel[]>(`${this.config.api.base}/loyaltyapp/loyaltyPrograms`)
            .pipe(
                switchMap((response: APIv3.LoyaltyProgramAppAssignmentModel[]) => {
                    if (response.length) {
                        return forkJoin(response
                            .map(prod => this.httpClient
                                .get<APIv3.GetLoyaltyProductProgramBusinessModel>(`${this.config.api.base}/LoyaltyProductProgram/${prod.LoyaltyProgramId}`)
                                .pipe(
                                    map((loyaltyProgram: APIv3.GetLoyaltyProductProgramBusinessModel) => LoyaltyAppMapper.mapGetLoyaltyProductPrograms(loyaltyProgram))
                                )
                            ));
                    } else {
                        return of(response);
                    }
                })
            );
    }

    public apiGetOrderTypes(locationNo: number): Observable<OLO.DTO.OrderType[]> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetLoyaltyAppOrderTypes.Responses.$200>(`${this.config.api.base}/loyaltyapp/orderTypes?locationNo=${locationNo}`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetLoyaltyAppOrderTypes.Responses.$200) => LoyaltyAppMapper.mapApiGetOrderTypes(response))
            );
    }

    public apiGetMessages(): Observable<OLO.DTO.LoyaltyAppMessageModel[]> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetLoyaltyAppMessages.Responses.$200>(`${this.config.api.base}/loyaltyapp/messages`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetLoyaltyAppMessages.Responses.$200) => LoyaltyAppMapper.mapApiGetMessages(response))
            );
    }

    public requestLoyaltyAppInfo(): void {
        this.store.dispatch(actions.AppSettingsRequest());
    }

    public requestLoyaltyIntroductionPages(): void {
        this.store.dispatch(actions.LoyaltyIntroductionPagesRequest());
    }

    public apiGetMemberInterface(locationNo: number): Observable<OLO.DTO.LoyaltyAppMemberInterfaceModel> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetMemberIterface.Responses.$200>(`${this.config.api.base}/loyaltyapp/memberIterface?locationNo=${locationNo}`)
            .pipe(
                map((response: APIv3.LoyaltyAppGetMemberIterface.Responses.$200) => {
                    const allowedCretitentialTypes = [
                        OLO.Enums.MEMBER_INTERFACE_CREDITENTIAL_TYPE.EMAIL,
                        OLO.Enums.MEMBER_INTERFACE_CREDITENTIAL_TYPE.PHONE,
                        OLO.Enums.MEMBER_INTERFACE_CREDITENTIAL_TYPE.CARD,
                        OLO.Enums.MEMBER_INTERFACE_CREDITENTIAL_TYPE.REDEMPTION_CODE
                    ];

                    if (!response) console.warn('Member interface is not properly configured in api');
                    if (response?.CredentialType && !allowedCretitentialTypes.includes(response?.CredentialType)) {
                        console.warn(`TOLO web application does not support creditential type ${response.CredentialType}`);
                    }

                    return LoyaltyAppMapper.mapApiGetMemberInterface(response);
                })
            );
    }
}
