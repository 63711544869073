import * as io from 'io-ts';
import { boolean, nullableString } from '@shared/core/types/common';
import { loyaltyPointsModeEnum, roundingTypeEnum } from '@shared/core/types/consts';

const rootDefinition: io.MixedObject<OLO.Config.LoyaltyPoints> = {
    enabled: boolean,
    units: nullableString,
    circleHeader: nullableString,
    header: nullableString,
    mode: loyaltyPointsModeEnum,
    roundUnits: roundingTypeEnum,
};

export const loyaltyPointsSchema = io.type(rootDefinition);
