import { createSelector } from '@ngrx/store';

import { isRedirectPaymentType, isBrowserBasedPaymentMethod } from '@shared/state/payment/selectors';
import { isCreditCardPaymentMethodSelected } from '@shared/state/creditCards/selectors';

export const isSelectedPaymentMethodValid = createSelector(
    isRedirectPaymentType,
    isBrowserBasedPaymentMethod,
    isCreditCardPaymentMethodSelected,
    (isRedirectPayment, isBrowserBasedMethod, isCreditCardValid) => isCreditCardValid || isRedirectPayment || isBrowserBasedMethod,
);
