import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';

import { getCurrentRoute } from '../getCurrentRoute';

export const getCollectionTypeOrderTypeIdFromCurrentRoute = createSelector(getCurrentRoute, (currentRoute) => {
    const config = new Statics.ConfigStatic().current;

    return config.collectionTypes?.find((collectionType) => collectionType.url && collectionType.url === currentRoute?.url?.substring(1))?.orderTypeIds?.[0] || null;
});
