import { createSelector } from '@ngrx/store';

import { getCollectionTypesList } from '@shared/state/collectionType/selectors';
import { isCurrentRouteHome, isCurrentRouteLoyalty, isCurrentRouteLocations, isCurrentRouteCollectionTypeUrl } from '@shared/state/router/selectors';

export const showCollectionTypeToggleForHomePage = () =>
    createSelector(
        getCollectionTypesList,
        isCurrentRouteHome,
        isCurrentRouteLoyalty,
        isCurrentRouteLocations,
        isCurrentRouteCollectionTypeUrl,
        (list, isHome, isLoyaltyHome, isLocations, isCurrentRouteCollectionTypeUrlFlag) => {
            if (isLoyaltyHome || (!isHome && !isLocations && !isCurrentRouteCollectionTypeUrlFlag) || !list.length) return false;

            return list?.length > 1;
        },
    );
