export class CollectionTypeHelper {
    constructor(
        private _collectionTypes: OLO.Config.CollectionTypesNext,
    ) {
        if (!_collectionTypes) return;
    }

    public getCollectionTypeConfig(collectionTypeId: OLO.Enums.COLLECTION_TYPE): Nullable<Unpacked<OLO.Config.CollectionTypesNext>> {
        return this._collectionTypes
            .find(collectionType => collectionType.collectionTypeId === collectionTypeId) || null;
    }

    public getPickupCollectionTypeConfig(): Nullable<OLO.Config.CollectionTypesNext.Pickup> {
        return this._collectionTypes
            .find(collectionType => collectionType.collectionTypeId === OLO.Enums.COLLECTION_TYPE.PICKUP) as OLO.Config.CollectionTypesNext.Pickup || null;
    }

    public getDeliveryCollectionTypeConfig(): Nullable<OLO.Config.CollectionTypesNext.Delivery> {
        return this._collectionTypes
            .find(collectionType => collectionType.collectionTypeId === OLO.Enums.COLLECTION_TYPE.DELIVERY) as OLO.Config.CollectionTypesNext.Delivery || null;
    }

    public getDineInCollectionTypeConfig(): Nullable<OLO.Config.CollectionTypesNext.DineIn> {
        return this._collectionTypes
            .find(collectionType => collectionType.collectionTypeId === OLO.Enums.COLLECTION_TYPE.DINE_IN) as OLO.Config.CollectionTypesNext.DineIn || null;
    }

    public getCateringCollectionTypeConfig(): Nullable<OLO.Config.CollectionTypesNext.Catering> {
        return this._collectionTypes
            .find(collectionType => collectionType.collectionTypeId === OLO.Enums.COLLECTION_TYPE.CATERING) as OLO.Config.CollectionTypesNext.Catering || null;
    }

    public getDefaultCollectionType(orderTypeId?: number): Nullable<Unpacked<OLO.Config.CollectionTypesNext>> {
        return orderTypeId ? this._collectionTypes
            .find(collectionType => collectionType.orderTypeIds.includes(orderTypeId) && collectionType.enabled === true) : this._findFirstAvailable();
    }

    public getCollectionTypeByOrderTypeId(orderTypeId: number): Nullable<Unpacked<OLO.Config.CollectionTypesNext>> {
        return this._collectionTypes
            .find(collectionType => collectionType.orderTypeIds.includes(orderTypeId)) || null;
    }

    public hasAnyCollectionTypeConfigOrderTypeId(orderTypeId: number): boolean {
        return !!this.getCollectionTypeByOrderTypeId(orderTypeId);
    }

    public getOrderTypeIdFromDefaultCollectionType(): number {
        const defaultCollectionType = this.getDefaultCollectionType();
        const orderTypeId = defaultCollectionType.orderTypeIds[0];

        return orderTypeId;
    }

    private _findFirstAvailable(): Nullable<Unpacked<OLO.Config.CollectionTypesNext>> {
        return [...this._collectionTypes]
            .sort((a, b) => a.displayIndex - b.displayIndex)
            .find(collectionType => collectionType.enabled) || null;
    }
}
