import { createSelector } from '@ngrx/store';

import * as Models from '@shared/core/models';
import * as Utils from '@shared/core/utils';

import { getCart } from '@shared/state/cart/selectors';
import { getSelectedOrderType } from '@shared/state/onlineOrder/selectors';
import { getProductRestrictions } from '@shared/state/productRestrictions/selectors';
import { getOrderTypesForLocation } from '@shared/state/orderTypes/selectors';

const matchHelper = (
    product: OLO.State.Cart.CartSimpleItemExtended | OLO.State.Cart.CartMenuFlowPageProduct,
    catIds: number[],
    famIds: number[],
    prodIds: number[],
): boolean | number[] => {
    const matchCategoryId = catIds.includes(product.ProductCategoryId);
    const matchFamilyIds = Utils.Arrays.findCommonItems(famIds, product.ProductFamilyIds || []);
    const matchProdId = prodIds.includes(product.ProductId);

    return matchCategoryId || matchFamilyIds || matchProdId;
};

export const restrictionsLimit = (locationNo: number) =>
    createSelector(getCart, getSelectedOrderType, getProductRestrictions, getOrderTypesForLocation(locationNo), (cart, orderType, productRestrictions, locationOrderTypes) => {
        if (
            ((!orderType || orderType?.IsProductsRestrictionEnabled !== true) && !locationOrderTypes?.data?.length) ||
            productRestrictions.isDownloading ||
            !productRestrictions.data ||
            productRestrictions.LocationNo !== cart.locationNo
        )
            return null;

        const restrictions: Models.RestrictionItem[] = [];

        productRestrictions.data.forEach((restrictionObj) => {
            const catIds = restrictionObj.CategoryIds;
            const famIds = restrictionObj.FamilyIds;
            const prodIds = restrictionObj.ProductIds;

            const simpleProducts: OLO.State.Cart.CartSimpleItemExtended[] = [];
            cart.itemsSimple.forEach((simpleProduct) => {
                if (matchHelper(simpleProduct, catIds, famIds, prodIds)) {
                    simpleProducts.push({
                        ...simpleProduct,
                    });
                }
            });

            const menuFlowProducts: OLO.State.Cart.CartMenuFlowPageProduct[] = [];
            cart.itemsMenuFlow.forEach((menuFlow) => {
                menuFlow.Pages.forEach((page) => {
                    page.Products.forEach((menuFlowProduct) => {
                        if (matchHelper(menuFlowProduct, catIds, famIds, prodIds)) {
                            menuFlowProducts.push({
                                ...menuFlowProduct,
                                Quantity: menuFlowProduct.Quantity * menuFlow.Quantity,
                            });
                        }
                    });
                });
            });

            restrictions.push(
                new Models.RestrictionItem(
                    restrictionObj.CustomerFriendlyName,
                    restrictionObj.CustomerFriendlyDescription,
                    restrictionObj.CategoryIds,
                    restrictionObj.FamilyIds,
                    restrictionObj.MaxQty,
                    simpleProducts,
                    menuFlowProducts,
                ),
            );
        });

        return restrictions;
    });
