import { createSelector } from '@ngrx/store';

import { isOrderTypeValid } from '../../orderTypes/isOrderTypeValid';
import { getOnlineOrderState, hasMetMinimumOrderValueCriteria } from '@shared/state/onlineOrder/selectors';

export const isPaymentDisabledForOnlineOrderValidation = createSelector(
    getOnlineOrderState,
    isOrderTypeValid,
    hasMetMinimumOrderValueCriteria,
    (orderState, isValidOrderType, orderValueValid) =>
        orderState.updateRequest.isUpdating ||
        orderState.downloadRequest.isDownloading ||
        orderState.createRequest.isCreating ||
        orderState.recalculateRequest.isRecalculating ||
        !isValidOrderType ||
        orderValueValid === false,
);
