import { createSelector } from '@ngrx/store';
import { collectionTypeState } from '../root';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

export const getSelectedCollectionType = createSelector(
    collectionTypeState,
    collectionType => {
        const config = new Statics.ConfigStatic().current;

        return new Utils.CollectionTypeHelper(config.collectionTypes).getCollectionTypeByOrderTypeId(collectionType?.orderTypeId);
    }
);
