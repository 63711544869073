import { createSelector } from '@ngrx/store';
import { cartState } from '../root';
import * as Utils from '@shared/core/utils';
import { ConfigStatic } from '@shared/core/statics';

export const isCartCollectionTypeCatering = createSelector(
    cartState,
    cart => {
        if (!cart?.orderTypeId) return false;

        const config = new ConfigStatic().current;

        const collectionTypeCateringConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getCateringCollectionTypeConfig();

        return collectionTypeCateringConfig?.orderTypeIds.includes(cart?.orderTypeId);
    }
);
