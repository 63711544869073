import { createSelector } from '@ngrx/store';
import { getCardsState } from '../getCardsState';
import { isLoadingCards } from '../isLoadingCards';

export const isPaymentDisabledForCards = createSelector(
    getCardsState,
    isLoadingCards,
    (cardsState, cardsLoading) => {
        const isDataEmpty = Boolean(cardsState.data?.length) === false;
        const isCardlessOptionSelected = (
            cardsState.activeCardId === OLO.Enums.PAYMENT_VENDOR_SERVICE.ACCOUNT_CHARGE ||
            cardsState.activeCardId === OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_IN_STORE
        );

        return cardsState.showAddCardForm === true ||
            isDataEmpty && !isCardlessOptionSelected ||
            cardsState.data == null ||
            cardsLoading;
    }
);
