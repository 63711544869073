import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getLocationsState } from '@shared/state/locations/selectors';
import { getSelectedOrderTypeId } from '../../orderTypes/getSelectedOrderType';

export const getMinimumPickupTimesForAllLocationsByDate = (date: string | Date | null = null) =>
    createSelector(getLocationsState, getSelectedOrderTypeId, (locations, orderTypeId) => {
        let d: Date = Utils.Dates.createDate(date);
        const currDay = d.getDay();

        return locations?.data?.map((obj) => {
            const baseObj = {
                locationNo: obj.LocationNo,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };

            const noDataAvailable: boolean = !obj?.OrderingTimeInfo || obj?.OrderingTimeInfo?.length === 0;

            if (noDataAvailable) {
                return {
                    ...baseObj,
                    MinimumPickupTime: null,
                };
            }

            const foundDay = Utils.Pickups.getFilteredOrderingTimeInfo(obj, orderTypeId).find((orderingTime) => orderingTime.DayOfWeek === currDay);

            if (!foundDay) {
                /* When there is no data about selected day  - assume location is closed*/
                return {
                    ...baseObj,
                    MinimumPickupTime: null,
                };
            }

            // const isLocationOpen: boolean = Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(d), foundDay.OpeningTime, foundDay.ClosingTime, 'from');
            const isLocationOpenNowOrLaterToday: boolean = Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(d), d, foundDay.ClosingTime, 'from');

            if (!isLocationOpenNowOrLaterToday) {
                /* Location is closed explicitly */
                return {
                    ...baseObj,
                    MinimumPickupTime: null,
                };
            }

            const foundCurrentPickupTime = foundDay.PickupTimes.find((pickupTime) =>
                Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(d), pickupTime.From, pickupTime.To, 'both'),
            );
            if (!foundCurrentPickupTime) {
                /* By default we assume there is 0 preperation time */
                return {
                    ...baseObj,
                    MinimumPickupTime: 0,
                };
            }

            return {
                ...baseObj,
                MinimumPickupTime: foundCurrentPickupTime.MinimumPickupTime,
            };
        });
    });
