import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import * as actions from '../actions';
import * as selectors from '../selectors';

import { Observable, of, never } from 'rxjs';
import { switchMap, takeWhile, delay, map, filter, withLatestFrom, auditTime, take } from 'rxjs/operators';

@Injectable()
export class ModalEffects {
    private _animationTimeout: number = this._config && (this._config.modals || this._config.modals.animateTimeout) ? this._config.modals.animateTimeout : 500;

    @Effect() public closeAllModalsWithExeptionTypes$: Observable<Action> = this._actions$.pipe(
        ofType(actions.ModalCloseAll),
        withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
        switchMap(([action, modals]) =>
            modals
                .filter((modal) => {
                    if (action.typesExcludedFromClosing.includes(modal.type)) {
                        return false;
                    }

                    return true;
                })
                .map((modal) => (action.animation === OLO.Components.Modals.MODAL_ANIMATION.OUT ? actions.ModalRequestClose(modal.id) : actions.ModalClose({ id: modal.id }))),
        ),
    );

    @Effect({ dispatch: false }) public onModalAnyAction$: Observable<never> = this._actions$.pipe(
        ofType(actions.ModalOpen, actions.ModalRequestClose, actions.ModalBackgroundClicked, actions.ModalAnimate, actions.ModalClose),
        takeWhile(() => this._config.modals && this._config.modals.preventGlobalScroll),
        auditTime(0),
        withLatestFrom(this._store.select(selectors.getAllModals), this._modalsSharedService.modalContainer$),
        switchMap(([, modals, container]) => {
            const isModalOpen: boolean = modals.length > 0;
            const isScrollVisible: boolean = document.body.clientHeight > window.innerHeight;

            if (isModalOpen) {
                /* When open */
                /* TODO! FIX TOPBAR tick WHEN OPENING AND CLOSING DUE TO POSITION:FIXED! */
                if (isScrollVisible && container) {
                    container.nativeElement.style.overflowY = 'scroll';
                    /* If any difference because scrollbar */
                    const bodyWidth: number = document.body.clientWidth;
                    const windowInnerWidth: number = window.innerWidth || window.outerWidth;
                    const diff: number = windowInnerWidth - bodyWidth;

                    if (diff) {
                        document.body.style.paddingRight = `${diff}px`;
                    }

                    document.body.style.overflowY = 'hidden';
                }
            } else {
                /* When closed */
                if (container) {
                    container.nativeElement.style.overflowY = null;
                }
                /* IE FIX - AOLO-208 */
                document.body.removeAttribute('style');
            }

            return never();
        }),
    );

    @Effect() public onModalCloseRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.ModalRequestClose),
        map((action) => actions.ModalAnimate(action.modalId, OLO.Components.Modals.MODAL_ANIMATION.OUT)),
    );

    @Effect() public onModalAnimateOut$: Observable<Action> = this._actions$.pipe(
        ofType(actions.ModalAnimate),
        filter((action) => action.animation === OLO.Components.Modals.MODAL_ANIMATION.OUT),
        delay(this._animationTimeout),
        map((action) => actions.ModalClose({ id: action.id })),
    );

    @Effect() public onModalBackgroundClick$: Observable<Action | never> = this._actions$.pipe(
        ofType(actions.ModalBackgroundClicked),
        switchMap(() => {
            if (this._config.modals && this._config.modals.bgClickClose) {
                return of(actions.ModalRequestClose());
            }

            return never();
        }),
    );

    public showFirstLoadIntroModalOnLocationOrCollectionTypeChange$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.CurrentLocationSet, actions.SetCollectionType),
                filter((action) => {
                    const isCurrrentLocationSetAction = action.type === actions.CurrentLocationSet.type;
                    const isSetCollectionTypeAction = action.type === actions.SetCollectionType.type && action.orderTypeId !== action.previousOrderTypeId;

                    return isCurrrentLocationSetAction || isSetCollectionTypeAction;
                }),
                withLatestFrom(
                    this._store.pipe(select(selectors.getAllModals)),
                    this._store.pipe(select(selectors.getCurrentLocationNo)),
                    this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
                    this._store.pipe(select(selectors.getOrderTypeId)),
                    this._store.pipe(select(selectors.isCartEmpty)),
                ),
                switchMap(([_, modals, currentLocationNo, isLocationDetailsPage, orderTypeId, isCartEmpty]) => {
                    if (modals.length !== 0) {
                        return [];
                    }

                    if (typeof currentLocationNo === 'number' && isLocationDetailsPage) {
                        const currentLocationDetailsPage = Utils.DynamicPages.getCurrentPageSetup(this._config.locationDetailsPage, orderTypeId);
                        if (!!currentLocationDetailsPage?.firstLoadIntroductionModal && isCartEmpty) {
                            return this._store.pipe(
                                select(selectors.getLocationsState),
                                filter((state) => state.data?.length > 0),
                                switchMap(() => {
                                    this._modalsSharedService.show({
                                        type: 'first-load-intro',
                                        locationNo: currentLocationNo,
                                    });

                                    return [];
                                }),
                            );
                        }
                    }

                    return [];
                }),
            ),
        { dispatch: false },
    );

    @Effect({ dispatch: false }) public showDeliveeryModalOnLocationOrCollectionTypeChange$: Observable<void> = this._actions$.pipe(
        ofType(actions.CurrentLocationSet, actions.SetCollectionType),
        switchMap(() =>
            this._store.pipe(
                select(selectors.getAllModals),
                filter((modals) => modals.length === 0),
                take(1),
            ),
        ),
        switchMap(() => this._store.pipe(select(selectors.getCurrentLocationNo), take(1))),
        withLatestFrom(
            this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
            this._store.pipe(select(selectors.getOrderTypeId)),
            this._store.pipe(select(selectors.getCartDeliveryAddress)),
        ),
        switchMap(([currentLocationNo, isLocationDetailsPage, orderTypeId, cartDeliveryAddress]) => {
            if (typeof currentLocationNo === 'number' && isLocationDetailsPage) {
                const selectedCollectionType = new Utils.CollectionTypeHelper(this._config.collectionTypes).getCollectionTypeByOrderTypeId(orderTypeId);
                if (!cartDeliveryAddress && selectedCollectionType && 'deliveryAddressModal' in selectedCollectionType) {
                    this._modalsSharedService.show({
                        type: 'delivery-address',
                        locationNo: currentLocationNo,
                    });
                }
            }

            return [];
        }),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _modalsSharedService: Services.ModalsService,
    ) {}
}
