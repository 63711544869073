import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './alert-messages.actions';

const initialState: OLO.State.AlertMessages.AlertMessage[] = [];

export const alertMessagesReducerFn = createReducer(
    initialState,
    on(
        actions.AlertMessageOpen,
        (state, action) => [
            ...state,
            {
                ...action.message,
                id: action.message.id || new Date().getTime() + Math.floor(Math.random() * 10000),
                type: action.message.type ? action.message.type : 'info',
            }
        ]
    ),
    on(
        actions.AlertMessageClose,
        (state, action) => state.filter(message => message.id !== action.id)
    ),
    on(
        actions.AlertMessageEdit,
        (state, action) => state.map(message => {
            if (message.id === action.id) {
                return {
                    ...message,
                    ...action.options
                };
            }

            return message;
        })
    ),
    on(
        actions.AlertMessageSetBulk,
        (state, action) => {
            const processedMessages: OLO.State.AlertMessages.AlertMessage[] = action.messages.map(message => ({
                ...message,
                id: message.id ? message.id : new Date().getTime() + Math.floor(Math.random() * 10000),
                type: message.type ? message.type : 'info',
            }));

            return [
                ...state,
                ...processedMessages,
            ];
        }
    ),
);

export function alertMessagesReducer(state: OLO.State.AlertMessages.AlertMessage[] | undefined, action: Action) {
    return alertMessagesReducerFn(state, action);
}
