import { createSelector } from '@ngrx/store';

import { getCartLocationNo } from '@shared/state/cart/selectors';
import { getOrderTypesForAllLocations } from '@shared/state/orderTypes/selectors';

export const getOrderTypesForCartsLocation = createSelector(getCartLocationNo, getOrderTypesForAllLocations, (locationNo, orderTypes) => {
    if (!locationNo || orderTypes.length === 0) return null;

    const foundTypes = orderTypes.find((obj) => obj.locationNo === locationNo && obj?.data && obj?.data.length > 0 && obj.isDownloading === false);

    return foundTypes?.data || null;
});
