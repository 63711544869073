import { createSelector } from '@ngrx/store';

import * as Models from '@shared/core/models';
import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

import { getLocationDetails } from '@shared/state/locations/selectors';
import { getCollectionType } from '@shared/state/collectionType/selectors';

export const getCollectionTypesListForLocation = (locationNo: number) =>
    createSelector(getLocationDetails(locationNo), getCollectionType, (location, collectionType) => {
        const config = new Statics.ConfigStatic().current;

        const collectionTypes: Models.CollectionTypeItem[] = [];
        if (!location) return collectionTypes;

        const LocationColletionTypesChecker = new Utils.LocationCollectionTypesChecker(location, config);
        const collectionTypeFromConfig = new Utils.CollectionTypeHelper(config.collectionTypes);

        if (LocationColletionTypesChecker.hasPickups()) {
            const pickupOrderTypeId = LocationColletionTypesChecker.getFirstMatchingOrderTypeIdForPickup();
            collectionTypes.push(
                new Models.CollectionTypeItem(
                    collectionTypes.length + 1,
                    collectionTypeFromConfig.getPickupCollectionTypeConfig().displayName,
                    LocationColletionTypesChecker.getPickupIds(),
                    pickupOrderTypeId === collectionType?.orderTypeId,
                    OLO.Enums.COLLECTION_TYPE.PICKUP,
                    collectionTypeFromConfig.getPickupCollectionTypeConfig().displayIndex,
                ),
            );
        }

        if (LocationColletionTypesChecker.hasCatering()) {
            const cateringOrderTypeId = LocationColletionTypesChecker.getFirstMatchingOrderTypeIdForCatering();
            collectionTypes.push(
                new Models.CollectionTypeItem(
                    collectionTypes.length + 1,
                    collectionTypeFromConfig.getCateringCollectionTypeConfig().displayName,
                    LocationColletionTypesChecker.getCateringIds(),
                    cateringOrderTypeId === collectionType?.orderTypeId,
                    OLO.Enums.COLLECTION_TYPE.CATERING,
                    collectionTypeFromConfig.getCateringCollectionTypeConfig().displayIndex,
                ),
            );
        }

        if (LocationColletionTypesChecker.hasDelivery()) {
            const deliveryOrderTypeId = LocationColletionTypesChecker.getFirstMatchingOrderTypeIdForDelivery();
            collectionTypes.push(
                new Models.CollectionTypeItem(
                    collectionTypes.length + 1,
                    collectionTypeFromConfig.getDeliveryCollectionTypeConfig().displayName,
                    LocationColletionTypesChecker.getDeliveryIds(),
                    collectionType?.orderTypeId === deliveryOrderTypeId,
                    OLO.Enums.COLLECTION_TYPE.DELIVERY,
                    collectionTypeFromConfig.getDeliveryCollectionTypeConfig().displayIndex,
                ),
            );
        }

        const isDineInAllowed = new Utils.LocationDineIn(location).canOrderWithDineIn();
        if (isDineInAllowed) {
            const dineInOrderTypeId = LocationColletionTypesChecker.getFirstMatchingOrderTypeIdForDineIn();
            collectionTypes.push(
                new Models.CollectionTypeItem(
                    collectionTypes.length + 1,
                    collectionTypeFromConfig.getDineInCollectionTypeConfig().displayName,
                    LocationColletionTypesChecker.getDineInIds(),
                    collectionType?.orderTypeId === dineInOrderTypeId,
                    OLO.Enums.COLLECTION_TYPE.DINE_IN,
                    collectionTypeFromConfig.getDineInCollectionTypeConfig().displayIndex,
                ),
            );
        }

        return collectionTypes;
    });
