import { Inject, Injectable } from '@angular/core';
import { GoogleTagManagerService } from './google-tag-manager.shared.service';

import { Store, select } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';

import * as selectors from '@shared/state/selectors';
import * as Utils from '@shared/core/utils';

import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { GAMenuFlowItem, GASimpleItem } from '@shared/core/models/googleAnalytics/google-analytics.model';
import { GoogleAnalyticsMapper } from '@shared/core/mappers/google-analytics.shared.mapper';

@Injectable({
    providedIn: 'root'
})
export class GoogleEcommerceService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _gtmService: GoogleTagManagerService,
        private _store: Store<OLO.State>
    ) {}

    private clearEcommerce(): void {
        this._gtmService.pushTag({ ecommerce: null });
    }

    public recordAddToCartSimpleItem(item: OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended): void {
        this._store.pipe(select(selectors.getOnlineMenuPages)).pipe(
            take(1)
        )
            .subscribe(onlineMenuPages => {
                const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.ADD_TO_CART;
                const ecommerce: GA.EcommerceEvent.Cart = {
                    items: [new GASimpleItem(item, onlineMenuPages, this._config.localization.currency)],
                    value: item.UnitPrice
                };

                this.clearEcommerce();
                this._gtmService.pushTag({ event, ecommerce });
            });
    }

    public recordAddToCartMenuFlowItem(item: OLO.State.Cart.CartMenuFlowExtended): void {
        this._store.pipe(select(selectors.getOnlineMenuPages)).pipe(
            take(1)
        )
            .subscribe(onlineMenuPages => {
                const items: GA.CommonItem[] = [new GAMenuFlowItem(item, onlineMenuPages, this._config.localization.currency)];
                const totalPrice: number = this._calculateTotalValue(items);
                const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.ADD_TO_CART;
                const ecommerce: GA.EcommerceEvent.Cart = {
                    items,
                    value: totalPrice
                };

                this.clearEcommerce();
                this._gtmService.pushTag({ event, ecommerce });
            });
    }

    public recordRemoveFromCart(item: GA.CartItem, quantity: number = 1): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.REMOVE_FROM_CART;
        const ecommerce: GA.EcommerceEvent.Cart = {
            items: [{
                ...item, quantity
            }],
            value: item.price
        };

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    public recordViewItem(item: OLO.DTO.OnlineMenuProductResponseModel): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.VIEW_ITEM;
        const ecommerce: GA.EcommerceEvent.Common = {
            items: [{
                item_name: item.DisplayName,
                item_id: item.ProductId ? item.ProductId.toString() : null,
                price: item.Price,
                item_category: item.ProductCategoryId ? item.ProductCategoryId.toString() : null,
                currency: this._config.localization.currency
            }]
        };

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    public recordViewCart(): void {
        combineLatest(
            this._store.pipe(select(selectors.getCartSimpleItems)),
            this._store.pipe(select(selectors.getCartMenuFlows)),
            this._store.pipe(select(selectors.getOnlineMenuPages)))
            .pipe(
                take(1)
            )
            .subscribe(([simpleItems, menuFlowItems, onlineMenupages]) => {
                const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.VIEW_CART;
                const items: GA.CommonItem[] = [...GoogleAnalyticsMapper.mapSimpleItems(
                    simpleItems, onlineMenupages, this._config.localization.currency), ...GoogleAnalyticsMapper.mapMenuFlowItems(
                    menuFlowItems, onlineMenupages, this._config.localization.currency)];
                const ecommerce: GA.EcommerceEvent.Common = {
                    currency: this._config.localization.currency,
                    items: items,
                    value: this._calculateTotalValue(items)
                };

                this.clearEcommerce();
                this._gtmService.pushTag({ event, ecommerce });
            });
    }

    public recordBeginCheckout(): void {
        combineLatest(
            this._store.pipe(select(selectors.getCartSimpleItems)),
            this._store.pipe(select(selectors.getCartMenuFlows)),
            this._store.pipe(select(selectors.getOnlineOrder)),
            this._store.pipe(select(selectors.getOnlineMenuPages)))
            .pipe(
                take(1)
            )
            .subscribe(([simpleItems, menuFlowItems, onlineOrder, onlineMenupages]) => {
                const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.BEGIN_CHECKOUT;
                const items: GA.CommonItem[] = [...GoogleAnalyticsMapper.mapSimpleItems(
                    simpleItems, onlineMenupages, this._config.localization.currency), ...GoogleAnalyticsMapper.mapMenuFlowItems(
                    menuFlowItems, onlineMenupages, this._config.localization.currency)];
                const totalPrice: number = this._calculateTotalValue(items);
                const ecommerce: GA.EcommerceEvent.CommonWithCoupon = {
                    coupon: onlineOrder.ActivatedVouchers[0]?.VoucherCode,
                    currency: this._config.localization.currency,
                    value: totalPrice,
                    items
                };

                this.clearEcommerce();
                this._gtmService.pushTag({ event, ecommerce });
            });
    }

    public recordPurchase(data: { orderId: number; order: OLO.DTO.OnlineOrderDetailedBusinessModel;
        simpleItems: OLO.State.Cart.CartSimpleItem[]; menuFlowItems: OLO.State.Cart.CartMenuFlow[]; onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[]; }): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.PURCHASE;
        const ecommerce: GA.EcommerceEvent.PurchaseEvent = {
            coupon: data.order.ActivatedVouchers[0]?.VoucherCode,
            currency: this._config.localization.currency,
            transaction_id: data.orderId.toString(),
            tax: data.order.TotalGrossValue - data.order.TotalNettValue,
            value: data.order.TotalGrossValue,
            shipping: Utils.Products.calculateShipping(data.order.Surcharges ? data.order.Surcharges : []),
            items: [...GoogleAnalyticsMapper.mapSimpleItems(data.simpleItems, data.onlineMenuPages, this._config.localization.currency), ...GoogleAnalyticsMapper.mapMenuFlowItems(
                data.menuFlowItems, data.onlineMenuPages, this._config.localization.currency)]
        };

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    public recordAddPaymentInfo(value: number, paymentMethod: GA.PAYMENT_METHOD): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.ADD_PAYMENT_INFO;
        const ecommerce: GA.EcommerceEvent.AddPaymentInfo = {
            currency: this._config.localization.currency,
            payment_type: paymentMethod,
            value: value
        };

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    public recordAddShippingInfo(item: any): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.ADD_SHIPPING_INFO;
        const ecommerce: GA.EcommerceEvent.AddShippingInfo = {};

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    public recordSearch(searchTerm: string): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.SEARCH;
        const ecommerce: GA.EcommerceEvent.Search = {
            search_term: searchTerm
        };

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    public recordSelectContent(contentType: GA.CONTENT_TYPE, item: string): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.SELECT_CONTENT;
        const ecommerce: GA.EcommerceEvent.SelectContent = {
            content_type: contentType,
            item_id: item
        };

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    public recordSelectItemMenuFlow(product: OLO.State.Cart.CartMenuFlowExtended): void {
        this._store.pipe(select(selectors.getOnlineMenuPages)).pipe(
            take(1)
        )
            .subscribe(onlineMenuPages => {
                const onlineMenuPage = Utils.Products.getOnlineMenuPageContaingProduct(product, onlineMenuPages);
                const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.SELECT_ITEM;
                const ecommerce: GA.EcommerceEvent.ItemsList = {
                    items: GoogleAnalyticsMapper.mapMenuFlowItemsForSelectItemEvent(product, onlineMenuPages, this._config.localization.currency),
                    item_list_name: onlineMenuPage?.Name || null,
                    item_list_id: onlineMenuPage?.Id.toString()|| null,
                };

                this.clearEcommerce();
                this._gtmService.pushTag({ event, ecommerce });
            });
    }

    public recordSelectItemSimple(product: OLO.Ordering.SimpleItem): void {
        this._store.pipe(select(selectors.getOnlineMenuPages)).pipe(
            take(1)
        )
            .subscribe(onlineMenuPages => {
                const onlineMenuPage = Utils.Products.getOnlineMenuPageContaingProduct(product, onlineMenuPages);
                const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.SELECT_ITEM;
                const ecommerce: GA.EcommerceEvent.ItemsList = {
                    items: [new GASimpleItem(product, onlineMenuPages, this._config.localization.currency)],
                    item_list_name: onlineMenuPage?.Name || null,
                    item_list_id: onlineMenuPage?.Id.toString()|| null,
                };

                this.clearEcommerce();
                this._gtmService.pushTag({ event, ecommerce });
            });
    }

    public recordSpendVirtualCurrency(value: number): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.SPEND_VIRTUAL_CURRENCY;
        const ecommerce: GA.EcommerceEvent.SpendVirtualCurrency = {
            item_name: null,
            virtual_currency_name: GA.PAYMENT_METHOD.ACOUNT_CHARGE,
            value
        };

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    public recordViewItemList(page: OLO.DTO.OnlineMenuPageResponseModel): void {
        const event: GA.ECOMMERCE_EVENT = GA.ECOMMERCE_EVENT.VIEW_ITEM_LIST;
        const ecommerce: GA.EcommerceEvent.ItemsList = {
            items: page.Products.map(product => ({
                item_id: product.Plu?.toString() || null,
                item_name: product.DisplayName,
                quantity: product.StockAmount,
                price: product.Price,
                currency: this._config.localization.currency,
                index: product.DisplayIndex
            })),
            item_list_name: page.Name,
            item_list_id: page.Id.toString()
        };

        this.clearEcommerce();
        this._gtmService.pushTag({ event, ecommerce });
    }

    private _calculateTotalValue(items: GA.CommonItem[]): number {
        return items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    }
}
