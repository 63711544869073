import { createSelector } from '@ngrx/store';

import * as Models from '@shared/core/models';

import { getOnlineMenuItemById } from '../getOnlineMenuItemById';

export const getStatsForSingleProduct = (productId: number) =>
    createSelector(getOnlineMenuItemById(productId), (product) => {
        if (!product) return null;

        return new Models.Stats({
            price: product.Price,
            kilojoules: product.Kilojoules,
        }).toJson();
    });
