import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

export const hasGuestSignedUp = createSelector(
    membersState,
    members => members.signUp.hasSucceeded === true
        && members.isGuestModeEnabled === true
        && members.data !== null
        && members.accountId !== null && members.accountId === members.data.MemberId
);
