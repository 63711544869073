import { CONFIG_TOKEN } from '@shared/core/tokens/config.tokens';
import { StaticProvider } from './static';

export class ConfigStatic extends StaticProvider<OLO.Config> {
    constructor() {
        super();

        this._setCurrent(CONFIG_TOKEN);
    }
}
