import { createSelector } from '@ngrx/store';

import { loylatyProductProgramsState } from '../root';

export const getAllValidLoyaltyPrograms = createSelector(
    loylatyProductProgramsState,
    (loyaltyProductPrograms) => {
        const now = new Date();

        return (
            loyaltyProductPrograms.data?.reduce((acc, program) => {
                console.warn(program.EndDate, new Date(program.EndDate), new Date(program.StartDate), program.StartDate);
                if (new Date(program.EndDate) <= now || new Date(program.StartDate) > now) return acc;

                return [...acc, program];
            }, [] as OLO.DTO.GetLoyaltyProductProgramBusinessModel[]) || null
        );
    }
);
