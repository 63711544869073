import { createSelector } from '@ngrx/store';

import { getOnlineMenuItemById } from '@shared/state/onlineMenu/selectors';
import { getCartOnlineMenuItemById } from '@shared/state/cart/selectors';
import { getImageForMenuFlow } from '@shared/state/menuFlowImages/selectors';
import { getWizzard } from '@shared/state/wizzard/selectors';

export const getModalDataForOnlineMenuItem = (itemId: number, isMenuFlow: boolean) =>
    createSelector(getOnlineMenuItemById(itemId), getCartOnlineMenuItemById(itemId), getImageForMenuFlow(itemId), getWizzard, (onlineMenu, cartOnlineMenu, image, wizzard) => {
        if (!wizzard || (isMenuFlow && !wizzard.itemsMenuFlow) || (!isMenuFlow && !wizzard.itemsSimple)) return null;

        const isEditing = isMenuFlow ? !!wizzard.itemsMenuFlow['_Id'] : !!wizzard.itemsSimple['_Id'];

        if ((!isEditing && !onlineMenu) || (isEditing && !cartOnlineMenu)) return null;

        const selectedOnlineMenu: OLO.DTO.OnlineMenuProductResponseModel = isEditing ? cartOnlineMenu : onlineMenu;

        return {
            PosDisplay: selectedOnlineMenu.PosDisplay,
            PosDescription: selectedOnlineMenu.PosDescription,
            Image: isMenuFlow ? image : null,
            Stats: {
                price: selectedOnlineMenu.Price,
                cals: selectedOnlineMenu.Kilojoules,
            },
        } as OLO.Components.Modals.ModalDynamicStateForOnlineMenuItem<OLO.State.MenuFlowImage>;
    });
