import { NgModule, ErrorHandler, ModuleWithProviders, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { lazyModulesToObj } from '@shared/core/services/lazyLoader/registry';

import * as Interceptors from '@shared/core/interceptors';
import * as Tokens from '@shared/core/tokens';
import { StaticProvider, staticProviderFactory } from '@shared/core/statics';

@NgModule({
    imports: [
        HttpClientModule,
    ],
})
export class SharedProvidersModule {
    public static forRoot(dependency?: {
        environment: Environment;
        buildEnvironment: Environment;
    }): ModuleWithProviders<SharedProvidersModule> {
        return {
            ngModule: SharedProvidersModule,
            providers: [
                {
                    provide: Tokens.LAZY_MODULES,
                    useFactory: lazyModulesToObj
                },
                {
                    provide: Tokens.ENV_TOKEN,
                    useValue: dependency.environment
                },
                {
                    provide: Tokens.ENV_BUILD_TOKEN,
                    useValue: dependency.buildEnvironment
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: Interceptors.ApiInterceptor,
                    multi: true
                },
                {
                    provide: ErrorHandler,
                    useClass: Interceptors.ErrorsHandlerSharedService,
                },
                {
                    provide: StaticProvider,
                    useFactory: staticProviderFactory,
                    deps: [Injector],
                }
            ],
        };
    }

    constructor(
        /* Register StaticProvider - required! */
        private _static: StaticProvider,
    ) {}
}
