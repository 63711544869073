import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-messages.actions';

const initialState: OLO.State.LoyaltyMessages = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    downloadedDate: null,
    data: null,
};

export const loyaltyMessagesReducerFn = createReducer<OLO.State.LoyaltyMessages>(
    initialState,
    on(actions.LoyaltyMessagesRequest, (state, _action) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.LoyaltyMessagesSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        downloadedDate: new Date().getTime(),
        data: [...action.payload],
    })),
    on(actions.LoyaltyMessagesErrorRequest, (state, _action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
);

export function loyaltyMessagesReducer(state: OLO.State.LoyaltyMessages | undefined, action: Action) {
    return loyaltyMessagesReducerFn(state, action);
}
