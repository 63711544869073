import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './menu-flow-default-activations.actions';

const initialState: OLO.State.MenuFlowDefaultActivations[] = [];

export const menuFlowDefaultActivationsReducerFn = createReducer(
    initialState,
    on(actions.MenuFlowDefaultActivationRequest, (state, action) => {
        const foundObj = state.find((obj) => obj.MenuFlowId === action.menuFlowId);

        if (!foundObj) {
            return [
                ...state,
                {
                    MenuFlowId: action.menuFlowId,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    data: null,
                },
            ];
        }

        return state.map((obj) => {
            if (obj.MenuFlowId === action.menuFlowId) {
                return {
                    ...obj,
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                };
            }

            return obj;
        });
    }),
    on(actions.MenuFlowDefaultActivationSuccessRequest, (state, action) =>
        state.map((obj) => {
            if (obj.MenuFlowId === action.menuFlowId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    data: action.payload,
                };
            }

            return obj;
        }),
    ),
    on(actions.MenuFlowDefaultActivationErrorRequest, (state, action) =>
        state.map((obj) => {
            if (obj.MenuFlowId === action.menuFlowId) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return obj;
        }),
    ),
);

export function menuFlowDefaultActivationsReducer(state: OLO.State.MenuFlowDefaultActivations[] | undefined, action: Action) {
    return menuFlowDefaultActivationsReducerFn(state, action);
}
