import { createSelector } from '@ngrx/store';

import { getOnlineMenu } from '@shared/state/onlineMenu/selectors';
import { getWizzardSimpleItem, getWizzardMenuFlow } from '@shared/state/wizzard/selectors';
import { getDietaryTagImages } from '@shared/state/dietaryTagsImages/selectors';

export const getDietaryTagImagesForWizzardItem = (isMenuFlow: boolean) =>
    createSelector(getOnlineMenu, getWizzardSimpleItem, getWizzardMenuFlow, getDietaryTagImages, (onlineMenu, singleProduct, menuFlow, images) => {
        if (!onlineMenu || !onlineMenu.data || (isMenuFlow && !menuFlow) || (!isMenuFlow && !singleProduct) || !images) return null;

        const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;

        let dietaryTags: OLO.DTO.TagModel[];

        onlineMenu.data.Pages.forEach((page) => {
            page.Products.forEach((product) => {
                if (dietaryTags) return;

                if (isMenuFlow) {
                    if (product.MenuFlowId === itemId && product.DietaryTags) {
                        dietaryTags = [...product.DietaryTags];
                    }

                    return;
                }

                if (product.ProductId === itemId && product.DietaryTags) {
                    dietaryTags = [...product.DietaryTags];
                }
            });
        });

        if (!dietaryTags) return null;

        return images.reduce((acc, tagImg) => {
            const tag = dietaryTags.find((obj) => obj.Id === tagImg.Id);
            if (tag) {
                return [...acc, { ...tagImg, Name: tag.Name }];
            }

            return acc;
        }, [] as OLO.State.DietaryTagImage[]);
    });
