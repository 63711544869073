import { Pricing } from '@shared/core/utils/pricing.utils';
import { Products } from '@shared/core/utils/products.utils';


export class GASimpleItem implements GA.CartItem {
    public item_id: string;
    public item_name: string;
    public quantity: number;
    public item_list_name: string;
    public item_list_id: string;
    public index: number;
    public price: number;
    public currency: string;
    constructor(
        item: (OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended) | OLO.Ordering.SimpleItem,
        onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[],
        currency: OLO.Types.CURRENCY_SYMBOL
    ) {
        const onlineMenuPage = Products.getOnlineMenuPageContaingProduct(item, onlineMenuPages);

        this.item_id = item.Plu?.toString();
        this.item_name = item.PosDisplay;
        this.quantity = item.Quantity;
        this.price = item.UnitPrice;
        this.currency = currency;
        this.index = Products.getProductIndexInOnlineMenuPages(item, onlineMenuPages);
        this.item_list_name = onlineMenuPage?.Name || null;
        this.item_list_id = onlineMenuPage?.Id.toString()|| null;
    };
}

export class GAMenuFlowItem implements GA.CartItem {
    public item_id: string;
    public item_name: string;
    public quantity: number;
    public item_list_name: string;
    public item_list_id: string;
    public index: number;
    public price: number;
    public currency: string;
    constructor(
        item: OLO.State.Cart.CartMenuFlowExtended,
        onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[],
        currency: OLO.Types.CURRENCY_SYMBOL
    ) {
        const extraPrice = Pricing.calculateExtraPriceForIngredients(item);
        const totalPrice = item.Quantity * (item.UnitTotalValue + extraPrice);
        const onlineMenuPage = Products.getOnlineMenuPageContaingProduct(item, onlineMenuPages, true);

        this.item_id = item.MenuFlowId?.toString();
        this.item_name = item.MenuFlowName;
        this.quantity = item.Quantity;
        this.price = totalPrice;
        this.currency = currency;
        this.index = Products.getProductIndexInOnlineMenuPages(item, onlineMenuPages, true);
        this.item_list_name = onlineMenuPage?.Name || null;
        this.item_list_id = onlineMenuPage?.Id.toString()|| null;
    };
}
