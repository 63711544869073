import { createAction, props } from '@ngrx/store';

export const LoyaltyIntroductionPagesRequest = createAction(
    '[Loyalty App] Request introduction pages',
);

export const LoyaltyIntroductionPagesSuccessRequest = createAction(
    '[Loyalty App] Success request introduction pages',
    props<{ payload: OLO.DTO.LoyaltyAppIntroductionPageModel[]; }>(),
);

export const LoyaltyIntroductionPagesErrorRequest = createAction(
    '[Loyalty App] Error request introduction pages',
    props<{ ex?: any; }>(),
);
