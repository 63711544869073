type ReplaceData = {
    variable: string;
    value: string;
};
export class ReplacePlaceholderVariables {
    private static _log(sentence: string, variable: Nullable<string | number>): void {
        console.warn(`The sentence: *** ${sentence} *** doesn't contain the variable *** {{${variable}}} ***. Check sentence and variables name for any typos.`);
    }

    /**
     * In the provided string finds and replaces occurencess of a target value
     * @param rawValue raw string that should be processed
     * @param targetVariable target variable
     * @param newValue next value of target variable
     * @returns {string} transformed value
     */
    public static replaceVariableWithValue(rawValue: string, targetVariable: string, newValue: string | number): string {
        if (typeof rawValue !== 'string') {
            return rawValue;
        }

        const showWarning = !rawValue.includes('{{' + targetVariable + '}}');
        if (showWarning) {
            ReplacePlaceholderVariables._log(rawValue, targetVariable);
        }

        return rawValue.replace('{{' + targetVariable + '}}', `${newValue}`);
    }

    public static replaceVariablesWithValues(value: string, replaceData: ReplaceData[]): string {
        let result = value;

        for (let data of replaceData) {
            result = this.replaceVariableWithValue(result, data.variable, data.value);
        }

        return result;
    }
}
