import { createSelector } from '@ngrx/store';
import { getHistoryOrderByOrderId } from '../getHistoryOrderByOrderId';

export const getHistoryOrderDeliveryDetailsByOrderId = (orderId: number) =>
    createSelector(
        getHistoryOrderByOrderId(orderId),
        (order) => {
            let formattedAddress = '';
            const deliveryDetails = order?.data?.DeliveryDetails;
            if (!deliveryDetails) return null;

            if (deliveryDetails.Street) formattedAddress += `${deliveryDetails.Street}, `;
            if (deliveryDetails.Building) formattedAddress += `${deliveryDetails.Building}, `;
            if (deliveryDetails.Unit) formattedAddress += `${deliveryDetails.Unit}, `;
            if (deliveryDetails.City) formattedAddress += `${deliveryDetails.City}`;
            if (deliveryDetails.ExtraInstructions) formattedAddress += `, ${deliveryDetails.ExtraInstructions}`;

            return formattedAddress;
        }
    );
