import { createSelector } from '@ngrx/store';

import { getMinimumPickupTimesForAllLocationsByDate } from '../getMinimumPickupTimesForAllLocationsByDate';

export const getEdgeMinimumPickupTimeForLocations = (date: string | Date | null = null) =>
    createSelector(
        getMinimumPickupTimesForAllLocationsByDate(date),
        (minimumPickupTimes) => {
            const edgeMinimumPickupTimeForLocations = minimumPickupTimes?.reduce((acc, curr) => {
                if (curr.MinimumPickupTime === null) return acc;

                if (acc === null) {
                    return curr.MinimumPickupTime;
                }

                if (acc > curr.MinimumPickupTime) {
                    return curr.MinimumPickupTime;
                }

                return acc;
            }, null as number);

            return edgeMinimumPickupTimeForLocations >= 0 ? edgeMinimumPickupTimeForLocations : null;
        }
    );
