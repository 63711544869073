import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';
import * as Utils from '@shared/core/utils';

import { getCardsState, isLoadingCards } from '@shared/state/creditCards/selectors';
import { isAccountSet } from '@shared/state/members/selectors';

export const canMemberAddFirstCard = () =>
    createSelector(getCardsState, isLoadingCards, isAccountSet, (state, isMakingRequests, isAccountSetFlag) => {
        const config = new Statics.ConfigStatic().current;

        if (isMakingRequests || config.payments.baseProvider == null) return false;

        const isVendorPaymentProviderAvailable = Utils.VendorPayments.isAnyVendorPaymentMethodAvailable(config);
        const memberHasCards = Boolean(state.data?.length) === true;
        if (!memberHasCards && (isVendorPaymentProviderAvailable || isAccountSetFlag)) {
            return true;
        }

        return false;
    });
