import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getLocationDetails } from '../getLocationDetails';

/**
 * Returns just open hours for location
 * @param locationNo
 * @returns array of open days, but not necessarily handling any online orders
 */
export const getLocationOperatingHours = (locationNo: number) =>
    createSelector(getLocationDetails(locationNo), (location) => {
        const result =
            location?.OperatingTimeInfo?.map((operatingTimeInfo) => ({
                ...operatingTimeInfo,
                IsOpen: new Utils.LocationOpenStatus(operatingTimeInfo).isOpen(),
            })) || null;

        if (!result) {
            return null;
        }

        const sunday: APICommon.LocationOrderingTimeInfoExtendedModel[] = [];

        const reduced = result.reduce((acc, day, index) => {
            if (index < 7) {
                if (day.DayOfWeek === 0) {
                    sunday.push({
                        ...day,
                    });

                    return acc;
                }

                return [
                    ...acc,
                    {
                        ...day,
                    },
                ];
            }

            return acc;
        }, [] as APICommon.LocationOrderingTimeInfoExtendedModel[]);

        return [...reduced.sort((a, b) => a.DayOfWeek - b.DayOfWeek), ...sunday];
    });
