/**
 * Hello, welcome to the kingdome of validation setup props.
 * This is not direct model validation - only relation betweene provided
 * VALUES should be checked.
 * Do not perform model validation here!
 */
import { Arrays } from '@shared/core/utils/arrays.utils';
import { Objects } from '@shared/core/utils/objects.utils';
import { Validators } from './validators';

type PageItem = {
    orderTypeIds: number[];
    _context: string;
};

export class OrderTypeIdsValidators extends Validators {
    public static readonly ERROR_TYPES = {
        noDefaultFound: 'No default object found',
        multipleDefaults: 'Found multiple defaults',
        unrelatedOrderTypeId: 'Found unrelated orderTypeId',
        inPagesDuplicatedOrderTypeId: 'Found duplicated orderTypeIds for single page',
        rootChildren: 'Sub order types (dineInTable or dineInBuzzer) contain unrelated to parent orderTypeIds',
        rootDuplicatedOrderTypeId: 'Found duplicated orderTypeId',
    };

    public static validatePages(config: OLO.Config, ...paths: string[]): typeof OrderTypeIdsValidators._errors {
        Validators._registerErrorTypes(OrderTypeIdsValidators.ERROR_TYPES);

        const collectionTypes = config.collectionTypes;

        OrderTypeIdsValidators._errors = [];

        const pages: Array<PageItem[]> = paths.reduce((acc, path) => {
            const extractedPages = Objects.getPropertyByString(config, path);
            if(!extractedPages) {
                return acc;
            }

            return [
                ...acc,
                extractedPages.map(pageDetails => ({
                    ...pageDetails,
                    _context: path
                }))
            ];
        }, []);

        const allowedOrderTypeIds = collectionTypes.reduce((arr, collectionItem) => {
            if (!collectionItem.enabled) return arr;

            return [...arr, ...collectionItem.orderTypeIds];
        }, [] as number[]);

        pages.forEach((arrOfPages) => {
            let totalDefaults = 0;
            const definedOrderTypeIds = [];

            arrOfPages.forEach((pageItem, index) => {
                const potentialError = {
                    context: [arrOfPages, pageItem],
                    path: `${pageItem._context}.[${index}]`,
                    foundIn: `[${index}]`,
                    value: null,
                };

                if (!pageItem.orderTypeIds.length) {
                    ++totalDefaults;
                }

                if(totalDefaults > 1) {
                    OrderTypeIdsValidators._errors.push({ multipleDefaults: potentialError });
                }

                pageItem.orderTypeIds.forEach((orderTypeId, idIndex) => {
                    const innerError = {
                        ...potentialError,
                        context: [
                            ...potentialError.context,
                            pageItem.orderTypeIds
                        ],
                        path: `${potentialError.path}.orderTypeIds.[${idIndex}]`,
                        foundIn: `[${idIndex}]`,
                        value: orderTypeId,
                    };

                    if (definedOrderTypeIds.includes(orderTypeId)) {
                        OrderTypeIdsValidators._errors.push({ inPagesDuplicatedOrderTypeId: innerError });
                    } else {
                        definedOrderTypeIds.push(orderTypeId);
                    }

                    if (!allowedOrderTypeIds.includes(orderTypeId)) {
                        OrderTypeIdsValidators._errors.push({ unrelatedOrderTypeId: innerError });
                    }
                });

                if (totalDefaults === 0 && arrOfPages.length === index + 1) {
                    OrderTypeIdsValidators._errors.push({ noDefaultFound: potentialError });
                }
            });
        });

        return OrderTypeIdsValidators._errors;
    }

    public static validateTuple(config: OLO.Config): typeof OrderTypeIdsValidators._errors {
        Validators._registerErrorTypes(OrderTypeIdsValidators.ERROR_TYPES);

        if (config?.collectionTypes) {
            config.collectionTypes.reduce((map, obj, index) => {
                if (!obj.enabled) return map;

                for (const key in map) {
                    const prevOrderTypeIds = map[key];
                    const path = `config.collectionTypes.(${obj.displayName})[${index}]`;

                    obj.orderTypeIds?.forEach((orderTypeId, idIndex) => {
                        if (prevOrderTypeIds.includes(orderTypeId)) {
                            OrderTypeIdsValidators._errors.push({
                                rootDuplicatedOrderTypeId: {
                                    context: [config, config.collectionTypes, obj],
                                    path: `${path}.orderTypeIds.[${idIndex}]`,
                                    foundIn: key,
                                    value: orderTypeId,
                                },
                            });
                        }
                    });

                    if ('dineInTable' in obj) {
                        const children = [obj.dineInTable.orderTypeId, obj.dineInBuzzer.orderTypeId];
                        const parent = [...obj.orderTypeIds];
                        if (!Arrays.arraysMatch(children, parent)) {
                            OrderTypeIdsValidators._errors.push({
                                rootChildren: {
                                    context: [config, config.collectionTypes, obj],
                                    path: `${path}.(dineInTable|dineInBuzzer).orderTypeId`,
                                    foundIn: obj.displayName,
                                    value: children,
                                },
                            });
                        }
                    }
                }

                return {
                    ...map,
                    [obj.displayName]: obj.orderTypeIds,
                };
            }, {} as Record<string, number[]>);
        }

        return OrderTypeIdsValidators._errors;
    }
}
