import { createAction, props } from '@ngrx/store';

export const DietaryTagsImagesRequest = createAction(
    '[Dietary tags images] Request',
    props<{ params: APICommon.ImagesGetParams; dietaryTagIds: number[]; }>(),
);

export const DietaryTagsImagesSuccessRequest = createAction(
    '[Dietary tags images] Success request',
    props<{ payload: OLO.DTO.ImageUrlModel[]; dietaryTagIds: number[]; }>(),
);

export const DietaryTagsImagesErrorRequest = createAction(
    '[Dietary tags images] Error request',
    props<{ ex?: any; dietaryTagIds: number[]; }>(),
);
