import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

export const getFreeProduct = (freeProductId: number) => createSelector(
    membersState,
    members => {
        if (!members.data || !members.freeProducts.data) return null;

        return members.freeProducts.data.find(obj => obj.FreeProductId === freeProductId) || null;
    }
);
