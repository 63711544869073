import { createSelector } from '@ngrx/store';

import { isMenuFlowProductSelectable } from '../isMenuFlowProductSelectable';
import { isWizzardPageLimitReached } from '../isWizzardPageLimitReached';
import { getWizzardMenuFlowProductTotalQuantity } from '@shared/state/wizzard/selectors';
import { isWizzardPageOneAllowed } from '../isWizzardPageOneAllowed';

export const wizzardProductActionToTake = (pageIdentifier: number, productId: number) =>
    createSelector(
        isMenuFlowProductSelectable(pageIdentifier, productId),
        isWizzardPageLimitReached(pageIdentifier),
        getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId),
        isWizzardPageOneAllowed(pageIdentifier),
        (canSelect, pageLimitReached, productQty, isPageOneAllowed) => {
            if (!canSelect) return null;
            if (isPageOneAllowed) {
                if (pageLimitReached && productQty !== 0) {
                    return 'remove';
                }

                if (pageLimitReached && productQty === 0) {
                    return 'replace';
                }

                return 'add';
            } else {
                if (!pageLimitReached) {
                    return 'add';
                }

                return null;
            }
        },
    );
