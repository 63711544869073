import { createSelector } from '@ngrx/store';
import { getHistoryOrderByOrderId } from '../getHistoryOrderByOrderId';
import { ConfigStatic } from '@shared/core/statics';
import { CollectionTypeHelper } from '@shared/core/utils';

export const isHistoryOrderDineInBuzzer = (orderId: number) =>
    createSelector(getHistoryOrderByOrderId(orderId), (order) => {
        if (!order?.data) return null;
        const config = new ConfigStatic().current;

        const orderTypeId: number = order.data.OrderTypeId;
        const collectionTypeDineInConfig = new CollectionTypeHelper(config.collectionTypes).getDineInCollectionTypeConfig();

        return orderTypeId === collectionTypeDineInConfig?.dineInBuzzer?.orderTypeId;
    });
