import * as io from 'io-ts';

interface MinMaxArray<T> extends Array<T> {
    readonly minMaxArray: unique symbol;
}

/**
 * Type of array and with size
 * @param {min=} min minimum width - default 1
 * @param {max=} max maximum width
 */
export const arraySize = (min: number = 1, max?: number) => {
    const isValidSize = (inputArr: Array<unknown>): inputArr is io.Branded<Array<any>, MinMaxArray<any>> => {
        if(min != null && inputArr.length < min) {
            return false;
        }

        if(max != null && inputArr.length > max) {
            return false;
        }

        return true;
    };

    return new io.Type(
        'arrayOfSize',
        isValidSize,
        (input: Array<any>, context) =>
            (isValidSize(input) ?
                io.success(input) :
                io.failure(input, context, `Array size is invalid: Expected min: ${min}, max: ${max ?? 'infinite'}, current size: ${input.length}`)),
        io.identity);
};
