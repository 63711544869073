export class MobilePhone {
    public static extractPhoneValues(currCountry: OLO.Types.COUNTRIES, configuredCountries: OLO.DTO.LoyaltyAppCountryAssignmentModel[], phone: string): {
        raw: string;
        prefix: OLO.DTO.LoyaltyAppCountryAssignmentModel;
        countryPrefixId: number;
        original: string;
    } {
        const result = {
            raw: null,
            prefix: null,
            countryPrefixId: null,
            original: phone || null,
        };

        if (!configuredCountries || configuredCountries.length < 1 || !currCountry || !phone) {
            result.original = phone;
            result.raw = phone;

            return result;
        }

        if (phone.charAt(0) !== '+') {
            result.raw = phone;
            result.original = phone;

            return result;
        }

        const foundCountry: OLO.DTO.LoyaltyAppCountryAssignmentModel = configuredCountries.find(obj => phone.substring(0, obj.PhonePrefix.length) === obj.PhonePrefix);

        if (!foundCountry) {
            result.raw = phone;
            result.original = phone;

            return result;
        }

        result.raw = phone.replace(foundCountry.PhonePrefix, '');
        result.prefix = foundCountry.PhonePrefix;
        result.countryPrefixId = foundCountry.Id;

        return result;
    }
}
