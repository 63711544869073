import { createSelector } from '@ngrx/store';

import { isMemberLoading } from '../isMemberLoading';
import { isMemberAuthorizedJWT } from '../isMemberAuthorizedJWT';
import { getCurrentMember } from '../getCurrentMember';

/**
 * Checks if all data for member is ready and member is fully authorized
 */
export const isMemberStable = createSelector(
    isMemberLoading,
    isMemberAuthorizedJWT,
    getCurrentMember,
    (isLoading, isAuthorized, currentMember) => Boolean(currentMember) && isAuthorized && !isLoading
);
