import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';
import * as Utils from '@shared/core/utils';

import { getCardsState, isLoadingCards, isRequestingCardsFirstTime } from '@shared/state/creditCards/selectors';
import { isAccountSet } from '@shared/state/members/selectors';

export const showPaymentFormForMember = () =>
    createSelector(getCardsState, isLoadingCards, isRequestingCardsFirstTime, isAccountSet, (state, isMakingRequests, isLoadingCardsFirstTime, hasAccount) => {
        const config = new Statics.ConfigStatic().current;

        const isVendorPaymentProviderAvailable = Utils.VendorPayments.isAnyVendorPaymentMethodAvailable(config);

        if (isMakingRequests || isLoadingCardsFirstTime || hasAccount || isVendorPaymentProviderAvailable) return false;

        if (!state.data?.length) return true;

        return state.showAddCardForm;
    });
