import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import { Observable, of } from 'rxjs';
import { map, filter, take, delay, switchMap } from 'rxjs/operators';
import { CreditCardsMapper } from '@shared/core/mappers/credit-cards.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class CreditCardsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) protected _config: OLO.Config,
        protected _store: Store<OLO.State>,
        protected _httpClient: HttpClient,
        @Optional() protected _actions$: Actions,
    ) {
    }

    public getCardItems(): Observable<OLO.DTO.PaginatedListPaymentAccountsListResponse> {
        return this._httpClient
            .get<APIv3.MembersGetMemberCards.Responses.$200>(`${Utils.HTTP.switchApi(this._config.api.base)}/members/my/creditCards`)
            .pipe(
                map(data => ({ ...data, Items: data.Items.filter(item => item.Provider === this._config.payments.baseProvider) })),
                map((results: APIv3.MembersGetMemberCards.Responses.$200) => CreditCardsMapper.mapGetCardItems(results))
            );
    }

    public addMemberCard(cart: OLO.DTO.CreatePaymentAccountRequest): Observable<OLO.DTO.CreatePaymentAccountResponse> {
        const postModel: APIv3.MembersCreateMemberCard.Parameters.Request = CreditCardsMapper.mapAddMemberCardRequest(cart);

        return this._httpClient
            .post<APIv3.MembersCreateMemberCard.Responses.$200>(`${Utils.HTTP.switchApi(this._config.api.base)}/members/my/creditCards`, postModel)
            .pipe(
                map((results: APIv3.MembersCreateMemberCard.Responses.$200) => CreditCardsMapper.mapAddMemberCardResponse(results))
            );
    }

    public removeMemberCardRequest(cardId: number, clientAppKey: string = this._config.api.key): Observable<boolean> {
        return this._httpClient
            .delete<APIv3.MembersRemoveMemberCard.Responses.$200>(`${Utils.HTTP.switchApi(this._config.api.base)}/members/my/creditCards/${cardId}`)
            .pipe(
                map((results: APIv3.MembersRemoveMemberCard.Responses.$200) => CreditCardsMapper.mapRemoveMemberCard(results))
            );
    }

    public async addCreditCardWithRedirect(cardData: OLO.CreditCards.CreditCardDetails): Promise<APICommon.PaymentProviderConfigParams> {
        this._store.dispatch(actions.GetCreditCardTokenWithRedirect(cardData));

        return new Promise<APICommon.PaymentProviderConfigParams>((resolve, reject) => {
            Utils.Redirect.setRedirectAsync()
                .then(() => {
                    this._store
                        .pipe(
                            select(selectors.getCardsState),
                            filter(state => state.token.isGettingToken === false && (state.token.hasSucceeded === true || state.token.hasFailed === true)),
                            take(1),
                            switchMap(state => {
                                if (state.token.hasSucceeded && state.activeCardRedirectUrl) {
                                    if (Utils.Redirect.isRedirecting()) {
                                        this._store.dispatch(actions.SelectActiveCreditCardId({ cardId: null }));
                                        this._store.dispatch(actions.SelectActiveCreditCardToken({ token: null }));
                                        this._store.dispatch(actions.StateSave());

                                        return this._actions$
                                            .pipe(
                                                ofType(
                                                    actions.StateSaveSuccess,
                                                    actions.StateSaveError
                                                ),
                                                take(1),
                                                switchMap(action => {
                                                    if (action.type === actions.StateSaveSuccess.type) {
                                                        return of({
                                                            redirectUrl: state.activeCardRedirectUrl,
                                                            verificationToken: state.sessionToken,
                                                            returnUrlAfterRedirect: state.activeCardReturnUrlAfterRedirect,
                                                        });
                                                    }

                                                    return of(null);
                                                })
                                            );
                                    }

                                    return of({
                                        redirectUrl: state.activeCardRedirectUrl,
                                        verificationToken: state.sessionToken,
                                        returnUrlAfterRedirect: state.activeCardReturnUrlAfterRedirect,
                                    });
                                }

                                return of(null);
                            }),
                            delay(10),
                        ).subscribe(obj => {
                            if (obj) {
                                return resolve(obj);
                            }

                            Utils.Redirect.unsetRedirectAsync()
                                .then(() => {
                                    if (this._config.demoMode === true) {
                                        return resolve({ redirectUrl: 'DEMO_URL' });
                                    }
                                    reject('There was an error getting redirectUrl for credit card');
                                });
                        });
                });
        });
    }

    public async handleCardReturnRedirect(
        status: boolean,
        providerResponseParams: APICommon.PaymentProviderPossibleResponseParams = { token: null }
    ): Promise<boolean> {
        this._store.dispatch(actions.StateRestore({
            setProps: {
                modals: []
            }
        }));

        return new Promise(resolve => {
            Utils.Redirect.unsetRedirectAsync()
                .then(() => {
                    if (!status) {
                        console.warn('Provider responded with "FAILED" status. Please contact payment provider for more details.');
                        this._store.dispatch(actions.CreditCardsValidateErrorRequest({
                            responseParams: {}
                        }));

                        return resolve(false);
                    }

                    this._store.dispatch(actions.CreditCardsValidateRequest({
                        responseParams: providerResponseParams
                    }));

                    this._store
                        .pipe(
                            select(selectors.getCardsState),
                            filter(state => (state.validation.hasSucceeded === true || state.validation.hasFailed === true)),
                            take(1)
                        ).subscribe(state => {
                            if (state.validation.hasFailed) {
                                console.warn('Payment method validation failed failed');

                                return resolve(false);
                            }
                            resolve(true);
                        });

                });
        });
    }
}
