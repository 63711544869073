import { createSelector } from '@ngrx/store';

import { getCurrentLocationNo } from '@shared/state/currentLocation/selectors';
import { getLocations } from '@shared/state/locations/selectors';

export const getCurrentLocationDetails = createSelector(getCurrentLocationNo, getLocations, (locationNo, locations) => {
    if (!locationNo || !locations) return null;

    return locations.find((obj) => obj.LocationNo === locationNo) || null;
});
