import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const isOnlineOrderLoading = createSelector(
    onlineOrderState,
    onlineOrder => (
        onlineOrder.updateRequest.isUpdating ||
        onlineOrder.downloadRequest.isDownloading ||
        onlineOrder.createRequest.isCreating
    )
);
