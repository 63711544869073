import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-introduction-pages.actions';

const initialState: OLO.State.LoyaltyIntroductionPages = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    downloadedDate: null,
    data: null,
};

export const loyaltyIntroductionPagesReducerFn = createReducer<OLO.State.LoyaltyIntroductionPages>(
    initialState,
    on(actions.LoyaltyIntroductionPagesRequest, (state) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.LoyaltyIntroductionPagesSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        downloadedDate: new Date().getTime(),
        data: [...action.payload],
    })),
    on(actions.LoyaltyIntroductionPagesErrorRequest, (state) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
);

export function loyaltyIntroductionPagesReducer(state: OLO.State.LoyaltyIntroductionPages | undefined, action: Action) {
    return loyaltyIntroductionPagesReducerFn(state, action);
}
