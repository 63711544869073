import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of, never, iif, timer } from 'rxjs';
import { switchMap, audit, withLatestFrom, map, take, combineLatest, filter } from 'rxjs/operators';

@Injectable()
export class PickupsEffects {
    private _isScheduleOrderingEnabled = this._config.onlineOrders.scheduledOrders === true;

    /**
     * Calculates default pickup time if not set for current location
     */
    public setDefaultPickupTimeForCurrentLocation$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CurrentLocationSet, actions.SetCollectionType),
            withLatestFrom(this._store.pipe(select(selectors.getCurrentLocationNo)), this._store.pipe(select(selectors.getCurrentPickupTime))),
            switchMap(([, locationNo, currentPickupTime]) => {
                if (!locationNo) {
                    return [];
                }

                return this._store.pipe(select(selectors.getAvailablePickupTimesWithFutureForLocation(locationNo, this._isScheduleOrderingEnabled))).pipe(
                    take(1),
                    switchMap((list) => {
                        if (list?.length > 0) {
                            let nextPickup = currentPickupTime || list[0];

                            if (currentPickupTime) {
                                const foundRelated = list.find((obj) => currentPickupTime?.Id === obj.Id);

                                if (!foundRelated) {
                                    nextPickup = list[0];
                                }
                            }

                            return [actions.CurrentLocationPickupTimeSet(nextPickup), actions.LocationsFiltersSyncPickupTime(nextPickup.IsAsap ? null : nextPickup)];
                        }

                        return [];
                    }),
                );
            }),
        ),
    );

    /**
     * This will validate cart itself comparing to current date.
     * - check cart.pickupTime orderTimeout and pickupTime if user hasn't spent too much time ordering,
     * - check cart.onlineMenu dates and compare it to current date,
     * - check if it's TODAY if there was anything in the cart from other days,
     *
     * Check it only if there is anything in the cart.
     */
    @Effect() public validateCartPickupTimeAndOnlineMenuTime$: Observable<Action> = this._actions$.pipe(
        ofType(
            actions.ROUTER_NAVIGATED,
            actions.WizzardValidate,
            actions.CartLoad,
            actions.CartSetLocationNo,
            actions.CartSetOnlineMenu,
            actions.CartEditItem,
            actions.CartMenuFlowAdd,
            actions.CartMenuFlowDecrement,
            actions.CartMenuFlowIncrement,
            actions.CartMenuFlowRemove,
            actions.CartMenuFlowUpdate,
            actions.CartSimpleItemAdd,
            actions.CartSimpleItemDecrement,
            actions.CartSimpleItemIncrement,
            actions.CartSimpleItemRemove,
            actions.CartSimpleItemUpdate,
        ),
        audit(() => timer(10)),
        withLatestFrom(this._store.pipe(select(selectors.getCart)), this._store.pipe(select(selectors.isCollectionTypeDineIn))),
        switchMap(([, cartObj, isDineIn]) =>
            iif(
                () => cartObj.itemsMenuFlow.length === 0 && cartObj.itemsSimple.length === 0,
                never(),
                this.isPickupOrderTimeValid$().pipe(
                    switchMap((isValid) =>
                        iif(
                            () => isValid === true || (isValid === false && cartObj.pickupTime?.IsAsap === true && isDineIn === true),
                            never(),
                            of(cartObj).pipe(
                                withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
                                switchMap(() => {
                                    console.error('Cart pickup time is not valid any more!');

                                    this._pickupsService.exitLocationWithPickupPrompt();

                                    return [];
                                }),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    public isPickupOrderTimeValid$(date: Date = new Date()): Observable<boolean> {
        return this._store.pipe(
            select(selectors.getCart),
            combineLatest(
                this._store.pipe(
                    select(selectors.getOrderingTimeInfoByCartLocationAndOrderType),
                    filter((obj) => obj !== undefined && obj !== null),
                    take(1),
                ),
            ),
            take(1),
            map(([cart, orderingTimeInfo]) => this._pickupsService.validateSelectedPickupTimeObjForOnlineMenu(date, cart.pickupTime, cart.onlineMenu, orderingTimeInfo)),
        );
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _pickupsService: Services.PickupsService,
    ) {}
}
