import * as io from 'io-ts';
import { boolean, nullableNumber, nullableString, string } from '@shared/core/types/common';
import { energyUnitsEnum } from '@shared/core/types/consts';

const descriptionDefinition: io.MixedObject<OLO.Config.OnlineMenu.ConfigOnlineMenuDescription> = {
    startAt: nullableNumber,
    limit: nullableNumber,
    ellipsis: nullableString,
    completeWords: boolean,
};

const rootDefinition: io.MixedObject<OLO.Config.OnlineMenu> = {
    disclaimer: nullableString,
    searchPlaceholderText: string,
    energyUnits: energyUnitsEnum,
    showSpecialInstructionsForProducts: boolean,
    showSpecialInstructionsForMenuFlows: boolean,
    description: io.type(descriptionDefinition),
    wizzardDescription: io.type(descriptionDefinition),
};

export const onlineMenuSchema = io.type(rootDefinition);
