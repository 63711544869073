import { createSelector } from '@ngrx/store';

import { isInVenueMode } from '@shared/state/appSettings/selectors';
import { getCurrentRoute } from '@shared/state/router/selectors';
import { getLocationsState } from '@shared/state/locations/selectors';

export const currentLocationNoByRoute = createSelector(isInVenueMode, getCurrentRoute, getLocationsState, (isVenueMode, route, locations) => {
    if (!route || !locations || locations.isDownloading || locations.data === null) return undefined;

    if (!isVenueMode) return route.params.id ? +route.params.id : null;

    const foundLocation: OLO.DTO.LocationBusinessModel = locations.data.find((location) => {
        if (location.OnlineOrderingStatus !== 0 || location.LocationOLOIsActive !== true || !location.LocationFriendlyName || !route.params.LocationFriendlyName) return false;

        return location.LocationFriendlyName.toLowerCase().replace(/\s/gi, '') === route.params.LocationFriendlyName.toLowerCase();
    });

    return foundLocation ? foundLocation.LocationNo : null;
});
