import { createSelector } from '@ngrx/store';

import { isMemberLoading, isDownloadingLoyaltyProducts, isDownloadingFreeProducts } from '@shared/state/members/selectors';
import { isDownloadingAnyProgram } from '@shared/state/loyaltyProductPrograms/selectors';

export const isLoadingLoyaltyInfoForCurrentMember = createSelector(
    isMemberLoading,
    isDownloadingLoyaltyProducts,
    isDownloadingFreeProducts,
    isDownloadingAnyProgram,
    (memberIsLoading, isLoadingLoyaltyProducts, isLoadingFreeProducts, isLoadingPrograms) =>
        memberIsLoading || isLoadingLoyaltyProducts || isLoadingFreeProducts || isLoadingPrograms,
);
