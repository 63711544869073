import { createSelector } from '@ngrx/store';

import { getMenuFlowDetailsSepcificPageByWizzard } from '../getMenuFlowDetailsSepcificPageByWizzard';
import { getWizzardMenuFlowPageItemsTotalQuantity } from '@shared/state/wizzard/selectors';

export const isWizzardPageLimitReached = (pageIdentifier: number) =>
    createSelector(getMenuFlowDetailsSepcificPageByWizzard(pageIdentifier), getWizzardMenuFlowPageItemsTotalQuantity(pageIdentifier), (page, currentTotalQuantity) => {
        if (!page || !Number.isInteger(currentTotalQuantity)) return null;

        return currentTotalQuantity >= page.PageMaxQuantity;
    });
