import { Injectable } from '@angular/core';

import * as Utils from '@shared/core/utils';

import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { take, filter, auditTime, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DrawCollapseService {
    private _progress: number = 1;
    private _difference: number = null;

    public contentMinimumHeightObjects: Map<HTMLElement, number> = new Map();
    public totalOffsetObjects: Map<HTMLElement, number> = new Map();
    public totalOffsetMobileObjects: Map<HTMLElement, number> = new Map();

    public headerLimit: number = 150;
    public hasInitialized: boolean = false;
    public transitionTime: number = 500;
    public minimalHeight: number = 300; /* 200 */
    public maximumHeight: number = 360;
    public mediaBreakPoint: number = 768; /* window.innerWidth - below this, things should look a bit different */
    public progressBreakPoint: number = .6; /* below .6 - things should look a bit different */
    public mobileScrollPoint: number = 400; /* Defines when to hide nav bar when scrolling down. Bigger === Later */
    public transition: string = '0 linear';
    public currentHeight: number = 150;
    public expand: boolean = null;
    public preventOnWindowScrolled: boolean = true; /* When user scrolled down, and will toggle collapse, this will prevent from collapsing if calculated height */
    public maxContentMargin!: number;

    public drawDirectiveInit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public drawCurrentHeight$: BehaviorSubject<number> = new BehaviorSubject(this.currentHeight);
    public drawExpand$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public drawOffset$: BehaviorSubject<number> = new BehaviorSubject(0);
    public drawMobileOffset$: BehaviorSubject<number> = new BehaviorSubject(0);
    public drawIsCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public drawProgress$: BehaviorSubject<number> = new BehaviorSubject(this._progress);
    public drawDifference$: BehaviorSubject<number> = new BehaviorSubject(null);
    public minHeightContent$: Subject<number> = new Subject();
    public drawBreakPoint$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public drawNavBarCollapseHeight$: Subject<number> = new Subject();
    public drawContentMargin$: BehaviorSubject<number> = new BehaviorSubject(0);
    public drawCanvasHeightChange$: Subject<number> = new Subject();

    constructor() {

        fromEvent(window, 'resize')
            .pipe(
                auditTime(200),
            )
            .subscribe(event => {
                this.drawBreakPoint$.next(this._progress < this.progressBreakPoint || window.innerWidth < this.mediaBreakPoint);

                /* Update offsets and contents' height values */
                this.minHeightContent$.next(Utils.Objects.sumMapValues(this.contentMinimumHeightObjects));
                this.drawOffset$.next(Utils.Objects.sumMapValues(this.totalOffsetObjects));
            });
    }

    public destroy(): void {
        this.hasInitialized = false;
        this._progress = 1;
        this._difference = null;
        this.currentHeight = 150;
        this.expand = false;
        this.contentMinimumHeightObjects = new Map();
        this.totalOffsetObjects = new Map();
        this.totalOffsetMobileObjects = new Map();
    }

    public setDrawContentMargin(margin: number): void {
        this.drawContentMargin$.next(margin);
    }

    public initDrawDirective(): void {
        this.drawDirectiveInit$.next(true);
    }

    public get drawCanvasExpandedHeight(): number {
        //
        //  Returns height of a drawer canvas area when expanded,
        //  depending on user's screen resolution.
        //  Is used in draw-collapse.shared.directive and sub-nav.shared.service (for adjusting scroll position)
        //
        return window.innerWidth < this.mediaBreakPoint ? this.minimalHeight : this.maximumHeight;
    }

    public get progress(): number {
        return this._progress;
    }


    public get difference(): number {
        return this._difference;
    }

    public setBaseMinimalHeight(height: number): void {
        if (height && height > this.minimalHeight) {
            this.minimalHeight = height;
            this.maximumHeight = height;
        }

        this.drawCanvasHeightChange$.next(this.drawCanvasExpandedHeight);
    }

    public setNavBarHeight(height: number): void {
        //
        //  On mobile devices after more scrolling down,
        //  page will collapse more hiding nav bar.
        //
        return this.drawNavBarCollapseHeight$.next(height);
    }


    public addMinHeight(elem: HTMLElement, height: number): void {
        //
        //  This will register additional component to determine minimum height
        //  of content component
        //

        this.contentMinimumHeightObjects.set(elem, height);

        this.minHeightContent$.next(Utils.Objects.sumMapValues(this.contentMinimumHeightObjects));
    }

    public setProgress(progress: number, difference: number): void {
        //
        //  This will provide data about collapsed progress,
        //  to handle opacity in some components depending on current
        //  'progress'. Value is float between 0 and 1;
        //
        //  The difference value shows how much container has expanded/collapsed in px.
        //  This is used for adding margin when initial scrolling starts and we want to
        //  prevent from content scrolling. It's a trick!
        //
        this._progress = progress;
        this._difference = difference;

        this.drawProgress$.next(progress);
        this.drawDifference$.next(difference);
        this.drawBreakPoint$.next(progress < this.progressBreakPoint || window.innerWidth < this.mediaBreakPoint);
    }

    public updateDrawHeight(height: number): void {
        //
        //  This is triggered from draw-collapse directive from only 1 element.
        //  Then this value can be used be other components with draw-offset directive
        //  to set proper styling depending on their styles;
        //
        this.currentHeight = height;
        this.drawCurrentHeight$.next(this.currentHeight);

        if (height === Utils.Objects.sumMapValues(this.totalOffsetObjects)) {
            this.drawIsCollapsed$.next(true);

            return;
        }

        this.drawIsCollapsed$.next(false);
    }

    public toggleDraw(expand: boolean): void {
        // Instant toggle draw expand or draw collapse
        // It should filter expand value that is different than old expand value, otherwise this
        // will cause expanding bug, when drawExpand will be emitted with same expand value
        this.drawDirectiveInit$
            .pipe(
                filter(hasInit => hasInit === true && this.expand !== expand),
                take(1),
            ).subscribe(() => {
                this.hasInitialized = true;
                this.expand = expand;
                this.drawExpand$.next(expand);
            });

    }

    public addOffset(elem: HTMLElement, offset: number): void {
        //
        //  This method shoul be used by additional components that will add more static
        //  top offset to the drawer, i.e. nav bar, filters draw.
        //  It's used by directive to add aditional offset to calculations.
        //

        this.totalOffsetObjects.set(elem, offset);
        this.drawOffset$.next(Utils.Objects.sumMapValues(this.totalOffsetObjects));
    }

    public removeOffset(elem: HTMLElement): void {
        //
        //  Removes element from offset calculations
        //

        this.totalOffsetObjects.delete(elem);
        this.drawOffset$.next(Utils.Objects.sumMapValues(this.totalOffsetObjects));
    }

    public addOffsetMobile(elem: HTMLElement, offset: number): void {
        //
        //  Adds additional offset height for mobile devices when nav bar collapses
        //  when scrolling more than usual.
        //

        this.totalOffsetMobileObjects.set(elem, offset);

        this.drawMobileOffset$.next(Utils.Objects.sumMapValues(this.totalOffsetMobileObjects));
    }

    public removeOffsetMobile(elem: HTMLElement): void {
        //
        //  Removes element from offset calculations on mobile
        //
        this.totalOffsetMobileObjects.delete(elem);

        this.drawMobileOffset$.next(Utils.Objects.sumMapValues(this.totalOffsetMobileObjects));
    }

    public tempUnlockScrollOnToggle(timeout: number = 100): Promise<boolean> {
        return new Promise(resolve => {
            this.preventOnWindowScrolled = false;

            setTimeout(() => {
                this.preventOnWindowScrolled = true;
            }, timeout);
        });
    }
}
