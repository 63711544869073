import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './cart-popup.actions';

const initialState: OLO.State.CartPopup = {
    isVisible: false,
    animation: null,
};

export function cartPopupReducer(state: OLO.State.CartPopup | undefined, action: Action) {
    return createReducer(
        initialState,
        on(
            actions.SetCartPopup,
            (state, action) => ({
                ...state,
                ...action.options,
            })
        ),
    )(state, action);
}
