import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './locations-filters.actions';

const initialState: OLO.State.LocationsFilters = {
    pickupMode: {
        Id: 1,
        Name: 'ASAP',
    },
    pickupTime: null,
    search: null,
    address: null,
    geocoder: null,
};

export const locationsFiltersReducerFn = createReducer(
    initialState,
    on(actions.LocationsFiltersSetAddress, (state, action) => ({
        ...state,
        address: { ...action.address },
    })),
    on(actions.LocationsFiltersReset, () => ({
        ...initialState,
        pickupMode: {
            ...initialState.pickupMode,
        },
    })),
    on(actions.LocationsFiltersRestorePickupTime, (state) => ({
        ...state,
        pickupMode: {
            ...initialState.pickupMode,
        },
        pickupTime: null,
    })),
    on(actions.LocationsFiltersSetPickupMode, (state, action) => ({
        ...state,
        pickupMode: action.mode,
    })),
    on(actions.LocationsFiltersSyncPickupTime, actions.LocationsFiltersSetPickupTime, (state, action) => ({
        ...state,
        pickupTime: action.pickupTime,
    })),
    on(actions.LocationsFiltersSetSearch, (state, action) => ({
        ...state,
        search: action.search,
        geocoder: action.geocoder,
    })),
    on(actions.InitGeocoder, (state, action) => ({
        ...state,
        geocoder: action.geocoder,
    }))
);

export function locationsFiltersReducer(state: OLO.State.LocationsFilters | undefined, action: Action) {
    return locationsFiltersReducerFn(state, action);
}
