/* https://github.com/pmiatkowski/angularNgrx/blob/master/src/app/state/effects/currency.effects.ts */
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';

import * as Services from '@shared/core/services';



import { Observable, of } from 'rxjs';
import { catchError, mergeMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class IngredientsEffects {
    @Effect() public onMenuFlowSuccessRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MenuFlowsDetailsSuccessRequest
            ),
            withLatestFrom(
                this._store.select('ingredients')
            ),
            switchMap(([action, ingredients]) => {
                /* Get regular images after menu flow downloads */
                const Pages: OLO.DTO.MenuFlowPage[] = action.payload.Pages;
                const productIds: number[] = Pages.reduce((acc, page) => acc.concat(page.Products.map(p => p.ProductId)), []);
                const filtered: number[] = productIds.filter(productId => !ingredients
                    .find(ingredient => ingredient.ProductId === productId && (ingredient.isDownloading || ingredient.hasSucceeded)));

                return of(actions.IngredientsRequest({ locationNo: action.locationNo, productIds: filtered }));
            })
        );

    @Effect() public onRequestIngredient$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.IngredientsRequest
            ),
            mergeMap(({ locationNo, productIds }) => {
                if (productIds.length === 0) return [actions.IngredientsSuccessRequest({ payload: [], locationNo, productIds: [] })];

                return this._productsService.getIngredientsForLocationExtended(locationNo, ...productIds)
                    .pipe(
                        map(payload => actions.IngredientsSuccessRequest({ payload, locationNo, productIds })),
                        catchError(ex => of(actions.IngredientsErrorRequest({ locationNo, productIds, ex })))
                    );

            }),
        );

    constructor(
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _productsService: Services.ProductsService,
    ) { }

}
