import { createSelector } from '@ngrx/store';

import { getCart, getCartMenuFlowQuantityByMenuFlowId } from '@shared/state/cart/selectors';
import { getCurrentLocationNo } from '@shared/state/currentLocation/selectors';

export const getCartMenuFlowTotalQuantityForCurrentLocation = (menuFlowId: number) =>
    createSelector(getCart, getCartMenuFlowQuantityByMenuFlowId(menuFlowId), getCurrentLocationNo, (cart, total, currentLocation) => {
        if (!cart || !cart.locationNo || !currentLocation || cart.locationNo !== currentLocation) return null;

        return total;
    });
