import * as io from 'io-ts';
import { arrayOfStrings, boolean, nullableString } from '@shared/core/types/common';
import { layoutType, buttonFeelType, expandedSectionFeelType, boxFeelType } from '@shared/core/types/consts';

const freeProductTileDefinition: io.MixedObject<OLO.Config.Loyalty.FreeProductTitle> = {
    feel: boxFeelType,
    titleInverted: boolean,
};

const ctaDefinition: io.MixedObject<OLO.Config.Loyalty.CTA> = {
    title: nullableString,
    btnInverted: boolean,
    titleInverted: boolean,
    description: arrayOfStrings,
    sectionFeel: expandedSectionFeelType,
    btnFeel: buttonFeelType,
};

const rootDefinition: io.MixedObject<OLO.Config.Loyalty> = {
    heroText: arrayOfStrings,
    layout: layoutType,
    freeProductTile: io.type(freeProductTileDefinition),
    cta: io.type(ctaDefinition),
};

export const loyaltySchema = io.type(rootDefinition);
