import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './online-menu-pages-images.actions';
import * as Utils from '@shared/core/utils';

const initialState: OLO.State.OnlineMenuPagesImages = {
    default: [],
    forList: [],
    forDetails: [],
    forWeb: [],
    forWebThumb: [],
    forMobile: [],
};

export const onlineMenuPagesImagesReducerFn = createReducer(
    initialState,
    on(
        actions.OnlineMenuPagesImagesRequest,
        (state, action) => {
            let imagesType: string = Utils.Images.toImageTypeString(action.imageType);

            return {
                ...state,
                [imagesType]: action.pageIds.reduce((stateAcc, pageId) => {
                    const index = stateAcc.findIndex(obj => obj.pageId === pageId);

                    if (index === -1) {
                        stateAcc.push({
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                            downloadedDate: null,
                            pageId,
                            data: null
                        });
                    } else {
                        stateAcc[index] = {
                            ...stateAcc[index],
                            isDownloading: true,
                            hasSucceeded: false,
                            hasFailed: false,
                        };
                    }

                    return stateAcc;

                }, [...state[imagesType]])
            };
        }
    ),
    on(
        actions.OnlineMenuPagesImagesSuccessRequest,
        (state, action) => {
            let imagesType: string = Utils.Images.toImageTypeString(action.imageType);

            return {
                ...state,
                [imagesType]: state[imagesType].map(obj => {
                    const hasRequested = action.pageIds.find(pageId => pageId === obj.pageId);
                    if (!hasRequested) {
                        return obj;
                    }
                    const found = action.payload.find(o => o.ParentId === obj.pageId);

                    if (found) {
                        return {
                            ...obj,
                            isDownloading: false,
                            hasSucceeded: true,
                            hasFailed: false,
                            downloadedDate: new Date().getTime(),
                            data: {
                                ...found,
                            }
                        };
                    }

                    return {
                        ...obj,
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                        downloadedDate: new Date().getTime(),
                        data: null,
                    };
                })
            };
        }
    ),
    on(
        actions.OnlineMenuPagesImagesErrorRequest,
        (state, action) => {
            let imagesType: string = Utils.Images.toImageTypeString(action.imageType);

            return {
                ...state,
                [imagesType]: state[imagesType].map(obj => {
                    const found = action.pageIds.find(pageId => pageId === obj.pageId);

                    if (found) {
                        return {
                            ...obj,
                            isDownloading: false,
                            hasSucceeded: false,
                            hasFailed: true,
                        };
                    }

                    return obj;
                })
            };
        }
    ),
);

export function onlineMenuPagesImagesReducer(state: OLO.State.OnlineMenuPagesImages | undefined, action: Action) {
    return onlineMenuPagesImagesReducerFn(state, action);
}
