import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';

interface Response {redirectUrl: string; sessionToken: string;};

@Injectable({
    providedIn: 'root',
})
export class WindcavePaymentProviderService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
    ) {
    }

    public get isWindcaveConfigured(): boolean {
        return this.config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.WINDCAVE && this.config.payments.redirectType === true;
    }

    public requestRedirectUrl(obj: any): Observable<Response> {
        return this.httpClient.post<Response>('/api/windcave/validateSession', obj);
    }
}
