import { createSelector } from '@ngrx/store';
import { ConfigStatic } from '@shared/core/statics';
import { membersState } from '../root';

export const showAccountBalance = createSelector(
    membersState,
    members => {
        const config = new ConfigStatic().current;

        if (config.payments.accountCharge?.enabled !== true) return false;

        return members.data?.HasAccount === true;
    }
);
