import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';



import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';


@Injectable()
export class LocationsImagesEffects {
    /** similar code: controllers.images.requestThumbForLocations(locationIds); Refactor required */
    @Effect() public requestLocationImagesForHistoryOrders$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.HistoryOrdersSuccessRequest,
                actions.HistoryOrdersLoadMoreSuccessRequest
            ),
            switchMap(({ payload }) => {
                const { width, height } = Utils.Dimensions.calculateThumbSize();
                const locationIds = [...new Set<number>(payload.map(order => order.PickupLocation))];

                return [
                    actions.LocationImagesRequest(
                        locationIds,
                        OLO.Enums.IMAGE_TYPE.ForList,
                        width,
                        height
                    )
                ];

            })
        );

    @Effect() public requestImagesForLocations$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LocationImagesRequest
            ),
            withLatestFrom(
                this._store.pipe(
                    select(
                        selectors.getAllLocationImages
                    )
                )
            ),
            mergeMap(([action, images]) => {
                const { imageType, height, width, ids } = action;
                let targetImageType: string = Utils.Images.toImageTypeString(imageType);
                //
                //  Prevent from redownloading same image
                //
                const imagesFiltered = {
                    downloaded: [],
                    required: [],
                };

                ids.forEach(id => {
                    const img = images[targetImageType].find(obj => obj.Id === id);

                    if (img.data) {
                        imagesFiltered.downloaded.push(img);
                    } else {
                        imagesFiltered.required.push(img.Id);
                    }
                });

                if (imagesFiltered.downloaded.length) {
                    this._store.dispatch(actions.LocationImagesSuccessRequest({
                        imageType,
                        ids: imagesFiltered.downloaded.map(img => img.Id),
                        payload: imagesFiltered.downloaded
                    }));
                }

                return this._imagesService.getImagesForLocationsByType({ imageType: imageType > 100 ? imageType / 100 : imageType, height, width }, ...imagesFiltered.required)
                    .pipe(
                        switchMap(payload => {
                            if (this._config.predownloadImages && this._config.predownloadImages.forLocations) {
                                // console.log('predownloading images for locations', this._config.predownloadImages);
                                return forkJoin(
                                    ...payload.map(img => this._imagesService.preloadImageInMemory(img.ImageUrl))
                                ).pipe(
                                    map(() => actions.LocationImagesSuccessRequest({ imageType, ids: imagesFiltered.required, payload })),
                                );
                            }

                            return of(actions.LocationImagesSuccessRequest({ imageType, ids: imagesFiltered.required, payload }));
                        }),
                        catchError(ex => of(actions.LocationImagesErrorRequest({ imageType, ids: imagesFiltered.required, ex })))
                    );
            }),
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _imagesService: Services.ImagesService,
    ) { }

    private _getNearestLargerMultipleOf50(value: number): number {
        return Math.ceil(value / 50) * 50;
    }
}
