import { createSelector } from '@ngrx/store';
import { getLocationDetails } from '../getLocationDetails';

import * as Utils from '@shared/core/utils';

/**
 * Gets ordering location hours based on order type. Location might be phisically closed but can still take online orders.
 * @param {number} locationNo
 * @param {number} orderTypeId
 * @param {Date=} forDate
 * @returns {OLO.Common.LocationOpenStatus} object
 */
export const getLocationOrderingStatus = (locationNo: number, orderTypeId: number, forDate: Date = new Date()) =>
    createSelector(getLocationDetails(locationNo), (location) => {
        const statusMessageObj = new Utils.LocationOpenStatusMessage(Utils.LocationOpenStatus, location, orderTypeId, forDate);

        statusMessageObj.setMode(OLO.Enums.LOCATION_AVAILABILITY.BY_ORDERING_TIME_INFO);

        return statusMessageObj.getOpenStatusObj();
    });
