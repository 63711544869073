import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './available-pickups.actions';

const initialState: OLO.State.AvailablePickup[] = [];

export const availablePickupsReducerFn = createReducer(
    initialState,
    on(actions.AvailablePickupsReset, () => []),
    on(actions.AvailablePickupsCalculateRequest, (state, { locationNo, collectionTypeId }) => {
        const foundObj = state.find((obj) => obj.locationNo === locationNo);

        if (!foundObj) {
            return [
                ...state,
                {
                    locationNo,
                    collectionTypeId,
                    calculatedDate: null,
                    isCalculating: true,
                    hasFailed: false,
                    hasSucceeded: false,
                    data: null,
                },
            ];
        }

        return state.map((obj) => {
            if (obj.locationNo === locationNo) {
                return {
                    ...obj,
                    collectionTypeId,
                    isCalculating: true,
                    hasFailed: false,
                    hasSucceeded: false,
                };
            }

            return obj;
        });
    }),
    on(actions.AvailablePickupsCalculateSuccessRequest, (state, { locationNo, collectionTypeId, payload }) =>
        state.map((obj) => {
            if (obj.locationNo === locationNo) {
                return {
                    ...obj,
                    collectionTypeId: collectionTypeId,
                    isCalculating: false,
                    hasFailed: false,
                    hasSucceeded: true,
                    data: payload,
                    calculatedDate: new Date().getTime(),
                };
            }

            return obj;
        }),
    ),
    on(actions.AvailablePickupsCalculateErrorRequest, (state, { locationNo, collectionTypeId }) =>
        state.map((obj) => {
            if (obj.locationNo === locationNo) {
                return {
                    ...obj,
                    collectionTypeId: collectionTypeId,
                    isCalculating: false,
                    hasFailed: true,
                    hasSucceeded: false,
                };
            }

            return obj;
        }),
    ),
);

export function availablePickupsReducer(state: OLO.State.AvailablePickup[] | undefined, action: Action) {
    return availablePickupsReducerFn(state, action);
}
