import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';
import * as Utils from '@shared/core/utils';

import { getHistoryOrderByOrderId } from '@shared/state/historyOrders/selectors';
import { getLocationDetails } from '@shared/state/locations/selectors';

export const getOrderTypeDescriptionForOrder = (orderId: number, locationNo: number) =>
    createSelector(getHistoryOrderByOrderId(orderId), getLocationDetails(locationNo), (order, location) => {
        const config = new Statics.ConfigStatic().current;

        if (!order || !order.data || !location) return null;
        let description: string = new Utils.CollectionTypeHelper(config.collectionTypes).getDefaultCollectionType(order.data.OrderTypeId)?.displayName;

        const foundLocationOrderType = location.OrderTypes.find((obj) => obj.Id === order.data.OrderTypeId);
        if (foundLocationOrderType) {
            description = foundLocationOrderType.TypeDescription;
        }

        return description;
    });
