import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import * as Tokens from '@shared/core/tokens';

import { BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DynamicPathsGuard implements CanActivate {
    public routeUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(null);

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _router: Router,

    ) { }

    private _navigateTo404(): Promise<boolean> {
        return this._router.navigateByUrl('/404');
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<boolean>(resolve => {
            this.routeUpdate$
                .pipe(
                    filter(hasUpdated => hasUpdated !== null),
                    take(1),
                )
                .subscribe(hasUpdated => {
                    if (!hasUpdated) {

                        this._navigateTo404();

                        return resolve(false);
                    }
                    const splittedUrl: string[] = state.url.split('/').filter(segment => segment);

                    const isVenueMode = this._config?.venue?.id && this._config?.venue?.name;
                    const hasCollectionTypeUrlConfigured = this._config.collectionTypes.some(collectionType => !!collectionType.url);
                    const collectionTypeUrls = this._config.collectionTypes.reduce((arrUrls, collectionType) => {
                        if (collectionType.url) {
                            return [...arrUrls, collectionType.url];
                        }

                        return arrUrls;
                    }, []);

                    if ((isVenueMode && splittedUrl[0] === this._config.venue.name) || (hasCollectionTypeUrlConfigured && collectionTypeUrls.some(url => url === splittedUrl[0]))) {
                        this._router.navigateByUrl(state.url);
                    } else {
                        this._navigateTo404();
                    }

                    resolve(false);
                });
        });
    }
}
