import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getSelectedOrderTypeId } from '../../orderTypes';
import { getLocationDetails } from '@shared/state/locations/selectors';

export const getPickupTimesForLocationForPeriod = (period: OLO.Ordering.Period, locationNo: number, params: OLO.Ordering.GeneratePickupsParams) =>
    createSelector(getSelectedOrderTypeId, getLocationDetails(locationNo), (selectedOrderTypeId, location) => {
        if (!period) return null;

        const opts: OLO.Ordering.GeneratePickupsParams = {
            ...params,
            orderTypeId: params.orderTypeId || selectedOrderTypeId,
        };

        const { orderTypeId, orderTimeoutBufferMins, startBufferMins, endBufferMins, nextTick, displayAsTimespans } = opts;

        const openingHours = new Utils.LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();

        const result = Utils.Pickups.generatePickupTimesFutureList(period, {
            orderTypeId,
            orderTimeoutBufferMins,
            startBufferMins,
            endBufferMins,
            nextTick,
            displayAsTimespans,
            location,
            openingHours,
        });

        return result;
    });
