import { Injectable } from '@angular/core';

import { HTML } from '@shared/core/utils/html.utils';

import { Subject, fromEvent } from 'rxjs';
import { auditTime } from 'rxjs/operators';

interface IRegistryEntry {
    target: HTMLElement;
    source: HTMLElement;
    offsetElements: HTMLElement[];
    offsetAdditional: number[];
}

@Injectable({
    providedIn: 'root',
})
export class HeightAdjustService {
    public registry: Map<string, IRegistryEntry> = new Map();

    public heightUpdate$: Subject<number> = new Subject();

    constructor() {
        fromEvent(window, 'resize')
            .pipe(
                auditTime(200)
            )
            .subscribe(() => this.recalculateAll());
    }

    private _createEntry(targetId: string): IRegistryEntry {
        if (!this.registry.get(targetId)) {
            this.registry.set(targetId, {
                target: null,
                source: null,
                offsetElements: [],
                offsetAdditional: [],
            });
        }

        return this.registry.get(targetId);
    }

    private _setKey(targetId: string, key: string, value: any): IRegistryEntry {
        const entry: IRegistryEntry = this._createEntry(targetId);

        this.registry.set(targetId, {
            ...entry,
            [key]: value,
        });

        return this.registry.get(targetId);
    }

    private _addToArray(targetId: string, key: string, value: any): IRegistryEntry {
        const entry: IRegistryEntry = this._createEntry(targetId);

        this.registry.set(targetId, {
            ...entry,
            [key]: [
                ...entry[key],
                value,
            ]
        });

        return this.registry.get(targetId);
    }

    private _removeFromArray(targetId: string, key: string, value: any): IRegistryEntry {
        const entry: IRegistryEntry = this._createEntry(targetId);

        this.registry.set(targetId, {
            ...entry,
            [key]: entry[key].filter(obj => obj === value)
        });

        return this.registry.get(targetId);
    }

    public getEntry(id: string): IRegistryEntry {
        return this.registry.get(id);
    }

    public removeEntry(targetId: string): void {
        this.registry.delete(targetId);
    }

    public setSource(targetId: string, elem: HTMLElement): IRegistryEntry {
        return this._setKey(targetId, 'source', elem);
    }

    public setTarget(targetId: string, elem: HTMLElement): IRegistryEntry {
        return this._setKey(targetId, 'target', elem);
    }

    public addOffsetElement(targetId: string, elem: HTMLElement): IRegistryEntry {
        return this._addToArray(targetId, 'offsetElements', elem);
    }

    public removeOffsetElement(targetId: string, elem: HTMLElement): IRegistryEntry {
        return this._removeFromArray(targetId, 'offsetElements', elem);
    }

    public recalculateHeight(targetId: string, initialOffset: number = 0): void {
        const entry: IRegistryEntry = this.registry.get(targetId);
        if (!entry || !entry.source || !entry.target) return;


        let totalHeight: number = initialOffset;

        entry.offsetElements.forEach(elem => totalHeight += HTML.getOuterHeight(elem));
        entry.offsetAdditional.forEach(no => totalHeight += no);
        totalHeight += HTML.getOuterHeight(entry.source);

        this.heightUpdate$.next(totalHeight);
    }

    public recalculateAll(): void {
        setTimeout(() => {
            for (let [key, value] of this.registry) {
                this.recalculateHeight(key);
            }
        }, 0);

    }

}
