import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

export const getMemberFullName = createSelector(
    membersState,
    members => {
        if (!members?.data) return null;

        return `${members.data.MemberName}${members.data.MemberSurname ? ' ' + members.data.MemberSurname : ''}`;
    }
);
