import { createAction, props } from '@ngrx/store';

export const LoyaltyMessagesRequest = createAction(
    '[Loyalty Messages] Request messages',
);

export const LoyaltyMessagesSuccessRequest = createAction(
    '[Loyalty Messages] Success request messages',
    props<{ payload: OLO.DTO.LoyaltyAppMessageModel[]; }>(),
);

export const LoyaltyMessagesErrorRequest = createAction(
    '[Loyalty Messages] Error request messages',
    props<{ ex?: any; }>(),
);
