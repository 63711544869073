import { createSelector } from '@ngrx/store';
import { cartState } from '../root';

export const isCartEmpty = createSelector(
    cartState,
    cart => {
        const isMenuFlowEmpty: boolean = cart.itemsMenuFlow === null || cart.itemsMenuFlow.length === 0;
        const isSimpleEmpty: boolean = cart.itemsSimple === null || cart.itemsSimple.length === 0;

        return isMenuFlowEmpty && isSimpleEmpty;
    }
);
