import { createSelector } from '@ngrx/store';

import { getLoyaltyFreeProductsForCurrentMember } from '../getLoyaltyFreeProductsForCurrentMember';

export const getLoyaltyFreeProductsForCurrentMemberGrouped = createSelector(getLoyaltyFreeProductsForCurrentMember, (items) =>
    items !== null
        ? (items.reduce((acc, item) => {
            const codeName = item.codeName;
            const qty = item.program.ProductQauantityToIssue;

            const existingItem = acc.find((obj) => obj.codeName === codeName);
            if (!existingItem) {
                acc.push({
                    ...item,
                    program: { ...item.program },
                    freeProduct: [...item.freeProduct],
                    loyaltyProduct: { ...item.loyaltyProduct },
                });
            } else {
                existingItem.freeProduct.push({ ...item });
                existingItem.program.ProductQauantityToIssue = existingItem.program.ProductQauantityToIssue + qty;
            }

            return acc;
        }, []) as OLO.Ordering.LoyaltyFreeProductItemModel[])
        : [],
);
