import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getCartMenuFlowByTempId } from '../getCartMenuFlowByTempId';

export const getCartMenuFlowDescription = (tempId: number) =>
    createSelector(getCartMenuFlowByTempId(tempId), (menuFlow) => {
        if (!menuFlow) return null;

        return Utils.Items.generateCartMenuFlowDescription(menuFlow);
    });
