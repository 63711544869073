import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppModeOrderingGuard implements CanActivate {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _router: Router,
    ) { }

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {

        if (this._config.appMode === OLO.Enums.APP_MODE.LOYALTY_ONLY) {
            return this._router.navigate(['/404']);
        }

        return true;
    }
}
