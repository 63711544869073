import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';

import { getCardsState, isLoadingCards } from '@shared/state/creditCards/selectors';
import { showPaymentFormForMember } from '../showPaymentFormForMember';

export const canMemberAddAnotherCard = () =>
    createSelector(getCardsState, isLoadingCards, showPaymentFormForMember(), (state, isMakingRequests, showForm) => {
        const config = new Statics.ConfigStatic().current;

        if (isMakingRequests || config.payments.baseProvider == null) return false;

        return showForm ? false : state.data.length > 0;
    });
