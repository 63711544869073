import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import { getOnlineMenu } from '@shared/state/onlineMenu/selectors';
import { getOnlineMenuFiltersSearchInput } from '@shared/state/onlineMenuFilters/selectors';

export const getOnlineMenuPagesFiltered = createSelector(getOnlineMenu, getOnlineMenuFiltersSearchInput, (onlineMenu, searchInput) => {
    const pages = onlineMenu?.data?.Pages || null;
    if (!searchInput || !pages) {
        return pages;
    }

    return pages.reduce((pagesAcc, page) => {
        if (page.Products.length === 0) return pagesAcc;

        /* Page check */
        const pageMatches = Utils.Strings.searchValueStringInObject(searchInput, page, 'Name', 'Description');
        if (pageMatches) {
            if (page.Products.length) {
                pagesAcc.push(page);
            }

            return pagesAcc;
        }

        /* Check products */
        const productsFiltered = page.Products.filter((product) => {
            const productMatches = Utils.Strings.searchValueStringInObject(searchInput, product, 'PosDisplay', 'PosDescription');
            if (productMatches) return true;

            /* Check tags if no match in prop values */
            return !product.Tags ? false : product.Tags.some((t) => Utils.Strings.searchValueStringInObject(searchInput, t, 'Name'));
        });

        if (productsFiltered.length) {
            pagesAcc.push({
                ...page,
                Products: productsFiltered,
            });
        }

        return pagesAcc;
    }, [] as OLO.DTO.OnlineMenuPageResponseModel[]);
});
