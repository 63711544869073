import * as io from 'io-ts';
import { validString } from '@shared/core/types/common';

export const baseDefinition: io.MixedObject<OLO.Config.ForgotPassword.Base> = {
    header: validString,
    body: validString,
};

const forgotPasswordLandingDefinition: io.MixedObject<OLO.Config.ForgotPassword.Landing> = {
    ...baseDefinition,
    button: validString,
};

const rootDefinition: io.MixedObject<OLO.Config.ForgotPassword> = {
    landing: io.type(forgotPasswordLandingDefinition),
    success: io.type(baseDefinition),
    failure: io.type(baseDefinition),
    failureChange: io.type(baseDefinition),
};

export const forgotPasswordSchema = io.type(rootDefinition);
