import { enumType } from '@shared/core/types/common';

enum PAYMENT_PROVIDER {
    CARD_CONNECT = OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT,
    CONVERGE = OLO.Enums.PAYMENT_PROVIDER.CONVERGE,
    PAYMENT_EXPRESS = OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS,
    FAT_ZEBRA = OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA,
    ADYEN = OLO.Enums.PAYMENT_PROVIDER.ADYEN,
    STRIPE = OLO.Enums.PAYMENT_PROVIDER.STRIPE,
    WINDCAVE = OLO.Enums.PAYMENT_PROVIDER.WINDCAVE,
}

export const paymentProviderEnum = enumType('PAYMENT_PROVIDER', PAYMENT_PROVIDER);
