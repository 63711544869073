import { ActionReducer, Action } from '@ngrx/store';

// import * as actions from '../actions';

export function __devMetaReducer(reducer: ActionReducer<OLO.State>): ActionReducer<OLO.State> {
    return function newReducer(state: OLO.State, action: Action): OLO.State {
        // if (action.type === '@ngrx/router-store/navigated') {
        //     console.trace('nav', action);
        // }
        // if (action.type === actions.CartReset.type) {
        //     console.trace('cart reset', action);
        // }

        switch (action.type) {
            case '[__DevSetState] Set state':
                return {
                    ...state,
                    ...(action as any).state,
                };

            default: {
                return reducer(state, action);
            }
        }
    };
}
