import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './menu-flow-images.actions';

const initialState: OLO.State.MenuFlowImage[] = [];

export const menuFlowImagesReducerFn = createReducer(
    initialState,
    on(actions.MenuFlowImagesRequest, (state, action) => {
        const newToConcat: OLO.State.MenuFlowImage[] = [];

        action.menuFlowIds.forEach((menuFlowId) => {
            if (state.find((image) => image.MenuFlowId === menuFlowId)) {
                return;
            }

            newToConcat.push({
                MenuFlowId: menuFlowId,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
                data: null,
            });
        });

        return [
            ...state.map((image: OLO.State.MenuFlowImage) => {
                if (action.menuFlowIds.find((menuFlowId) => menuFlowId === image.MenuFlowId)) {
                    return {
                        ...image,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    };
                }

                return image;
            }),
            ...newToConcat,
        ];
    }),
    on(actions.MenuFlowImagesSuccessRequest, (state, action) => [
        ...state.map((image: OLO.State.MenuFlowImage) => {
            const foundItem: OLO.DTO.ImageUrlModel = action.payload.find((newImage) => newImage.ParentId === image.MenuFlowId);

            if (foundItem) {
                return {
                    ...image,
                    data: { ...foundItem },
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                };
            }

            return {
                ...image,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };
        }),
    ]),
    on(actions.MenuFlowImagesErrorRequest, (state, action) =>
        state.map((image: OLO.State.MenuFlowImage) => {
            if (action.menuFlowIds.find((menuFlowId) => menuFlowId === image.MenuFlowId)) {
                return {
                    ...image,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return image;
        }),
    ),
);

export function menuFlowImagesReducer(state: OLO.State.MenuFlowImage[] | undefined, action: Action) {
    return menuFlowImagesReducerFn(state, action);
}
