export class HTML {
    private _isBodyScrollVisible: boolean;

    /**
     * Extracts full height of an element including top and bottom margins
     * @param element Html element
     * @param additionalProps add i.e. padding-top, padding-bottom, top, bottom
     * @returns {number}
     */
    public static getOuterHeight(element: HTMLElement, ...additionalProps: string[]): number {
        const style = window.getComputedStyle(element);

        return ['margin-top', 'margin-bottom', ...additionalProps]
            .map(key => parseInt(style.getPropertyValue(key), 10))
            .reduce((acc, next) => acc + next, element.offsetHeight);
    }

    public async setBodyOverflowHidden(timeout: number = 0): Promise<boolean> {
        //
        //  Will return true or false if scroll was visible
        //  This way we will be able to set or not fake scroll in our component,
        //
        this._isBodyScrollVisible = document.body.clientHeight > window.innerHeight;

        return new Promise<boolean>(resolve => {
            setTimeout(() => {
                document.body.style.overflowY = 'hidden';
                resolve(this._isBodyScrollVisible);
            }, timeout);
        });
    }

    public async unsetBodyOverflowHidden(timeout: number = 0): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            setTimeout(() => {
                document.body.style.overflowY = null;

                /* IE FIX - AOLO-208 */
                document.body.style['-ms-overflow-y'] = null;
                document.body.removeAttribute('style');

                resolve(this._isBodyScrollVisible);
            }, timeout);
        });
    }

    public static addClass(elem: HTMLElement, ...classes: string[]): void {
        const currentClasses: string[] = elem.className.split(' ');

        classes.forEach((className: string) => {
            if (!currentClasses.includes(className)) {
                currentClasses.push(className);
            }
        });

        elem.className = currentClasses.join(' ');
    }

    public static removeClass(elem: HTMLElement, ...classes: string[]): void {
        classes.forEach((className: string) => {
            const curr: string[] = Array.from(elem.classList);
            if (curr.includes(className)) {
                elem.className = curr.filter((existingClass) => existingClass !== className).join(' ');
            }
        });
    }

    public static hasClass(elem: HTMLElement, className: string): boolean {
        return elem.className.includes(className);
    }

    public static toggleClass(elem: HTMLElement, className: string): boolean {
        if (HTML.hasClass(elem, className)) {
            HTML.removeClass(elem, className);

            return false;
        } else {
            HTML.addClass(elem, className);

            return true;
        }
    }

    public static tempClass(elem: HTMLElement, className: string, timeout: number = 500): Promise<any> {
        return new Promise((resolve) => {
            HTML.addClass(elem, className);
            setTimeout(() => {
                HTML.removeClass(elem, className);
                resolve(true);
            }, timeout);
        });
    }

    public static getComputedStyle(elem: HTMLElement, property: string): string {
        let strValue: string = '';
        if (document.defaultView && document.defaultView.getComputedStyle) {
            strValue = document.defaultView.getComputedStyle(elem, '').getPropertyValue(property);
        } else if ((elem as any).currentStyle) {
            property = property.replace(/-(\w)/g, function (strMatch, p1) {
                return p1.toUpperCase();
            });
            strValue = (elem as any).currentStyle[property];
        }

        return strValue;
    }
}
