import * as Statics from '@shared/core/statics';
import { ReplacePlaceholderVariables } from './replace-variable-with-value.utils';

export class Messages {
    public static mapErrors(errors: { [key: string]: any; }, _?: T.StaticTexts): OLO.Common.MappedMessage[] {
        const text = new Statics.TextsStatic().current;
        const keys: string[] = Object.keys(errors);

        return keys.reduce((acc, key) => {
            let error: OLO.Common.MappedMessage;
            switch (key) {
                case 'minimumOrderValue':
                    error = {
                        message: errors[key],
                        messageType: 'disabled',
                    };
                    break;
                case 'max':
                    error = {
                        message: ReplacePlaceholderVariables.replaceVariableWithValue(text.errors.maxValueShouldBe, 'maxValue', `${errors[key]?.max}` ),
                        messageType: 'danger',
                    };
                    break;
                case 'min':
                    error = {
                        message:  ReplacePlaceholderVariables.replaceVariableWithValue(text.errors.minValueShouldBe, 'minValue', `${errors[key]?.min}` ),
                        messageType: 'danger',
                    };
                    break;
                case 'fieldMatchTo':
                    error = {
                        message: errors[key].message,
                        messageType: 'danger',
                    };
                    break;
                case 'previousTransaction':
                    error = {
                        message: text.errors.refreshPage,
                        messageType: 'danger',
                    };
                    break;

                case 'processingPayment':
                    error = {
                        message: text.errors.issueProcessingPayment,
                        messageType: 'danger',
                    };
                    break;

                case 'cardRetry':
                case 'cardChange':
                    error = {
                        message: text.errors.cardIssue,
                        messageType: 'danger',
                    };
                    break;

                case 'required':
                    error = {
                        message: text.errors.fieldRequired,
                        messageType: 'danger',
                    };
                    break;

                case 'cellPhoneContryError':
                    error = {
                        message: errors[key].message,
                        messageType: 'danger',
                    };
                    break;

                case 'cellPhoneError':
                    error = {
                        message: errors[key].message,
                        messageType: 'danger',
                    };
                    break;

                case 'phoneNotRegistered':
                    error = {
                        message: text.errors.phoneNotVerified,
                        messageType: 'danger',
                    };
                    break;

                case 'email':
                    error = {
                        message: text.errors.emailNotCorrect,
                        messageType: 'danger',
                    };
                    break;

                case 'maxlength':
                    error = {
                        message: ReplacePlaceholderVariables.replaceVariableWithValue(text.errors.maxCharsAllowed, 'maxCharsAllowed', `${errors[key].requiredLength}`),
                        messageType: 'danger',
                    };
                    break;

                case 'minlength':
                    error = {
                        message: ReplacePlaceholderVariables.replaceVariableWithValue(text.errors.minChars, 'minCharsAllowed', `${errors[key].requiredLength}`),
                        messageType: 'danger',
                    };
                    break;

                case 'fieldsMatchError':
                    error = {
                        message: text.errors.valuesDontMatch,
                        messageType: 'danger',
                    };
                    break;

                case 'phoneTaken':
                    error = {
                        message: text.errors.phoneNumberTaken,
                        messageType: 'warning',
                    };
                    break;

                case 'emailTaken':
                    error = {
                        message: text.errors.emailTaken,
                        messageType: 'warning',
                    };
                    break;

                case 'memberInterfacePending':
                    error = {
                        message: text.errors.memberInterfacePending,
                        messageType: 'warning',
                    };
                    break;

                default:
                    error = {
                        message: typeof errors[key] === 'string' ? errors[key] : Messages.parseError(errors, key),
                        messageType: 'danger',
                    };
            }

            return [
                ...acc,
                {
                    key,
                    originalValue: errors[key],
                    ...error
                }
            ];
        }, []);
    }

    public static parseError(errors: {[key: string]: any;}, key: string): {[key: string]: any;} {
        if(!errors[key]) return null;

        try {
            return JSON.parse(JSON.stringify(errors[key]));
        } catch (ex) {
            console.error('ParseError', ex, errors, key);

            return null;
        }
    }
}
