import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { PickupsEffects } from './pickups.effects';

@NgModule({
    declarations: [],
    imports: [CommonModule, EffectsModule.forFeature([PickupsEffects])],
})
export class PickupsStateModule {}
