import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';

import { membersState } from '../root';

export const getObfuscatedForgotMemberEmail = createSelector(
    membersState,
    members => {
        if (!members?.forgotPassword?.email) return null;

        return Utils.Strings.obfuscateEmail(members.forgotPassword.email);
    }
);
