import { createSelector } from '@ngrx/store';

import { isPaymentPossible } from '@shared/state/payment/selectors';

import { canPostOnlineOrder } from '../../onlineOrder/canPostOnlineOrder';
import { isSelectedPaymentMethodValid } from '../isSelectedPaymentMethodValid';

export const canPayForOnlineOrder = createSelector(
    isSelectedPaymentMethodValid,
    canPostOnlineOrder,
    isPaymentPossible,
    (isPaymentMethodValid, canCreateOrder, isPaymentPossibleFlag) => isPaymentMethodValid && canCreateOrder && isPaymentPossibleFlag,
);
