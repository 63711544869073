import { createSelector } from '@ngrx/store';

import { getReorder } from '../getReorder';

export const canAddToCartReorderItems = (orderId: number, locationNo: number, pickupTime: OLO.Ordering.PickupTime) =>
    createSelector(getReorder(orderId, locationNo, pickupTime), (foundReorder) => {
        if (!foundReorder || !foundReorder.data || !foundReorder.data.cart) return false;

        let foundCartItemSelected = !!foundReorder.data.cart.itemsMenuFlow.find((obj) => obj._IsSelected === true && !obj._IsDisabled);
        if (!foundCartItemSelected) {
            foundCartItemSelected = !!foundReorder.data.cart.itemsSimple.find((obj) => obj._IsSelected === true && !obj._IsDisabled);
        }

        return foundCartItemSelected;
    });
