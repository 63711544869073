import { createSelector } from '@ngrx/store';

import { wizzardState } from '../root';

export const getWizzardSelectedModifierForMenuFlowProduct = (pageIdentifier: number, productId: number) =>
    createSelector(wizzardState, (wizzard) => {
        if (!wizzard.itemsMenuFlow || !wizzard.itemsMenuFlow.Pages) return null;
        const page = wizzard.itemsMenuFlow.Pages.find((p) => p.PageIdentifier === pageIdentifier);

        if (!page) return null;

        const product = page.Products.find((Product) => Product.ProductId === productId);

        if (!product) return null;

        let ingredient = null;

        if (product.IngredientsChanges && (product.IngredientsChanges.IngredientsAdded || product.IngredientsChanges.IngredientsModified)) {
            if (product.IngredientsChanges.IngredientsAdded && product.IngredientsChanges.IngredientsAdded[0]) {
                ingredient = product.IngredientsChanges.IngredientsAdded[0];
            }

            if (product.IngredientsChanges.IngredientsModified && product.IngredientsChanges.IngredientsModified[0]) {
                ingredient = product.IngredientsChanges.IngredientsModified[0];
            }
        }

        return ingredient;
    });
