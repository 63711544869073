import { createAction, props } from '@ngrx/store';

/* Request programs */
//
//  strzelaj bez dat na razie, bo one dziwnie dzialaja, sprawdzaj tylko lokalnie daty u siebie i jak jest expired to nie wyswietlaj
//  no i w druga strone, jak jest start date do przodu to tez nie wyswietlaj
//
export const LoyaltyProductProgramsRequest = createAction(
    '[Loyalty Product Programs] Request product programs',
    props<{ requestParams?: APICommon.LoyaltyProductProgramsParams; }>(),
);

export const LoyaltyProductProgramsSuccessRequest = createAction(
    '[Loyalty Product Programs] Success request product programs',
    props<{ requestParams?: APICommon.LoyaltyProductProgramsParams; payload: OLO.DTO.GetLoyaltyProductProgramBusinessModel[]; }>(),
);

export const LoyaltyProductProgramsErrorRequest = createAction(
    '[Loyalty Product Programs] Error request product programs',
    props<{ requestParams?: APICommon.LoyaltyProductProgramsParams; ex?: any; }>(),
);

export const LoyaltyProductProgramsReset = createAction(
    '[Loyalty Product Programs] Reset',
);

/* Request tracking info */

