import { createSelector } from '@ngrx/store';
import { OnlineOrders } from '@shared/core/utils';
import { getHistoryOrderByOrderId } from '../getHistoryOrderByOrderId';

/** Confirmed but not finalized nor cancelled. Might indicate some subphase like sent to kitched or preparing or ready */
export const isHistoryOrderActive = (orderId: number) =>
    createSelector(getHistoryOrderByOrderId(orderId), (order) => {
        if (!order?.data) return null;

        return OnlineOrders.isOrderActive(order.data.Status);
    });
