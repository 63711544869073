import { createSelector } from '@ngrx/store';

import { isCartEmpty, getCartPickupTime } from '@shared/state/cart/selectors';
import { getCurrentLocationNo, getCurrentPickupTime } from '@shared/state/currentLocation/selectors';

export const canNavigateToCheckoutUnauthorized = createSelector(
    isCartEmpty,
    getCurrentLocationNo,
    getCurrentPickupTime,
    getCartPickupTime,
    (isCartEmptyFlag, currentLocationNo, pickupTime, cartPickupTime) => {
        if (isCartEmptyFlag === true) {
            return false;
        }

        if (currentLocationNo === null || currentLocationNo === 0) {
            return false;
        }

        const isPickupTimeSet = cartPickupTime != null || pickupTime != null;

        return isPickupTimeSet;
    },
);
