import { createSelector } from '@ngrx/store';
import { collectionTypeState } from '../root';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

export const isCollectionTypeDineIn = createSelector(
    collectionTypeState,
    collectionType => {
        if (!collectionType?.orderTypeId) return false;

        const config = new Statics.ConfigStatic().current;

        const collectionTypeDineInConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getDineInCollectionTypeConfig();

        return collectionType?.orderTypeId === collectionTypeDineInConfig?.dineInBuzzer?.orderTypeId
            || collectionType?.orderTypeId === collectionTypeDineInConfig?.dineInTable?.orderTypeId;
    }
);
