import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import { CollectionTypeParams } from './collection-type.models';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { switchMap, withLatestFrom, auditTime, filter, take, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class CollectionTypesEffects {
    private _collectionTypeDeliveryConfig: OLO.Config.CollectionTypesNext.DineIn = new Utils.CollectionTypeHelper(this._config.collectionTypes).getDineInCollectionTypeConfig();
    private _dineInBuzzerId: number = this._collectionTypeDeliveryConfig?.dineInBuzzer?.orderTypeId || null;
    private _dineInTableNoId: number = this._collectionTypeDeliveryConfig?.dineInTable?.orderTypeId || null;
    private _buzzerImg: string = this._collectionTypeDeliveryConfig?.dineInBuzzer?.modalIcon || null;
    private _tableImg: string = this._collectionTypeDeliveryConfig?.dineInTable?.modalIcon || null;
    private _buzzerPreTitle: string = this._collectionTypeDeliveryConfig?.dineInBuzzer?.modalPreTitle || null;
    private _tablePreTitle: string = this._collectionTypeDeliveryConfig?.dineInTable?.modalPreTitle || null;
    private _tableTitle: string = this._collectionTypeDeliveryConfig?.dineInTable?.modalTitle || null;
    private _buzzerDescription: string[] = this._collectionTypeDeliveryConfig?.dineInBuzzer?.modalDescription || null;
    private _tableDescription: string[] = this._collectionTypeDeliveryConfig?.dineInTable?.modalDescription || null;
    private _tableDescriptionSelected: string[] = this._collectionTypeDeliveryConfig?.dineInTable?.modalDescriptionSelected || null;

    @Effect() public onChangeLocationSelectDefaultOrderTypeForLocation$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CurrentLocationSet),
        switchMap(() => this._store.pipe(
            select(selectors.routeIsLocationDetailsPage()),
            filter(isCurrentRouteLocationDetails => !!isCurrentRouteLocationDetails),
            take(1)
        )),
        withLatestFrom(
            this._store.pipe(select(selectors.getCurrentLocationNo)),
            this._store.pipe(select(selectors.getCartOrderTypeId)),
            this._store.pipe(
                select(selectors.getCollectionType),
                filter((collectionTypeParams) => !Object.values(collectionTypeParams).every((param) => param === null)),
            ),
            this._store.pipe(select(selectors.isCollectionTypeDineIn)),
            this._store.pipe(select(selectors.getCurrentRouteQueryParams))
        ),
        switchMap(([, currentLocationNo, cartOrderTypeId, collection, isDineIn, queryParams]) =>
            this._store.pipe(
                select(selectors.getCollectionTypesListForLocation(currentLocationNo)),
                filter((obj) => obj !== null && obj?.length > 0),
                take(1),
                switchMap((availableCollectionsForLocation) => {
                    const priorityOrderTypeId = cartOrderTypeId || collection.orderTypeId;
                    let defaultCollectionTypeToSet = availableCollectionsForLocation?.find((obj) => obj.OrderTypeIds.includes(priorityOrderTypeId));

                    const collectionTypeParamObj: CollectionTypeParams = {
                        orderTypeId: null,
                        address: null,
                        tableNo: null,
                    };

                    const isCartOrderTypeId = cartOrderTypeId && defaultCollectionTypeToSet?.OrderTypeIds.includes(cartOrderTypeId);
                    if (isCartOrderTypeId) {
                        collectionTypeParamObj.orderTypeId = priorityOrderTypeId;
                        collectionTypeParamObj.tableNo = collection.tableNo;
                        collectionTypeParamObj.address = collection.address;

                        return [actions.SetCollectionType(collectionTypeParamObj)];
                    }

                    if (isDineIn) {
                        let foundDineInTypeInLocation = availableCollectionsForLocation?.find((obj) => obj.GroupType === OLO.Enums.COLLECTION_TYPE.DINE_IN);
                        defaultCollectionTypeToSet = foundDineInTypeInLocation || defaultCollectionTypeToSet;
                    }

                    if (defaultCollectionTypeToSet) {
                        collectionTypeParamObj.orderTypeId = defaultCollectionTypeToSet.OrderTypeIds[0];
                    }

                    if (!defaultCollectionTypeToSet) {
                        if (availableCollectionsForLocation?.length) {
                            const firstAvailableCollectionInLocation = availableCollectionsForLocation[0];
                            collectionTypeParamObj.orderTypeId = firstAvailableCollectionInLocation.OrderTypeIds[0];
                            collectionTypeParamObj.tableNo = queryParams?.tableNo;
                        }
                    }

                    if (collection.orderTypeId === collectionTypeParamObj.orderTypeId) return [];

                    return [actions.SetCollectionType(collectionTypeParamObj)];
                }),
            ),
        ),
    );

    @Effect() public onCustomOrderTypeSelectedUpdateValuesInCollectionState$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderTypeUpdateValues),
        withLatestFrom(this._store.pipe(select(selectors.getSelectedOrderType))),
        filter(([, orderType]) => orderType !== null),
        switchMap(([action, orderType]) => {
            let arr: Action[] = [actions.PatchOrderTypeIdCollectionTypeValue({ orderTypeId: orderType.Id })];

            if (this._isTableNoDineInType(orderType.Id) || this._isBuzzerDineInType(orderType.Id)) {
                arr.push(actions.PatchTableNoCollectionTypeValue({ tableNo: action.details?.[0]._Value || orderType.Details?.[0]._Value || null }));
            } else {
                arr = [actions.SetCollectionType(new CollectionTypeParams(orderType.Id))];
            }

            return arr;
        }),
    );

    @Effect() public saveValuesInLocalStorage$: Observable<Action> = this._actions$.pipe(
        ofType(actions.PatchOrderTypeIdCollectionTypeValue, actions.PatchAddressCollectionTypeValue, actions.PatchTableNoCollectionTypeValue, actions.SetCollectionType),
        auditTime(10),
        withLatestFrom(this._store.pipe(select(selectors.getCollectionType))),
        switchMap(([, state]) => {
            Utils.Storage.set(OLO.Enums.USER_STORAGE.COLLECTION_TYPE, state);

            return [];
        }),
    );

    @Effect() public showDineInModalOnCollectionTypeSet$: Observable<Action> = this._actions$.pipe(
        ofType(actions.SetCollectionType),
        withLatestFrom(
            this._store.pipe(select(selectors.currentLocationNoByRoute)),
            this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
            this._store.pipe(select(selectors.getCartLocationNo)),
            this._store.pipe(select(selectors.getCartOrderTypeId)),
            this._store.pipe(select(selectors.getCurrentRouteQueryParams)),
            this._store.pipe(select(selectors.isModalTypeOpen('dine-in')))
        ),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        switchMap(([{ orderTypeId, tableNo },
            locationNo,
            isLocationDetailsPage,
            cartLocationNo,
            cartOrderTypeId,
            queryParams,
            isDineInModalOpen]) => {
            if (isDineInModalOpen) return [];
            const isOnLocationDetailsPage: boolean = locationNo && isLocationDetailsPage;
            const cartLocationIsCurrentLocationOrNotSet: boolean = cartLocationNo === null || locationNo === cartLocationNo;
            const cartOrderTypeIdIsCurrentOrderTypeOrNotSet: boolean = cartOrderTypeId === null || orderTypeId === cartOrderTypeId;
            if (isOnLocationDetailsPage && cartLocationIsCurrentLocationOrNotSet && cartOrderTypeIdIsCurrentOrderTypeOrNotSet) {
                if (this._isOrderTypeDineIn(orderTypeId)) {
                    this._showDineInModal(orderTypeId, locationNo, tableNo || queryParams?.tableNo || null);
                }
            }

            return [];
        }),
    );

    @Effect() public onCurrentLocationEnterCheckDineInQueryParams$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CurrentLocationSet),
        auditTime(1000),
        withLatestFrom(
            this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
            this._store.pipe(select(selectors.getCurrentRouteQueryParams)),
            this._store.pipe(select(selectors.getCartOrderTypeId)),
            this._store.pipe(select(selectors.getCartLocationNo)),
            this._store.pipe(select(selectors.getCollectionType)),
        ),
        filter(([, isLocationDetailsPage]) => isLocationDetailsPage === true && location !== null),
        switchMap(([{ locationNo }, , queryParams, cartOrderTypeId, cartLocationNo, collectionType]) =>
            this._store.pipe(
                select(selectors.getCollectionTypesListForLocation(locationNo)),
                filter((c) => c?.length > 0),
                take(1),
                withLatestFrom(this._store.pipe(select(selectors.isModalTypeOpen('dine-in')))),
                switchMap(([availableCollections, isDineInModalOpen]) => {
                    const foundDineInCollection = availableCollections.find((obj) => obj.GroupType === OLO.Enums.COLLECTION_TYPE.DINE_IN);
                    if (isDineInModalOpen) {

                        return [];
                    }
                    if (!queryParams?.orderTypeId) {
                        const foundActiveForLocation = availableCollections?.find((obj) => obj.IsActive);
                        const foundDineInCollectionAndLocationNoIsCartLocationNo =
                            foundDineInCollection && foundDineInCollection.IsActive && (locationNo === cartLocationNo || cartLocationNo === null);
                        if (!foundActiveForLocation && availableCollections?.length) {

                            return [actions.PatchOrderTypeIdCollectionTypeValue({ orderTypeId: availableCollections[0]?.OrderTypeIds[0] })];
                        } else if (foundDineInCollectionAndLocationNoIsCartLocationNo) {
                            this._showDineInModal(cartOrderTypeId ?? foundDineInCollection.OrderTypeIds[0], locationNo, collectionType.tableNo ?? null);
                        }

                        return [];
                    }
                    const orderTypeId: number = +queryParams.orderTypeId;

                    if (!foundDineInCollection || !foundDineInCollection.OrderTypeIds.includes(orderTypeId)) {

                        return [];
                    }
                    this._store.dispatch(actions.PatchOrderTypeIdCollectionTypeValue({ orderTypeId })); ;

                    this._showDineInModal(orderTypeId, locationNo, queryParams?.tableNo || null);

                    return [];
                }),
            ),
        ),
    );

    @Effect() public setupCurrentCollectionTypeBasedOnRoutingParams$: Observable<Action> = this._actions$.pipe(
        ofType(actions.ROUTER_NAVIGATED),
        switchMap(() => this._store.pipe(select(selectors.getHistoryRoute))),
        switchMap((routerHistory) => (!routerHistory.length ? this._store.pipe(select(selectors.getCollectionTypeOrderTypeIdFromCurrentRoute)) : [])),
        withLatestFrom(this._store.pipe(select(selectors.getCollectionType))),
        switchMap(([orderTypeIdFromRoute, collectionType]) => {
            const collectionTypeHelper = new Utils.CollectionTypeHelper(this._config.collectionTypes);
            const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

            if (typeof orderTypeIdFromRoute === 'number' && !!collectionTypeHelper.hasAnyCollectionTypeConfigOrderTypeId(orderTypeIdFromRoute)) {
                return of(actions.SetCollectionType(new CollectionTypeParams(orderTypeIdFromRoute)));
            }
            const orderTypeIdFromUrl = +urlParams.get('orderTypeId');
            const orderTypeIdFromCollectionTypeDefaultConfig = collectionTypeHelper.getOrderTypeIdFromDefaultCollectionType();
            const collectionTypeConfigByUrlOrderTypeId = collectionTypeHelper.getCollectionTypeByOrderTypeId(orderTypeIdFromUrl);

            if (orderTypeIdFromUrl && collectionTypeConfigByUrlOrderTypeId) {
                return of(actions.SetCollectionType(new CollectionTypeParams(orderTypeIdFromUrl)));
            }

            if (!collectionType.orderTypeId) {
                return of(actions.SetCollectionType(new CollectionTypeParams(orderTypeIdFromCollectionTypeDefaultConfig, collectionType.address, collectionType.tableNo)));
            }

            return [];
        })
    );

    private _showDineInModal(orderTypeId: number, locationNo: number, tableNo: string = null): void {
        this._modalsService.show({
            type: 'dine-in',
            locationNo,
            params: {
                tableNo,
                buzzer: this._isBuzzerDineInType(orderTypeId),
                img: this._serveDineInImageBasedOnOrderType(orderTypeId),
                title: this._getDineInTitle(orderTypeId),
                preTitle: this._getDineInPreTitle(orderTypeId),
                description: this._getDineInDescription(orderTypeId, tableNo),
            },
        });
    }

    private _getDineInTitle(orderTypeId: number): string {
        if (this._isTableNoDineInType(orderTypeId)) return this._tableTitle;

        return null;
    }

    private _getDineInPreTitle(orderTypeId: number): string {
        if (this._isBuzzerDineInType(orderTypeId)) return this._buzzerPreTitle;
        if (this._isTableNoDineInType(orderTypeId)) return this._tablePreTitle;

        return null;
    }

    private _getDineInDescription(orderTypeId: number, tableNo: string = null): string[] {
        if (this._isBuzzerDineInType(orderTypeId)) return this._buzzerDescription;
        if (this._isTableNoDineInType(orderTypeId)) return tableNo ? this._tableDescriptionSelected : this._tableDescription;

        return null;
    }

    private _serveDineInImageBasedOnOrderType(orderTypeId: number | string): string {
        if (this._isBuzzerDineInType(orderTypeId)) {
            return this._buzzerImg;
        }

        if (this._isTableNoDineInType(orderTypeId)) {
            return this._tableImg;
        }

        return null;
    }

    private _isBuzzerDineInType(orderTypeId: number | string): boolean {
        if (!this._dineInBuzzerId) return false;

        return +orderTypeId === this._dineInBuzzerId;
    }

    private _isTableNoDineInType(orderTypeId: number | string): boolean {
        if (!this._dineInTableNoId) return false;

        return +orderTypeId === this._dineInTableNoId;
    }

    private _isOrderTypeDineIn(orderTypeId: number): boolean {
        if (!orderTypeId) return false;
        if (this._collectionTypeDeliveryConfig?.enabled === true) {
            return orderTypeId === this._dineInBuzzerId || orderTypeId === this._dineInTableNoId;
        }

        return false;
    }

    constructor(
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _modalsService: Services.ModalsService,
    ) {}
}
