import { createAction, props } from '@ngrx/store';

export const AlertMessageOpen = createAction(
    '[Alert message] Open',
    props<{ message: OLO.State.AlertMessages.AlertMessage; }>(),
);

export const AlertMessageClose = createAction(
    '[Alert message] Close',
    props<{ id: number; }>(),
);

export const AlertMessageEdit = createAction(
    '[Alert message] Edit',
    (id: number, options: OLO.State.AlertMessages.AlertMessage = {}) => ({ id, options })
);

export const AlertMessageSetBulk = createAction(
    '[Alert message] set bulk',
    (messages: OLO.State.AlertMessages.AlertMessage[] = []) => ({ messages }),
);
