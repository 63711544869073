import { createAction, props } from '@ngrx/store';

export const geolocationPositionRequest = createAction(
    '[Geolocation] Request position',
);

export const geolocationPositionSuccessRequest = createAction(
    '[Geolocation] Success request position',
    props<{ payload: GeolocationCoordinates; }>(),
);

export const geolocationPositionErrorRequest = createAction(
    '[Geolocation] Error request position',
    props<{ ex?: any; }>(),
);
