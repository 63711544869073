import { createSelector } from '@ngrx/store';

import { isMemberDataValid } from '@shared/state/members/selectors';

import { cartIsValidForPostingOnlineOrder } from '@shared/state/cart/selectors';
import { orderIsValidForPostingOnlineOrder } from '@shared/state/onlineOrder/selectors';

export const canPostOnlineOrder = createSelector(
    isMemberDataValid,
    cartIsValidForPostingOnlineOrder,
    orderIsValidForPostingOnlineOrder,
    (isMemberValid, isCartValid, isOrderDataValid) => isMemberValid && isCartValid && isOrderDataValid,
);
