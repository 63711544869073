import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { isMemberAuthorizedJWT, getCurrentMember, loyaltyProducts } from '@shared/state/members/selectors';
import { getAllValidLoyaltyPrograms } from '@shared/state/loyaltyProductPrograms/selectors';
import { getLoyaltyPrograms } from '@shared/state/loyaltyPrograms/selectors';

export const getLoyaltyProgramProductItemsForCurrentMember = createSelector(
    isMemberAuthorizedJWT,
    getCurrentMember,
    loyaltyProducts,
    getAllValidLoyaltyPrograms,
    getLoyaltyPrograms,
    (isAuthorized, member, stateProducts, programs, loyaltyPrograms) => {
        if (!isAuthorized || !member || programs?.length === 0) return null;

        return (
            loyaltyPrograms.data?.reduce((acc, loyaltyProgram) => {
                const foundProgram = programs?.find((obj) => obj.Id === loyaltyProgram.LoyaltyProgramId);
                if (!foundProgram) return acc;

                const foundProduct: OLO.DTO.GetLoyaltyProductProgramTrackingBusinessModel = stateProducts?.data?.find(
                    (product) => product.MemberId === member.UserId && product.LoyaltyProductProgramId === loyaltyProgram.LoyaltyProgramId,
                );

                return [
                    ...acc,
                    {
                        program: { ...foundProgram, ProgramName: loyaltyPrograms.data.find((program) => program.LoyaltyProgramId === loyaltyProgram.LoyaltyProgramId).ProgramName },
                        product: foundProduct || {
                            CurrentProductCount: 0,
                            Id: null,
                            LoyaltyProductProgramId: loyaltyProgram.LoyaltyProgramId,
                            MemberCardNumber: +member.MemberCardNumber,
                            MemberId: member.UserId,
                            PLU: foundProgram.PLU,
                            ProductDescription: foundProgram.ProductDescription,
                            ProductIssuedCount: 0,
                            ProgramName: foundProgram.ProgramName,
                        },
                        codeName: new Utils.LoyaltyProgramCodeName(loyaltyProgram.Type).getProgramCodeName(),
                        displayOrder: loyaltyProgram.DisplayOrder,
                        isLoading: stateProducts.isDownloading,
                    },
                ].sort((a, b) => a.displayOrder - b.displayOrder);
            }, [] as OLO.Ordering.LoyaltyProgramProductItemModel[]) || null
        );
    },
);
