export class GooglePayPaymentProviderMapper {
    public static mapGooglePayPaymentPaymentResponse(paymentData: google.payments.api.PaymentMethodData): OLO.DTO.GooglePaymentDataResponse {
        const tokenJson = paymentData.tokenizationData && JSON.parse(paymentData.tokenizationData.token);
        const intermediateSigningKeyJson = tokenJson
                                && tokenJson.intermediateSigningKey.signedKey
                                && JSON.parse(tokenJson.intermediateSigningKey.signedKey) || null;
        const signedMessageJson = tokenJson && tokenJson.signedMessage && JSON.parse(tokenJson.signedMessage) || null;

        return {
            type: paymentData.type,
            description: paymentData.description,
            info: paymentData.info && {
                cardDetails: paymentData.info.cardDetails,
                cardNetwork: paymentData.info.cardNetwork,
            },
            tokenizationData: paymentData.tokenizationData && {
                type: paymentData.tokenizationData.type,
                token: paymentData.tokenizationData.token,
                tokenJson: tokenJson && {

                    signature: tokenJson.signature,
                    protocolVersion: tokenJson.protocolVersion,
                    signedMessage: tokenJson.signedMessage,
                    signedMessageJson: signedMessageJson && {
                        encryptedMessage: signedMessageJson.encryptedMessage,
                        ephemeralPublicKey: signedMessageJson.ephemeralPublicKey,
                        tag: signedMessageJson.tag,
                    } || null,
                    intermediateSigningKey: tokenJson.intermediateSigningKey && {
                        signatures: tokenJson.intermediateSigningKey.signatures,
                        signedKey: tokenJson.intermediateSigningKey.signedKey,
                        signedKeyJson: intermediateSigningKeyJson && {
                            keyExpiration: intermediateSigningKeyJson.keyExpiration,
                            keyValue: intermediateSigningKeyJson.keyValue,
                        } || null,
                    } || null,
                },
            } || null
        };
    }

    public static mapPaymentResponseToPayData(response: OLO.DTO.GooglePaymentDataResponse): OLO.DTO.GooglePayPaymentData {
        return response && {
            IntermediateSigningKey: {
                Signatures: response?.tokenizationData?.tokenJson?.intermediateSigningKey?.signatures || null,
                SignedKey: response?.tokenizationData?.tokenJson?.intermediateSigningKey?.signedKey || null,
            },
            ProtocolVersion: response?.tokenizationData?.tokenJson?.protocolVersion,
            Signature: response.tokenizationData.tokenJson.signature,
            SignedMessage: response.tokenizationData.tokenJson.signedMessage
        } || null;
    }

    public static mapDirectResponseToPayData(
        paymentData: google.payments.api.PaymentMethodData
    ): OLO.DTO.GooglePayPaymentData {
        return GooglePayPaymentProviderMapper
            .mapPaymentResponseToPayData(
                GooglePayPaymentProviderMapper
                    .mapGooglePayPaymentPaymentResponse(paymentData)
            );
    }
}
