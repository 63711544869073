import { createSelector } from '@ngrx/store';

import { getSelectedOrderType } from '@shared/state/onlineOrder/selectors';
import { getFilteredOrderTypesForCartsLocationByGroup } from '../getFilteredOrderTypesForCartsLocationByGroup';

export const showOrderTypeSection = () =>
    createSelector(getFilteredOrderTypesForCartsLocationByGroup(), getSelectedOrderType, (options, orderTypeExtended) => {
        let isRequired: boolean = false;

        options?.forEach((option) => option.Details.forEach((detail) => (detail.IsRequired ? (isRequired = true) : null)));

        return options?.length > 1 || isRequired || orderTypeExtended?.Disclaimers?.length > 0 || orderTypeExtended?.Details?.length > 0;
    });
