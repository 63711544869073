import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';

import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';


@Injectable()
export class LoyaltyIntroductionPagesEffects {
    @Effect() public requestIntroductionPages$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LoyaltyIntroductionPagesRequest
            ),
            switchMap(() => this._loyaltyAppService.apiGetLoyaltyIntroductionPages()
                .pipe(
                    map(response => actions.LoyaltyIntroductionPagesSuccessRequest({ payload: response.Items.sort((a, b) => a.DisplayIndex - b.DisplayIndex) })),
                    catchError(ex => {
                        console.error('LoyaltyPagesRequest error', ex);

                        return of(actions.LoyaltyIntroductionPagesErrorRequest({ ex }));
                    })
                )
            )
        );

    constructor(
        private _actions$: Actions,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) { }
}
