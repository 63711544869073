import { createSelector } from '@ngrx/store';

import { getMemberState } from '../getMemberState';
import { isMemberLoading } from '../isMemberLoading';

export const isPaymentDisabledForMemberWithVendorServiceProviders = createSelector(
    getMemberState,
    isMemberLoading,
    (membersState, memberLoading) =>
        memberLoading || membersState.isDownloading || (membersState.isGuestModeEnabled && !membersState.guestData) || (!membersState.isGuestModeEnabled && !membersState.data),
);
