import { createAction, props } from '@ngrx/store';

export const StateSave = createAction(
    '[State] Save'
);

export const StateSaveSuccess = createAction(
    '[State] Save success'
);

export const StateSaveError = createAction(
    '[State] Save error'
);

export const StateRestore = createAction(
    '[State] Restore',
    props<{ setProps?: { [key: string]: any; }; }>(),
);

export const StateRestored = createAction(
    '[State] Restored',
    props<{ state: OLO.State; }>(),
);
