import * as io from 'io-ts';
import { string, nullableNumber, nullableString } from '@shared/core/types/common';
import { linkType } from '@shared/core/types/consts';


const rootRequiredDefinition: io.MixedObjectOmitted<OLO.Config.ConfigLink, 'id' | 'label'> = {
    url: string,
    name: linkType,
};

const rootOptionalDefinition: io.MixedObjectOmitted<OLO.Config.ConfigLink, keyof typeof rootRequiredDefinition> = {
    id: nullableNumber,
    label: nullableString,
};

export const linksSchema = io.array(
    io.intersection([
        io.type(rootRequiredDefinition),
        io.partial(rootOptionalDefinition)
    ])
);
