import { createSelector } from '@ngrx/store';

import { onlineOrderState } from '../root';

export const isOnlineOrderUpdatedSuccessfully = createSelector(
    onlineOrderState,
    onlineOrder => (
        onlineOrder.updateRequest.hasSucceeded
    )
);
