/* https://www.npmjs.com/package/crypto-js */
//
//  This service was created to handle our messed up API - no regular sign in,
//  no security tokens, no handlers for this scenario in our backend.
//  This is just a minimum to make it look like secured
//
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class CryptoService {
    public n: Navigator = window.navigator;
    public s: Screen = window.screen;

    private _simpleDictionary: { [key: string]: string[]; } = {
        '0': ['a', 'b', '2'],
        '1': ['c', 'd', '9'],
        '2': ['e', 'f', 'v'],
        '3': ['g', 'h', '0'],
        '4': ['i', 'j', '3'],
        '5': ['k', 'l', '4'],
        '6': ['m', 'n', 'y'],
        '7': ['o', 'p', '6'],
        '8': ['q', 'r', '7'],
        '9': ['s', 't', '8'],
        ':': ['5', '1', 'z', 'w', 'u'],
    };
    private _chars: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';

    private _getMachineStamp(): string {
        return `${this._getPrivateKey()}${this.n.userAgent}${this.n.vendor}${this.n.product}${this.n.productSub}${this.n.platform}
        ${this.s.width}${this.s.width + this.s.height}${this.s.height}${this.n.language}`.replace(/\s/gi, '');
    }

    private _getPrivateKey(simpleKey: boolean = false): string {
        return simpleKey ? 'simpleKey' : `${this.n.userAgent.toUpperCase()}${this.s.width * this.s.height + Math.floor(this.s.width / 2)}`;
    }

    public encrypt(str: string, simpleKey: boolean = false, encryption: 'AES' | 'MD5' | 'Rabbit' | 'RC4' | 'DES' | 'btoa' | null = 'AES'): string {
        try {
            if (encryption === null || !encryption) return this.simpleEncrypt(str);
            if (encryption === 'btoa') return window.btoa(str);

            if (!CryptoJS[encryption]) throw new Error('Encryption method not valid');
            const encrypted = CryptoJS[encryption].encrypt(str, this._getPrivateKey(simpleKey));
            const encryptedString = encrypted.toString();

            const decrypted = this.decrypt(encryptedString, simpleKey, encryption);

            if (str !== decrypted) {
                throw new Error('string corrupted');
            }

            return encryptedString;
        } catch (ex) {
            console.error('encrypt error', ex);

            return null;
        }

    }

    public decrypt(str: string, simpleKey: boolean = false, encryption: 'AES' | 'MD5' | 'Rabbit' | 'RC4' | 'DES' | 'btoa' | null = 'AES'): string {
        try {
            if (encryption === null || !encryption) return this.simpleDecrypt(str);
            if (encryption === 'btoa') return window.atob(str);
            if (!CryptoJS[encryption]) throw new Error('Encryption method not valid');
            const decrypted = CryptoJS[encryption].decrypt(str, this._getPrivateKey(simpleKey));

            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (ex) {
            // console.error('decrypt error', ex);
            return null;
        }
    }

    public hash(text: string): string {
        return CryptoJS.HmacSHA256(text, this._getPrivateKey()).toString();
    }

    public hashMachineStampKeyp(): string {
        return this.hash(this._getMachineStamp());
    }

    public generateMachineStampKey(): string {
        return this.encrypt(this._getMachineStamp());
    }

    public matchMachineStampKey(str: string): boolean {
        /* Will check if encrypted string matches real one */
        return this.decrypt(this.generateMachineStampKey()) === this.decrypt(str);
    }

    public simpleEncrypt(str: string, fakeInitChars: number = 7): string {
        let initial: string = '';
        for (let i = 0, j = fakeInitChars; i < j; i++) {
            const limit = this._chars.length - 1;
            const randomIndex = Math.floor(Math.random() * limit);
            initial += this._chars[randomIndex];
        }

        return str.split('').reduce((acc, letter) => {
            const arr = this._simpleDictionary[letter];
            const upperCase1: boolean = Math.floor(Math.random() * 1000) % 2 === 0;
            const upperCase2: boolean = Math.floor(Math.random() * 1000) % 2 === 0;
            const encryptedChar1 = arr[Math.floor(Math.random() * arr.length)];
            const encryptedChar2 = arr[Math.floor(Math.random() * arr.length)];

            return acc += (upperCase1 ? encryptedChar1.toUpperCase() : encryptedChar1) + (upperCase2 ? encryptedChar2.toUpperCase() : encryptedChar2);
        }, initial);
    }

    public simpleDecrypt(str: string, fakeInitChars: number = 7): string {
        const encryptedArr: string[] = str.split('').filter((a, index) => index >= fakeInitChars);

        return encryptedArr.reduce((acc, letter, index) => {
            if (index % 2 !== 0) return acc;

            return acc += Object.keys(this._simpleDictionary).find(key => this._simpleDictionary[key].includes(letter.toLowerCase()));
        }, '');
    }
}
