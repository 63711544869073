import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError, of } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { FatZebraPaymentProviderMapper } from '@shared/core/mappers/paymentProviders/fat-zebra.payment-provider.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class FatZebraPaymentProviderService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
    ) {
    }

    public requestCardToken(locationNo: number = null, defaultSettings: OLO.DTO.FatZebraSettingsResponse = null): Observable<OLO.DTO.FatZebraSettingsResponse> {
        if (!locationNo) {
            if (!defaultSettings) return throwError('No default settings provided for FatZebra payment provider');

            return of(defaultSettings)
                .pipe(
                    take(1),
                    map(response => ({
                        ...response,
                        Verification: response.Verification.toLocaleLowerCase(),
                    }))
                );
        }

        return this._getSettingsForLocation(locationNo);
    }

    protected _getSettingsForLocation(locationNo: number): Observable<OLO.DTO.FatZebraSettingsResponse> {
        return this.httpClient
            .get<APIv3.FatZebraSettingsResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/fatZebra/settings/${locationNo}`)
            .pipe(
                map((response: APIv3.FatZebraSettingsResponse) => FatZebraPaymentProviderMapper.mapGetSettingsForLocation(response)),
                catchError(ex => {
                    console.error('LocationNo not provided', ex);

                    return throwError(ex);
                })
            );
    }
}
