import { CollectionTypeHelper } from './collection-type-helper.utils';

export class LocationCollectionTypesChecker {
    private _pickupTypes: OLO.DTO.TerminalLocationOrderTypeModel[] = [];
    private _dineInTypes: OLO.DTO.TerminalLocationOrderTypeModel[] = [];
    private _deliveryTypes: OLO.DTO.TerminalLocationOrderTypeModel[] = [];
    private _cateringTypes: OLO.DTO.TerminalLocationOrderTypeModel[] = [];

    constructor(
        private _location: OLO.DTO.OnlineOrderingLocationBusinessModel,
        private _config: OLO.Config,
    ) {
        if (!_location) return;
        this._initFiltering();
    }

    private _initFiltering(): void {
        this._filterPickupTypes();
        this._filterDineInTypes();
        this._filterDeliveryTypes();
        this._filterCateringTypes();
    }

    private _filterPickupTypes(): void {
        const pickupConfig = new CollectionTypeHelper(this._config.collectionTypes).getPickupCollectionTypeConfig();
        if (pickupConfig?.enabled === true) {
            this._pickupTypes = this._location.OrderTypes
                .filter(orderType => pickupConfig?.orderTypeIds.includes(orderType.Id));
        }
    }

    private _filterDineInTypes(): void {
        const dineInConfig = new CollectionTypeHelper(this._config.collectionTypes).getDineInCollectionTypeConfig();
        if (dineInConfig?.enabled) {
            const buzzerId = dineInConfig?.dineInBuzzer?.orderTypeId;
            const tableId = dineInConfig?.dineInTable?.orderTypeId;

            this._location.OrderTypes.forEach(orderType => {
                if (buzzerId === orderType.Id || tableId === orderType.Id) {
                    this._dineInTypes.push(orderType);
                }
            });
        }
    }

    private _filterDeliveryTypes(): void {
        const deliveryConfig = new CollectionTypeHelper(this._config.collectionTypes).getDeliveryCollectionTypeConfig();
        if (deliveryConfig?.enabled) {
            this._deliveryTypes = this._location.OrderTypes
                .filter(orderType => deliveryConfig?.orderTypeIds.includes(orderType.Id));
        }
    }

    private _filterCateringTypes(): void {
        const cateringConfig = new CollectionTypeHelper(this._config.collectionTypes).getCateringCollectionTypeConfig();
        if (cateringConfig?.enabled === true) {
            this._cateringTypes = this._location.OrderTypes
                .filter(orderType => cateringConfig?.orderTypeIds.includes(orderType.Id));
        }
    }

    public getPickupIds(): number[] {
        return this._mapToId(
            this._pickupTypes
        );
    }

    public hasPickups(): boolean {
        return this.getPickupIds().length > 0;
    }

    public getDineInIds(): number[] {
        return this._mapToId(
            this._dineInTypes
        );
    }

    public hasDineIn(): boolean {
        return this.getDineInIds().length > 0;
    }

    public getDeliveryIds(): number[] {
        return this._mapToId(
            this._deliveryTypes
        );
    }

    public hasDelivery(): boolean {
        return this.getDeliveryIds().length > 0;
    }

    public getCateringIds(): number[] {
        return this._mapToId(
            this._cateringTypes
        );
    }

    public hasCatering(): boolean {
        return this.getCateringIds().length > 0;
    }

    public hasAnyTypes(): boolean {
        return this.hasDelivery()
            || this.hasPickups()
            || this.hasDineIn()
            || this.hasCatering();
    };

    public hasOnlyDineInType(): boolean {
        return !this.hasDelivery()
            && !this.hasPickups()
            && !this.hasCatering()
            && this.hasDineIn();
    };

    public getFirstMatchingOrderTypeIdForPickup(): Nullable<number> {
        const pickupConfig = new CollectionTypeHelper(this._config.collectionTypes).getPickupCollectionTypeConfig();

        return pickupConfig.orderTypeIds.find(pickupOrderTypeId => this.getPickupIds().includes(pickupOrderTypeId)) || null;
    }

    public getFirstMatchingOrderTypeIdForCatering(): Nullable<number> {
        const cateringConfig = new CollectionTypeHelper(this._config.collectionTypes).getCateringCollectionTypeConfig();

        return cateringConfig.orderTypeIds.find(cateringOrderTypeId => this.getCateringIds().includes(cateringOrderTypeId)) || null;
    }

    public getFirstMatchingOrderTypeIdForDineIn(): Nullable<number> {
        const dineInConfig = new CollectionTypeHelper(this._config.collectionTypes).getDineInCollectionTypeConfig();

        return dineInConfig.orderTypeIds.find(dineInOrderTypeId => this.getDineInIds().includes(dineInOrderTypeId)) || null;
    }

    public getFirstMatchingOrderTypeIdForDelivery(): Nullable<number> {
        const deliveryConfig = new CollectionTypeHelper(this._config.collectionTypes).getDeliveryCollectionTypeConfig();

        return deliveryConfig.orderTypeIds.find(deliveryOrderTypeId => this.getDeliveryIds().includes(deliveryOrderTypeId)) || null;
    }

    private _mapToId(collectionArr: OLO.DTO.TerminalLocationOrderTypeModel[]): number[] {
        return collectionArr.map(orderType => orderType.Id);
    }
}
