import { createSelector } from '@ngrx/store';

import { getWizzardMenuFlow } from '@shared/state/wizzard/selectors';
import { getMenuFlows } from '@shared/state/menuFlows/selectors';

export const getMenuFlowDetailsByWizzard = createSelector(getWizzardMenuFlow, getMenuFlows, (wizzardMenuFlow, menuFlows) => {
    if (!wizzardMenuFlow) return null;
    const { MenuFlowId, LocationNo } = wizzardMenuFlow;

    const menuFlow = menuFlows?.find((menuFlowObj) => menuFlowObj.MenuFlowId === MenuFlowId && menuFlowObj.LocationNo === LocationNo);

    return menuFlow?.data || null;
});
