import { createSelector } from '@ngrx/store';

import { getLocationDetails } from '../getLocationDetails';

import * as Utils from '@shared/core/utils';

export const isLocationOpenNowByOrderingTimeInfoObj = (locationNo: number, orderTypeId: number) =>
    createSelector(getLocationDetails(locationNo), (location) => {
        if (!location) {
            return null;
        }
        const foundObj = new Utils.LocationOrderingTimeInfo(location, orderTypeId).getOrderingTimeInfo();
        if (!foundObj) return false;
        const date = new Date();
        const currDay = date.getDay();

        const foundDay = foundObj.find((obj) => obj.DayOfWeek === currDay);
        if (!foundDay) {
            return false;
        }

        return Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(date), foundDay.OpeningTime, foundDay.ClosingTime, 'from');
    });
