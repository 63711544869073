import * as io from 'io-ts';
import { nullableNumber, boolean } from '@shared/core/types/common';

const memberCardDefinition: io.MixedObject<OLO.Config.Membership.MemberCard> = {
    minLength: nullableNumber,
    maxLength: nullableNumber,
};

const passwordRulesDefinition: io.MixedObject<OLO.Config.Membership.PasswordRules> = {
    minLength: nullableNumber,
    upperCaseCount: nullableNumber,
    lowerCaseCount: nullableNumber,
    digitsCount: nullableNumber,
    specialCharsCount: nullableNumber,
    allowWhiteSpaces: boolean,
};


const rootDefinition: io.MixedObject<OLO.Config.Membership> = {
    birthdayRewards: boolean,
    enableQuickSignInForReturningMembers: boolean,
    membersCanDeleteAccounts: boolean,
    defaultMemberGroupId: nullableNumber,
    minimumAgeToSignUp: nullableNumber,
    maximumAgeToSignUp: nullableNumber,
    memberCard: io.type(memberCardDefinition),
    passwordRules: io.type(passwordRulesDefinition),
};

export const membershipSchema = io.type(rootDefinition);
