// eslint-disable-next-line import/no-unassigned-import
import 'zone.js';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';

import { environment } from './environments/environment';
import { buildEnvironment } from './environments/environment.build';
import { ApplicationConfigBuilder } from '@shared/core/models/applicationConfig';
import * as Tokens from '@shared/core/tokens';
import { Log, I18n } from '@shared/core/utils';

import { AppModule } from './app/app.module';

import localePl from '@angular/common/locales/pl';
import localeAU from '@angular/common/locales/en-AU';
import localeNZ from '@angular/common/locales/en-NZ';
import localeES from '@angular/common/locales/es-US';

registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeAU, 'en-AU');
registerLocaleData(localeNZ, 'en-NZ');
registerLocaleData(localeES, 'es-US');

console.log(buildEnvironment);

if (environment.production) {
    enableProdMode();
}

(async function initApp() {
    try {
        const configRaw = await fetch('config.json').then(res => res.json());
        const config = new ApplicationConfigBuilder(configRaw)
            .build()
            .toDeepSealedJson();

        const staticTexts = await fetch(`assets/lang/${I18n.getLanguage(config.localization.locale)}.json`).then(res => res.json());
        let dynamicConfigProvider: any;

        if(buildEnvironment.environment === 'sut') {
            const provider = await import('../e2e/dynamic-config.provider').then(module => module.DynamicConfigProvider);
            dynamicConfigProvider = new provider(config).current;
        }

        await platformBrowserDynamic([
            {
                provide: Tokens.CONFIG_TOKEN,
                useValue: dynamicConfigProvider || config,
            },
            {
                provide: Tokens.STATIC_TEXT_TOKEN,
                useValue: staticTexts,
            },
        ]).bootstrapModule(AppModule);
    } catch (error) {
        console.error('Critical error during app initialization:', error);

        new Log().error(error);
    }
})();
