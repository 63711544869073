import { Arrays } from './arrays.utils';

export class Products {
    public static toSingleItemsByQuantity(items: OLO.DTO.MenuFlowProduct[]) {
        return items.map((product) => {
            let x = 0;
            let singleItem = [];
            while (x < product.Quantity) {
                singleItem.push({ ...product, Quantity: 1 });
                ++x;
            }

            return singleItem;
        })
            .reduce((prev, curr) => prev.concat(curr), []);
    }

    /**
     * Creates initial data to for futher calculations of menuFlow's products
     * @param {OLO.Enums.PRICE_MODE} specialPriceSelection
     */
    public static calculatePriceProperties(specialPriceSelection: OLO.Enums.PRICE_MODE): OLO.Ordering.PriceCalcDetails {
        const priceCalcDetails: OLO.Ordering.PriceCalcDetails = {
            priceProperty: 'default',
            sortDirection: null,
        };

        switch (specialPriceSelection) {
            case OLO.Enums.PRICE_MODE.MOST_EXPENSIVE:
                priceCalcDetails.priceProperty = 'OverridedPrice';
                priceCalcDetails.sortDirection = 'desc';
                break;

            case OLO.Enums.PRICE_MODE.MOST_EXPENSIVE_ORIGINAL_PRICE:
                priceCalcDetails.priceProperty = 'OriginalPrice';
                priceCalcDetails.sortDirection = 'desc';
                break;

            case OLO.Enums.PRICE_MODE.LEAST_EXPENSIVE:
                priceCalcDetails.priceProperty = 'OverridedPrice';
                priceCalcDetails.sortDirection = 'asc';
                break;

            case OLO.Enums.PRICE_MODE.LEAST_EXPENSIVE_ORIGINAL_PRICE:
                priceCalcDetails.priceProperty = 'OriginalPrice';
                priceCalcDetails.sortDirection = 'asc';
                break;

            case OLO.Enums.PRICE_MODE.OFF:
            default:
                break;
        }

        return priceCalcDetails;
    }

    /**
     * Based on provided parameters, creates calculated price object list
     * @param {OLO.Ordering.PriceCalcProperties} propName
     * @param {'asc' | 'desc'} direction
     * @param {OLO.Ordering.PageSpecialDetails} pageDetails
     * @param {OLO.DTO.MenuFlowProduct[]} items
     */
    public static calculatePriceObjects(propName: string,
        direction: string = 'desc',
        pageDetails: OLO.Ordering.PageSpecialDetails,
        items: OLO.DTO.MenuFlowProduct[]): OLO.Ordering.ItemPriceObj[] {
        let processedItems: number = 0; /* keeping this value. Might be useful if we will have to ommit some products (with property that excludes from special pricing) */

        return Products.toSingleItemsByQuantity(items)
            .sort(Arrays.sortByProp(propName, direction))
            .reduce((prev, next, index) => {
                let productUnitPrice: number = 0;
                processedItems = index;

                //
                //  UnitPrice - main price calculations. Other values will base on this.
                //
                switch (true) {
                    case propName === 'default':
                        productUnitPrice = next.OverridedPrice !== null ? next.OverridedPrice : next.OriginalPrice;
                        break;
                    case processedItems < pageDetails.SpecialPriceQuantity:
                        productUnitPrice = pageDetails.SpecialPrice;
                        break;

                    case propName === 'OriginalPrice' || propName === 'OverridedPrice':
                        productUnitPrice = next[propName];
                        break;

                    default:
                        throw new Error('Unhandeled price calulcation scenario');
                }

                //
                //  Build Models.IItemPriceObj[]
                //
                const sourceProduct: OLO.DTO.MenuFlowProduct = items
                    .find(rawItem => rawItem.ProductId === next.ProductId && rawItem.PageProductIdentifier === next.PageProductIdentifier);
                const existingItemIndex: number = prev.findIndex(previousObj => previousObj._fromItem === sourceProduct);

                /* Add item to previous */
                if (existingItemIndex === -1) {
                    return [
                        ...prev,
                        {
                            ProductId: next.ProductId,
                            MenuFlowId: next.MenuFlowID,
                            PageProductIdentifier: next.PageProductIdentifier,
                            ProductName: next.ProductName,
                            Quantity: 1,
                            UnitPrice: productUnitPrice,
                            TotalValue: productUnitPrice,
                            _fromItem: sourceProduct,
                        }
                    ];
                }

                //
                //  Update existing object Models.IItemPriceObj.
                //  This is very important, to updated all values based on calculated productUnitPrice!
                //
                const prevObj: OLO.Ordering.ItemPriceObj = prev[existingItemIndex];

                const newQuantity: number = prevObj.Quantity + 1;
                const newTotal: number = prevObj.TotalValue + productUnitPrice;
                const newUnitPrice: number = newTotal / newQuantity;

                prevObj.Quantity = newQuantity;
                prevObj.TotalValue = newTotal;
                prevObj.UnitPrice = newUnitPrice;

                return prev;
            }, []);
    }

    /**
     * Based on pageDetails and provided items, creates price object for each item
     * @param {OLO.Ordering.PageSpecialDetails} pageDetails menuFlow page
     * @param {OLO.DTO.MenuFlowProduct[]} items menuFlow products
     */
    public static generateCalculatedPriceObjects(pageDetails: OLO.Ordering.PageSpecialDetails, items: OLO.DTO.MenuFlowProduct[]): OLO.Ordering.ItemPriceObj[] {
        const { priceProperty, sortDirection } = Products.calculatePriceProperties(pageDetails.SpecialPriceSelection);

        return Products.calculatePriceObjects(priceProperty, sortDirection, pageDetails, items);
    }

    /**
     * Extract product price - either Overrided or OriginalPrice
     * @param {OLO.Ordering.MenuFlowItemPageProduct} item product
     */
    public static extractProductPrice<T extends OLO.Ordering.MenuFlowItemPageProduct>(item: T): number {
        return item.OverridedPrice ?? item.OriginalPrice;
    }

    /**
     * From a given collection of product items, extract the cheapest one
     * @param items collection of products
     */
    public static getProductWithTheLowestPrice<T extends OLO.Ordering.MenuFlowItemPageProduct>(...items: T[]): T {
        return items.reduce((cheapest, next) => {
            if(!cheapest) return next;

            const prevPrice = Products.extractProductPrice(cheapest);
            const nextProductPrice = Products.extractProductPrice(next);

            return nextProductPrice < prevPrice ? next : cheapest;
        }, null);
    }

    /**
     * From all online menu pages items, extract the one which contain a product
     * @param product product it can be simple product or menuflow
     * @param onlineMenuPages collection of online menu pages
     */
    public static getOnlineMenuPageContaingProduct(product: OLO.State.Cart.CartMenuFlowExtended | (OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended),
        onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[], isMenuFlow: boolean = false): OLO.DTO.OnlineMenuPageResponseModel {
        return onlineMenuPages?.find(page => page.Products.some(
            pageProduct => isMenuFlow
                ? product.MenuFlowId === pageProduct.MenuFlowId
                : (product as OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended).Plu === pageProduct.Plu));
    }

    /**
     * From all online menu pages items, return the index in array of products
     * @param product product it can be simple product or menuflow
     * @param onlineMenuPages collection of online menu pages
     */
    public static getProductIndexInOnlineMenuPages(product: OLO.State.Cart.CartMenuFlowExtended | (OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended),
        onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[], isMenuFlow: boolean = false): number {
        return this.getOnlineMenuPageContaingProduct(product, onlineMenuPages, true)?.Products.findIndex(
            pageProduct => isMenuFlow
                ? (product.MenuFlowId === pageProduct.MenuFlowId)
                : (product as OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended).Plu === pageProduct.Plu);
    }

    /**
     * From all surcharges, return the whole shipping cost
     * @param surcharges surcharge items
     */
    public static calculateShipping(surcharges: APIv3.OnlineOrderSurchargeBusinessModel[]): number {
        return surcharges.reduce((sum, surcharge) => sum + surcharge.Value, 0);
    }
}
