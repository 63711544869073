import { enumType } from '@shared/core/types/common';

enum CHECKOUT_STEP {
    INITIAL_STEP = OLO.Enums.CHECKOUT_STEP.INITIAL_STEP,
    MEMBER_DATA_SET = OLO.Enums.CHECKOUT_STEP.MEMBER_DATA_SET,
    PICKUP_TYPE_SET = OLO.Enums.CHECKOUT_STEP.PICKUP_TYPE_SET,
    PAYMENT_METHOD_SET = OLO.Enums.CHECKOUT_STEP.PAYMENT_METHOD_SET,
}

export const checkoutStepEnum = enumType('CHECKOUT_STEP', CHECKOUT_STEP);
