import { createSelector } from '@ngrx/store';
import { creditCardsState } from '../root';

export const isCardSelected = (creditCard: OLO.Members.MemberCreditCardDetails) => createSelector(
    creditCardsState,
    creditCards => {
        if (!creditCard) return false;

        const activeId: number = creditCards.activeCardId;
        const activeToken: string = creditCards.activeCardToken;

        if (creditCard.Id && activeId) {
            return creditCard.Id === activeId;
        }

        if (creditCard.Token && activeToken) {
            return creditCard.Token === activeToken;
        }

        return creditCard.Id === null && activeId === null && creditCard.Token === null && activeToken === null;
    }
);
