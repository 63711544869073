import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getCartSimpleItemByTempId } from '../getCartSimpleItemByTempId';

export const getCartSimpleItemSpecialInstructions = (tempId: number) =>
    createSelector(getCartSimpleItemByTempId(tempId), (simpleProduct) => {
        if (!simpleProduct) return null;

        return Utils.Items.generateCartMenuSpecialInstructions(simpleProduct);
    });
