export class JWTMapper {
    public static mapReqestJWToken(login: OLO.DTO.JWTokenRequest): { Email: string; Password: string; } {
        return login ? {
            Email: login.Email,
            Password: login.Password,
        } : null;
    }

    public static mapPostJWTokenResponse(token: APIv3.JwtTokenResponse): OLO.DTO.JWTokenObject {
        return token ? {
            AccessToken: token.AccessToken,
            RefreshToken: token.RefreshToken,
        } : null;
    }
}
