import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-programs.actions';

const initialState: OLO.State.LoyaltyPrograms[] = [];

export const loyaltyProgramsReducerFn = createReducer<OLO.State.LoyaltyPrograms[]>(
    initialState,
    on(actions.LoyaltyProgramsReset, () => []),
    on(actions.LoyaltyProgramsRequest, (state) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.LoyaltyProgramsSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        downloadedDate: Date.now(),
        data: action.payload,
    })),
    on(actions.LoyaltyProgramsErrorRequest, (state) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
);

export function loyaltyProgramsReducer(state: OLO.State.LoyaltyPrograms[] | undefined, action: Action) {
    return loyaltyProgramsReducerFn(state, action);
}
