import { createSelector } from '@ngrx/store';

import { modalsState } from '../root';

export const isLastModalHiding = createSelector(
    modalsState,
    (modals: OLO.State.Modals) => {
        const count: number = modals.length;
        if (count !== 1) {
            return false;
        }

        return modals[0]?.animate === OLO.Components.Modals.MODAL_ANIMATION.OUT;
    }
);
