import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getCart } from '@shared/state/cart/selectors';
import { getLocationDetails } from '@shared/state/locations/selectors';

export const getOrderingTimeInfoForCartPickupLocationNo = (locationNo: number) =>
    createSelector(
        getCart,
        getLocationDetails(locationNo),
        (cart, locations) =>
            new Utils.LocationOrderingTimeInfo(locations, cart.orderTypeId).getOrderingTimeInfo()?.find(Utils.Pickups.datesMatchByDayCallback(cart.pickupTime?.DateLocalISO)) ||
            null,
    );
