import * as io from 'io-ts';
import { nullableArrayOfStrings, nullableAnyObject, nullableNumber, string } from '@shared/core/types/common';

const tagManagerDefinition: io.MixedObject<OLO.Config.Google.TagManager> = {
    containerIDs: io.array(string)
};

const mapsRequiredDefinition: io.MixedObjectPicked<OLO.Config.Google.Maps, 'apiKey'> = {
    apiKey: string,
};

const mapsOptionalDefinition: io.MixedObjectOmitted<OLO.Config.Google.Maps, 'apiKey'> = {
    libraries: nullableArrayOfStrings,
    types: nullableArrayOfStrings,
    defaults: io.unknown,
    componentRestrictions: nullableAnyObject,
    searchDistance: nullableNumber,
    markerWidth: nullableNumber,
    markerHeight: nullableNumber,
    markerSelectedWidth: nullableNumber,
    markerSelectedHeight: nullableNumber,
    markerDownsizeFactor: nullableNumber,
};

const rootDefinition: io.MixedObject<OLO.Config.Google> = {
    maps: io.intersection([
        io.type(mapsRequiredDefinition),
        io.partial(mapsOptionalDefinition)
    ]),
    tagManager: io.type(tagManagerDefinition),
};


export const googleSchema = io.partial(rootDefinition);
