export class DetailedOrderTotalsReceiptCalculator implements OLO.Ordering.OrderSummary {
    public Subtotal: number = 0;
    public Tax: number = 0;
    public Total: number = 0;
    public Surcharges: OLO.DTO.OrderSurchargeDefinition[] = [];
    public Medias: OLO.DTO.OnlineOrderMediaModel[] = [];

    constructor(
        private _order: OLO.DTO.OnlineOrderDetailedBusinessModel
    ) {
        if (!_order) return;

        this._calculateValues();
    }

    private _calculateValues(): void {
        this.Surcharges = this._order.Surcharges;
        this.Total = this._order.TotalGrossValue;
        this.Subtotal = this._order.TotalNettValue;
        this.Tax = this._order.TotalTaxes.reduce((acc, tax) => acc += tax.Value, 0);
        this.Medias = this._order.Medias;
    }
}
