import * as io from 'io-ts';
import { nullableNumber, boolean } from '@shared/core/types/common';

const rootDefinition: io.MixedObject<OLO.Config.Modals> = {
    animateTimeout: nullableNumber,
    bgClickClose: boolean,
    preventGlobalScroll: boolean,
};

export const modalsSchema = io.type(rootDefinition);
