import { createSelector } from '@ngrx/store';
import { locationsState } from '../root';

/**
 * Gets location model by locationNo
 * @param {number} locationNo
 * @returns {OLO.DTO.OnlineOrderingLocationBusinessModel} location model
 */
export const getLocationDetails = (locationNo: number) =>
    createSelector(
        locationsState,
        (locations) => (!locations?.data ? null : locations?.data.find((location) => location.LocationNo === locationNo))
    );
