import * as Utils from '@shared/core/utils';

export class CreditCardsMapper {
    public static mapAddMemberCardRequest(card: OLO.DTO.CreatePaymentAccountRequest): APIv3.MembersCreateMemberCard.Parameters.Request {
        return card ? {
            Token: card.Token,
            NiceName: card.NiceName,
            ExpirationDate: Utils.CreditCards.dateToApiFormat(card.ExpirationDate),
            CardType: card.CardType,
            PaymentProvider: card.PaymentProvider,
            IsDefault: card.IsDefault,
            FatZebraToken: card.FatZebraToken ? {
                r: card.FatZebraToken.r,
                v: card.FatZebraToken.v,
            } : null,
            LocationNo: card.LocationNo,
        } : null;
    }

    public static mapAddMemberCardResponse(memberCard: APIv3.MembersCreateMemberCard.Responses.$200): OLO.DTO.CreatePaymentAccountResponse {
        return memberCard ? {
            Id: memberCard.Id,
            DisplayName: memberCard.DisplayName,
            CardType: memberCard.CardType,
            ExpirationDate: memberCard.ExpirationDate,
            IsDefault: memberCard.IsDefault,
        } : null;
    }

    public static mapGetCardItems(cardItems: APIv3.MembersGetMemberCards.Responses.$200): OLO.DTO.PaginatedListPaymentAccountsListResponse {
        return cardItems ? {
            PageNo: cardItems.PageNo,
            PageSize: cardItems.PageSize,
            Items: cardItems.Items ? cardItems.Items.map((item: APIv3.PaymentAccountsListResponse) => ({
                Id: item.Id,
                ExpirationDate: item.ExpirationDate,
                DisplayName: item.DisplayName,
                CardType: item.CardType,
                IsDefault: item.IsDefault,
            })) : null,
        } : null;
    }

    public static mapRemoveMemberCard(removeMemberCard: APIv3.MembersRemoveMemberCard.Responses.$200): boolean {
        return removeMemberCard ? removeMemberCard.IsSuccess : null;
    }
}
