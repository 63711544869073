import { createSelector } from '@ngrx/store';
import { dietaryTagsImagesState } from '../root';

export const getDietaryTagsByTags = (tagParams: OLO.DTO.TagModel[]) =>
    createSelector(dietaryTagsImagesState, (images) =>
        images.reduce((acc, tagImg) => {
            const tag = tagParams.find((obj) => obj.Id === tagImg.Id);
            if (tag) {
                return [...acc, { ...tagImg, Name: tag.Name }];
            }

            return acc;
        }, [] as OLO.State.DietaryTagImage[]),
    );
