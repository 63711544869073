import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DeliveryService {
    private _anotherAddressClicked: Subject<void> = new Subject();
    public anotherAddressClicked$: Observable<void> = this._anotherAddressClicked.asObservable();

    constructor() {}

    public handleTryAnotherAddressClick(): void {
        this._anotherAddressClicked.next(null);
    }
}
