import * as io from 'io-ts';

import { collectionTypeEnum, tickIndicatorType, orderTypeIdsArray } from '@shared/core/types/consts';
import {
    validString,
    combinedTypes,
    looseTupleType,
    boolean,
    nullableString,
    arrayOfStrings,
    arraySize,
    number,
    numberInRange,
    nnumberInRange,
    nullable
} from '@shared/core/types/common';

const commonDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.Common> = {
    orderTypeIds: orderTypeIdsArray,
    collectionTypeId: collectionTypeEnum,
    enabled: boolean,
    displayName: validString,
    displayIndex: number,
    url: nullableString,
};

const timeControlDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.TimeControl> = {
    startBufferMins: numberInRange(0),
    endBufferMins: nnumberInRange(0),
    orderTimeoutBufferMins: numberInRange(0),
    nextTick: tickIndicatorType
};

const cateringDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.Catering> = {
    ...commonDefinition,
    ...timeControlDefinition
};

const pickupDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.Pickup> = {
    ...commonDefinition,
    ...timeControlDefinition
};

const deliveryModalDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.Delivery.AddressModal> = {
    modalPreTitle: validString,
    modalTitle: validString,
    modalDescription: arrayOfStrings,
    inputPlaceholder: validString,
    buttonText: validString
};

const deliveryDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.Delivery> = {
    ...commonDefinition,
    ...timeControlDefinition,
    displayAsTimespans: boolean,
    deliveryAddressModal: io.type(deliveryModalDefinition)
};

const dineInTableDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.DineIn.Table> = {
    orderTypeId: number,
    modalIcon: validString,
    modalPreTitle: validString,
    modalTitle: validString,
    modalDescription: arrayOfStrings,
    modalDescriptionSelected: arrayOfStrings,
};

const dineInBuzzerDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.DineIn.Buzzer> = {
    orderTypeId: number,
    modalIcon: validString,
    modalPreTitle: validString,
    modalDescription: arrayOfStrings,
};

const dineInDefinition: io.MixedObject<OLO.Config.CollectionTypesNext.DineIn> = {
    ...commonDefinition,
    dineInTable: nullable(io.type(dineInTableDefinition)),
    dineInBuzzer: nullable(io.type(dineInBuzzerDefinition)),
};

type PickupBrand = {
    readonly Pickup: unique symbol;
};
const pickupBranded = io.brand(
    io.type(pickupDefinition),
    (input): input is io.Branded<OLO.Config.CollectionTypesNext.Pickup, PickupBrand> => input.collectionTypeId === OLO.Enums.COLLECTION_TYPE.PICKUP,
    'Pickup'
);

type DeliveryBrand = {
    readonly Delivery: unique symbol;
};
const deliveryBranded = io.brand(
    io.type(deliveryDefinition),
    (input): input is io.Branded<OLO.Config.CollectionTypesNext.Delivery, DeliveryBrand> => input.collectionTypeId === OLO.Enums.COLLECTION_TYPE.DELIVERY,
    'Delivery'
);

type DineInBrand = {
    readonly DineIn: unique symbol;
};
const dineInBranded = io.brand(
    io.type(dineInDefinition),
    (input): input is io.Branded<OLO.Config.CollectionTypesNext.DineIn, DineInBrand> => input.collectionTypeId === OLO.Enums.COLLECTION_TYPE.DINE_IN,
    'DineIn'
);

type CateringBrand = {
    readonly Catering: unique symbol;
};
const cateringBranded = io.brand(
    io.type(cateringDefinition),
    (input): input is io.Branded<OLO.Config.CollectionTypesNext.Catering, CateringBrand> => input.collectionTypeId === OLO.Enums.COLLECTION_TYPE.CATERING,
    'Catering'
);

export const collectionTypesSchema = combinedTypes({
    types: [
        looseTupleType([cateringBranded, dineInBranded, deliveryBranded, pickupBranded]),
        arraySize(1, 4)
    ]
});
