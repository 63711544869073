export class Routes {
    /**
     * Checks if provided url starts with
     * `""` or `/` or `/?....`
     * @param {string} url
     * @returns {boolean} boolean
     */
    public static isMainPage(url: string): boolean {
        return url === '' || /(^(\?.{1}$)|(^(\/)(\?.+)?$)|(^\?.+$))/i.test(url);
    }
}
