import { createSelector } from '@ngrx/store';

import * as Statics from '@shared/core/statics';

import { getCurrentRoute } from '@shared/state/router/selectors';
import { getAppLocationMode } from '@shared/state/appSettings/selectors';

export const routeIsLocationsSearchPage = () =>
    createSelector(getCurrentRoute, getAppLocationMode, (route, locationMode) => {
        if (route === null || locationMode === null) return null;
        if (locationMode === OLO.Enums.APP_LOCATION_MODE.LOCATION) {
            return route.url === '/locations';
        }

        const config = new Statics.ConfigStatic().current;

        return route.url === `/${config.venue.name}`;
    });
