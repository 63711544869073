export class CollectionTypeItem {
    constructor(
        public Id: number,
        public Name: string,
        public OrderTypeIds: number[],
        public IsActive: boolean,
        public GroupType: OLO.Enums.COLLECTION_TYPE,
        public DispayIndex: number
    ) { }
}
