import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductsMapper } from '@shared/core/mappers/products.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class ProductsService {

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
    ) {
    }

    public getIngredients(...productIds: number[]): Observable<OLO.DTO.PaginatedListProductRecipeIngredientModel> {
        const generatedProductIds: string = productIds.map(productId => `products=${productId}`).join('&');

        return this.httpClient
            .get<APIv3.PaginatedListProductRecipeIngredientModel>(`${this.config.api.base}/products/Ingredients?${generatedProductIds}`)
            .pipe(
                map((response: APIv3.PaginatedListProductRecipeIngredientModel) => ProductsMapper.mapIngredientsGETResponse(response))
            );
    }

    public getIngredientsForLocation(locationNo: number, ...productIds: number[]): Observable<OLO.DTO.ProductWithIngredientsModel[]> {
        const generatedProductIds: string = productIds.map(productId => `productIDs=${productId}`).join('&');

        return this.httpClient
            .get<APIv3.ProductWithIngredientsModel[]>(`${this.config.api.base}/products/Ingredients/Location/${locationNo}?${generatedProductIds}`)
            .pipe(
                map((response: APIv3.ProductWithIngredientsModel[]) => ProductsMapper.mapIngredientsForLocationGETResponse(response, locationNo))
            );
    }

    public getIngredientsForLocationExtended(locationNo: number, ...productIds: number[]): Observable<APICommon.ProductLocationIngredientExtended[]> {
        return this.getIngredientsForLocation(locationNo, ...productIds)
            .pipe(
                map(response => response.map(productIngredient => {
                    if (productIngredient.Ingredients === null) return productIngredient as APICommon.ProductLocationIngredientExtended;

                    const ingredientsExtended: APICommon.ProductIngredientExtended[] = productIngredient.Ingredients.map(ingredient => {
                        const Modifiers: APICommon.IngredientModifierExtended[] = ingredient.Modifiers === null ? null : ingredient.Modifiers.map(modifier => ({
                            ...modifier,
                            IngredientPLU: ingredient.IngredientPLU,
                            _IsOptional: false, // ingredient.IsOptional, model has changed. There is no IsOptional flag
                            _ProductId: productIngredient.ProductID,
                            _LocationNo: locationNo,
                            _ProductPLU: productIngredient.PLU,
                        }));
                        const Swaps = ingredient.Swaps === null ? null : ingredient.Swaps.map(swap => ({
                            ...swap,
                            Modifiers: swap.Modifiers.map(modifier => ({
                                ...modifier,
                                IngredientPLU: ingredient.IngredientPLU,
                                _IsOptional: false, // ingredient.IsOptional, model has changed. There is no IsOptional flag
                                _ProductId: productIngredient.ProductID,
                                _LocationNo: locationNo,
                                _ProductPLU: productIngredient.PLU,
                            }))
                        }));

                        return {
                            ...ingredient,
                            Modifiers,
                            Swaps: Swaps,
                        };
                    });

                    return {
                        ...productIngredient,
                        Ingredients: ingredientsExtended,
                        _LocationNo: locationNo
                    };
                })),
            );
    }

    public getIngredientsModifiers(...productIds: number[]): Observable<OLO.DTO.ProductRecipeIngredientModel[]> {
        return this.getIngredients(...productIds)
            .pipe(
                map(ingredients => ingredients.Items),
            );
    }

    public getProductRestrictionsForLocation(params: OLO.DTO.ProductRestrictionsRequest): Observable<OLO.DTO.ProductRestriction[]> {
        return this.httpClient
            .get<APIv3.ProductsGetProductRestrictions.Responses.$200>(
            `${this.config.api.base}/products/productRestrictions?locationNo=${params.locationNo}&dateToCheck=${params.dateToCheck}`)
            .pipe(
                map((response: APIv3.ProductRestrictionsResponse) => ProductsMapper.mapProductRestrictionsForLocationGETResponse(response))
            );
    }
}
