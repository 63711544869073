import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { onlineOrderState } from '../root';

export const hasOrderTypeDetailsFieldsVisible = createSelector(
    onlineOrderState,
    onlineOrder => Utils.OnlineOrders.hasOrderTypePristineDetailsFields(onlineOrder?.orderType)
);
