import { createSelector } from '@ngrx/store';
import { ConfigStatic } from '@shared/core/statics';
import { membersState } from '../root';

export const isAccountSet = createSelector(
    membersState,
    members => {
        const config = new ConfigStatic().current;

        if (
            config.appMode === OLO.Enums.APP_MODE.LOYALTY_ONLY
            || members.data?.HasAccount !== true
            || config.payments.accountCharge?.enabled !== true
        ) return false;

        return members.data?.HasAccount === true;
    }
);
