import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './loyalty-product-programs.actions';

const initialState: OLO.State.LoyaltyProductProgramItem = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    downloadedDate: null,
    data: null,
};

export const loyaltyProductProgramsReducerFn = createReducer<OLO.State.LoyaltyProductProgramItem>(
    initialState,
    on(actions.LoyaltyProductProgramsReset, () => ({
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
        data: null,
    })),
    on(actions.LoyaltyProductProgramsRequest, (state) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.LoyaltyProductProgramsSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        downloadedDate: new Date().getTime(),
        data: action.payload || null,
    })),
    on(actions.LoyaltyProductProgramsErrorRequest, (state) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
);

export function loyaltyProductProgramsReducer(state: OLO.State.LoyaltyProductProgramItem | undefined, action: Action) {
    return loyaltyProductProgramsReducerFn(state, action);
}
