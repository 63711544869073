import { createSelector } from '@ngrx/store';
import { getLocationDetails } from '../getLocationDetails';
import * as Utils from '@shared/core/utils';
import { ConfigStatic } from '@shared/core/statics';

/**
 * Gets location model by locationNo
 * @param {number} locationNo
 * @returns {boolean} boolean
 */
export const hasLocationPickupOrderType = (locationNo: number) =>
    createSelector(
        getLocationDetails(locationNo),
        locationDetails => {
            const config = new ConfigStatic().current;
            const LocationColletionTypesChecker = new Utils.LocationCollectionTypesChecker(locationDetails, config);

            if (LocationColletionTypesChecker.hasPickups()) return true;

            return false;
        }
    );
