import { createAction, props } from '@ngrx/store';

export const ReorderSetup = createAction(
    '[Reorder] Setup',
    props<{ orderId: number; locationNo: number; modalId?: number; }>()
);

export const ReorderUnmount = createAction(
    '[Reorder] Unmount',
    props<{ orderId?: number; }>()
);

export const ReorderCalculateRequest = createAction(
    '[Reorder] Request calculate for reorder',
    props<{ orderId: number; locationNo: number; pickupTime: OLO.Ordering.PickupTime; }>(),
);

export const ReorderCalculateSuccessRequest = createAction(
    '[Reorder] Success request calculate for reorder',
    props<{ orderId: number; locationNo: number; pickupTime: OLO.Ordering.PickupTime; payload: OLO.State.Reorder.ReorderDetails; }>(),
);

export const ReorderCalculateErrorRequest = createAction(
    '[Reorder] Error request calculate for reorder',
    props<{ orderId: number; locationNo: number; pickupTime: OLO.Ordering.PickupTime; ex?: any; }>(),
);

export const ReorderSelectItem = createAction(
    '[Reorder] Select item',
    props<{ orderId: number; item: OLO.State.Cart.CartSimpleItem | OLO.State.Cart.CartMenuFlow; }>(),
);

export const ReorderDeselectItem = createAction(
    '[Reorder] Deselect item',
    props<{ orderId: number; item: OLO.State.Cart.CartSimpleItem | OLO.State.Cart.CartMenuFlow; }>(),
);

export const ReorderDeselectAll = createAction(
    '[Reorder] Deselect all',
    props<{ orderId: number; }>(),
);
