import * as io from 'io-ts';
import { currenciesType, unitTypesType, countriesType, localeType } from '@shared/core/types/consts';


const rootDefinition: io.MixedObject<OLO.Config.Localization> = {
    country: countriesType,
    units: unitTypesType,
    currency: currenciesType,
    locale: localeType,
};

export const localizationSchema = io.type(rootDefinition);
