import { createSelector } from '@ngrx/store';

import { getSelectedOrderTypeId } from '../getSelectedOrderType';
import { getLocations } from '@shared/state/locations/selectors';

import * as Utils from '@shared/core/utils';

export const getOrderInfoCompleteForLocationsByDate = (date: string | Date | null = null) =>
    createSelector(getLocations, getSelectedOrderTypeId, (locations, orderTypeId) => {
        let d: Date = Utils.Dates.createDate(date);
        const currDay = d.getDay();

        return locations?.map((obj) => {
            const baseObj = {
                locationNo: obj.LocationNo,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };

            const orderingTimeInfo = new Utils.LocationOrderingTimeInfo(obj, orderTypeId).getOrderingTimeInfo();

            const noDataAvailable: boolean = !orderingTimeInfo || orderingTimeInfo.length === 0;
            if (noDataAvailable) {
                return {
                    ...baseObj,
                    OpeningTime: null,
                    ClosingTime: null,
                    IsOpen: null,
                    MinimumPickupTime: null,
                };
            }

            const foundDay = orderingTimeInfo.find((orderingTime) => orderingTime.DayOfWeek === currDay);

            if (!foundDay) {
                /* When there is no data about selected day  - assume location is closed*/
                return {
                    ...baseObj,
                    OpeningTime: null,
                    ClosingTime: null,
                    IsOpen: false,
                    MinimumPickupTime: null,
                };
            }

            const minimumPickupTimeForDay = foundDay.PickupTimes.find((pickupTime) =>
                Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(d), pickupTime.From, pickupTime.To, 'both'),
            );

            return {
                ...baseObj,
                OpeningTime: foundDay.OpeningTime,
                ClosingTime: foundDay.ClosingTime,
                IsOpen: Utils.Dates.isHourInHoursRange(d, foundDay.OpeningTime, foundDay.ClosingTime, 'from'),
                MinimumPickupTime: minimumPickupTimeForDay ? minimumPickupTimeForDay.MinimumPickupTime : 0,
            };
        });
    });
