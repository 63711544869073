import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';


import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { switchMap, map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class OrderTypesEffects {
    @Effect() public requestOrderTypeForLocation$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationSet,
                actions.CartSetup,
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getOrderTypesForAllLocations)
                    )
            ),
            switchMap(([action, orderTypes]) => {
                const foundDownloaded = orderTypes.find(obj => obj.locationNo === action.locationNo && obj.hasSucceeded === true);
                if (foundDownloaded) {
                    return [];
                }

                return [
                    actions.OrderTypesRequest({ locationNo: action.locationNo }),
                ];
            })
        );

    @Effect() public requestOrderTypes$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.OrderTypesRequest
            ),
            mergeMap(({ locationNo }) => this._loylatyAppService.apiGetOrderTypes(locationNo)
                .pipe(
                    map(payload => actions.OrderTypesSuccessRequest({ locationNo, payload })),
                    catchError(ex => {
                        console.error('OrderTypes request error', ex);

                        return of(actions.OrderTypesErrorRequest({ locationNo, ex }));
                    })
                )
            )
        );

    @Effect() public resetOnlineOrderTypeOnRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CurrentLocationSet,
                actions.CartSetup,
                actions.OrderTypesRequest,
            ),
            switchMap(() => [
                actions.OnlineOrderTypeSelect({ orderType: null })
            ])
        );

    constructor(
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _loylatyAppService: Services.LoyaltyAppService,
    ) { }
}
