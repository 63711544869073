import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { locationsImagesState } from '../root';

export const getLocationImage = (imageType: OLO.Enums.IMAGE_TYPE, locationNo: number) =>
    createSelector(
        locationsImagesState,
        (locationsImages) => {
            const targetImageType = Utils.Images.toImageTypeString(imageType);

            return locationsImages[targetImageType]?.find((obj) => obj.Id === locationNo) || null;
        }
    );
