import { Inject, Injectable } from '@angular/core';
import { take, filter, combineLatest } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as selectors from '@shared/state/selectors';

@Injectable({
    providedIn: 'root',
})
export class ReturningMemberService {
    public hasModalBeenDisplayed = false;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _store: Store<OLO.State>,
        private _router: Router,
        private _authService: Services.AuthService,
        private _modalService: Services.ModalsService,
        private _sessionService: Services.SessionService,
    ) { }

    public openLoginModalForReturningMember(): void {
        if (!this._config.membership.enableQuickSignInForReturningMembers) return;

        let sessionExists = false;
        this._sessionService.getSession()
            .pipe(take(1))
            .subscribe(res => {
                if (res) {
                    sessionExists = true;
                }
            });

        if (sessionExists) return;

        const cellNumber = localStorage.getItem(OLO.Enums.USER_STORAGE.PHONE);
        const countryId = localStorage.getItem(OLO.Enums.USER_STORAGE.COUNTRY_ID);

        if (!cellNumber || !countryId) return;

        if (!isNaN(+cellNumber) && !isNaN(+countryId)) {
            this._showAuthModal(countryId, cellNumber);
        }

    }

    private _showAuthModal(countryId: string, cellNumber: string): void {
        const pathsWithoutAuthModal = ['reset-password', 'email-confirmation', 'order-confirmation'];
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            combineLatest(this._store.select(selectors.getAllModals)),
            filter(([_, modals]) => modals.length === 0),
            take(1))
            .subscribe(([event]) => {
                if (!pathsWithoutAuthModal.some(path => (event as NavigationEnd).url.includes(path))) {
                    this._authService.initSignIn(`${countryId}:${cellNumber}`);
                    this._modalService.show({
                        type: 'auth'
                    });
                    this.hasModalBeenDisplayed = true;
                }
            });
    }
}
