import { STATIC_TEXT_TOKEN } from '@shared/core/tokens/static-texts.tokens';
import { StaticProvider } from './static';

export class TextsStatic extends StaticProvider<T.StaticTexts> {
    constructor() {
        super();

        this._setCurrent(STATIC_TEXT_TOKEN);
    }
}
