// https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api
// https://developer.apple.com/documentation/apple_pay_on_the_web
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplePayPaymentProviderMapper } from '@shared/core/mappers/paymentProviders';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

@Injectable({
    providedIn: 'root'
})
export class ApplePayPaymentProviderService {
    private _applePayConfig!: OLO.Config.Payments.ApplePay;
    private _session!: ApplePaySession;

    constructor(
        @Inject(Tokens.ENV_TOKEN) public env: Environment,
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
    ) {
        this._applePayConfig = this.config.payments.applePay;
    }

    private get _displayName(): string {
        return this.config.payments.applePay?.displayName || '';
    }

    public async pay(amount: string): Promise<OLO.DTO.ApplePayPaymentData> {
        return new Promise((resolve, reject) => {
            this._session = this._createSessionObject(amount);
            this._session.oncancel = (event) => {
                this.completeSession(null);

                reject(event);
            };

            this._session.onvalidatemerchant = (event) => {
                this.validateApplePaySession({
                    ValidationUrl: event.validationURL,
                    DisplayName: this._displayName,
                })
                    .then(token => {
                        this._session.completeMerchantValidation(token);
                    })
                    .catch(ex => {
                        this.completeSession(ApplePaySession.STATUS_FAILURE);
                        reject(ex);
                    });
            };

            this._session.onpaymentmethodselected = () => {
                this._session.completePaymentMethodSelection({
                    newTotal: {
                        amount,
                        label:  this._displayName
                    }
                });
            };

            this._session.onshippingmethodselected = () => {
                this._session.completeShippingMethodSelection(ApplePaySession.STATUS_SUCCESS, null, []);
            };

            this._session.onshippingcontactselected = () => {
                this._session.completeShippingContactSelection(ApplePaySession.STATUS_SUCCESS, [], null, []);
            };

            this._session.onpaymentauthorized = (event) => {
                resolve(
                    ApplePayPaymentProviderMapper.mapApplePayTokenObject(event.payment.token.paymentData as ApplePayJS.PaymentEncryptedData)
                );
            };

            this._session.begin();
        });


    }
    public async validateApplePaySession(obj: ApplePayJS.ApplePaySessionValidationPostModel): Promise<ApplePayJS.OpaqueMerchantSessionObj> {
        return this.httpClient.post<ApplePayJS.OpaqueMerchantSessionObj>('/api/applePay/validateMerchant', obj).toPromise();
    }

    public completeSession(status: number): void {
        if(!this.canUseApplePayApi || !this._session || status == null) {
            this._session = null;

            return;
        };

        if(status === ApplePaySession.STATUS_FAILURE) {
            console.error('Apple pay payment failed');
        }
        this._session.completePayment(status);

        this._session = null;
    }

    public completeSuccessApplePay(): void {
        this.completeSession(ApplePaySession?.STATUS_SUCCESS);
    }

    public completeErrorApplePay(): void {
        this.completeSession(ApplePaySession?.STATUS_FAILURE);
    }

    public get canUseApplePayApi(): boolean {
        return Utils.VendorPayments.clientCanUseApplePayMethod(this.config);
    }

    private _createSessionObject(amount: string): ApplePaySession {
        const obj = new ApplePaySession(this._applePayConfig.apiVersion, {
            countryCode: this.config.localization.country,
            currencyCode: this.config.localization.currency,
            supportedNetworks: this._applePayConfig.supportedNetworks,
            merchantCapabilities: this._applePayConfig.merchantCapability,
            total: {
                label: this._displayName,
                amount,
                type: 'final',
            }
        });

        return obj;
    }
}
