import { createSelector } from '@ngrx/store';

import { wizzardState } from '../root';

export const getWizzardMenuFlowProductTotalQuantity = (pageIdentifier: number, productId: number) =>
    createSelector(wizzardState, (wizzard) => {
        if (!wizzard.itemsMenuFlow) return null;
        const page = wizzard.itemsMenuFlow.Pages.find((p) => p.PageIdentifier === pageIdentifier);
        if (!page) return null;

        return page.Products.reduce((acc, product) => {
            if (product.ProductId === productId) {
                return product.Quantity;
            }

            return acc;
        }, 0);
    });
