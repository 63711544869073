import { createAction, props } from '@ngrx/store';

export const GooglePlaceSearchRequest = createAction(
    '[Google] Request place search',
    props<{ search: string; location: APICommon.GoogleLocation; }>(),
);

export const GooglePlaceSearchSuccessRequest = createAction(
    '[Google] Success request place search',
    props<{ search: string; payload: Array<APICommon.GooglePlaceDetails>; }>(),
);

export const GooglePlaceSearchErrorRequest = createAction(
    '[Google] Error request place search',
    props<{ search: string; ex: any; }>(),
);

export const GooglePlaceDetailsRequest = createAction(
    '[Google] Request place details',
    props<{ placeId: string; }>(),
);

export const GooglePlaceDetailsSuccessRequest = createAction(
    '[Google] Success place details',
    props<{ placeId: string; payload: APICommon.GooglePlaceResult; }>(),
);

export const GooglePlaceDetailsErrorRequest = createAction(
    '[Google] Error request place details',
    props<{ placeId: string; ex: any; }>(),
);

export const GoogleGeocoderRequest = createAction(
    '[Google] Request geocoder location',
    props<{ location: APICommon.GoogleLocation; }>(),
);

export const GoogleGeocoderSuccessRequest = createAction(
    '[Google] Success request geocoder location',
    props<{ location: APICommon.GoogleLocation; payload: Array<APICommon.GeocoderResult>; }>(),
);

export const GoogleGeocoderErrorRequest = createAction(
    '[Google] Error request geocoder location',
    props<{ location: APICommon.GoogleLocation; ex: any; }>(),
);

export const GoogleInitSuccess = createAction(
    '[Google] Init Success'
);
