import { createSelector } from '@ngrx/store';

import { getEdgeOpeningHoursForLocations } from '@shared/state/commonSelectors/locations';
import { getEdgeMinimumPickupTimeForLocations } from '../getEdgeMinimumPickupTimeForLocations';

export const getEdgeOrderingTimeInfoForLocations = (date: string | Date | null = null) =>
    createSelector(getEdgeMinimumPickupTimeForLocations(date), getEdgeOpeningHoursForLocations(date), (MinimumPickupTime, openingHours) => ({
        MinimumPickupTime,
        ...openingHours,
    }));
