import { createSelector } from '@ngrx/store';
import { getLocationDetails } from '../getLocationDetails';

import * as Utils from '@shared/core/utils';

/**
 * Gets operating hours status - when location is phisically open
 * @param {number} locationNo
 * @param {number} orderTypeId
 * @param {Date=} forDate
 * @returns {OLO.Common.LocationOpenStatus} object
 */
export const getLocationOperatingHoursStatus = (locationNo: number, orderTypeId: number, forDate: Date = new Date()) =>
    createSelector(getLocationDetails(locationNo), (location) => {
        const statusMessageObj = new Utils.LocationOpenStatusMessage(Utils.LocationOpenStatus, location, orderTypeId, forDate);

        return statusMessageObj.getOpenStatusObj();
    });
