import { createSelector } from '@ngrx/store';

import * as Models from '@shared/core/models';

import { getOnlineMenu } from '@shared/state/onlineMenu/selectors';
import { getWizzardSimpleItem, getWizzardMenuFlow } from '@shared/state/wizzard/selectors';
import { getDietaryTagImages } from '@shared/state/dietaryTagsImages/selectors';

export const getStatsForWizzardItem = (isMenuFlow: boolean, label: string) =>
    createSelector(getOnlineMenu, getWizzardSimpleItem, getWizzardMenuFlow, getDietaryTagImages, (onlineMenu, singleProduct, menuFlow, images) => {
        if (!onlineMenu || !onlineMenu.data || (isMenuFlow && !menuFlow) || (!isMenuFlow && !singleProduct) || !images) return null;

        const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;

        let stats: Nullable<Models.Stats> = null;

        onlineMenu.data.Pages.forEach((page) => {
            page.Products.forEach((product) => {
                if (stats) return;

                if (isMenuFlow) {
                    if (product.MenuFlowId === itemId) {
                        stats = new Models.Stats({
                            label: product.Price || product.DisplayMinimumPrice ? label : null,
                            price: product.DisplayMinimumPrice ? product.MinimumPrice : product.Price,
                            kilojoules: product.Kilojoules,
                        });
                    }

                    return;
                }

                if (product.ProductId === itemId) {
                    stats = new Models.Stats({
                        label: null,
                        price: product.Price,
                        kilojoules: product.Kilojoules,
                    });
                }
            });
        });

        return (stats?.toJson() as OLO.Components.StatsComponentInput) || null;
    });
